<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("orders.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("orders.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('customField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      formName === 'customField'
        ? $t('customFields.title')
        : formName === 'create'
        ? $t('orders.form.createTitle')
        : $t('orders.form.editTitle')
    "
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CustomFields v-if="formName === 'customField'" ref="form"></CustomFields>
      <CreateOrUpdateOrder
        v-if="formName === 'create' || formName === 'edit'"
        ref="form"
        :initial-values="tempRow"
        :is-creating="formName === 'create'"
        @handle-submit="handleSubmit"
      ></CreateOrUpdateOrder>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslated"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/orders/"
    :filters="tableFilters"
    :headers="headerTable"
    name="usersTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateOrder from "@/components/orders/CreateOrUpdateOrder.vue";
import { OrdersHeader } from "@/components/orders/templates/headers/Orders.headers";
import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useOrdersServices } from "@/composables/useOrdersServices";
import { Order } from "@/store/orders/models/Order.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type formsName = "create" | "edit" | "customField";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { deleteOrder } = useOrdersServices();
const { t } = useI18n();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formName: Ref<formsName> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Order | undefined> = ref(undefined);

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `orders.${question.value}`,
    params: {
      name: String(tempRow.value?.id) || "",
    },
    needsTranslate: true,
  };
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { order, isCreating } = data;

  if (isCreating) {
    tableRef.value.currentData = [order, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, order);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(actionType: "edit" | "delete", row: Order, index: number) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteOrder(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
  confirmationModalRef.value?.closeConfirmationModal();
}

function openOffcanvasForm(newFormName: formsName) {
  tempRow.value = null;
  formName.value = newFormName;
  if (newFormName === "create") {
    tempRow.value = {
      orderDate: new Date().toISOString(),
    } as unknown as Order;
  }
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  await getAllCatalogs();
  watch(
    customFields,
    () => {
      headerTable.value = OrdersHeader(
        runAction,
        customFields.value,
        catalogs.value
      );
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
