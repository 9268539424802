import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "text-center" }

import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { merge } from "lodash";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import DropdownMenu from "../globals/UiTools/DropdownMenu.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string; items: RawMaterial[] };
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateRawMaterialQuantity',
  props: {
  rawMaterials: {
    type: Object as PropType<RawMaterialQuantity[]>,
    required: false,
  },
},
  setup(__props, { expose: __expose }) {

const { t } = useI18n();

const props = __props;
const rawMaterials: Ref<RawMaterialQuantity[]> = toRef(props, "rawMaterials");
let currentRawMaterialIndex = -1;
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();

async function handleAddRawMaterial() {
  currentRawMaterialIndex = rawMaterials.value.length;
  await handleAddOrEditRawMaterial(currentRawMaterialIndex);
}

const excludeOptions = computed(() => {
  return rawMaterials.value.map(({ rawMaterial }) => {
    return rawMaterial;
  });
});

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  rawMaterials.value[currentRawMaterialIndex] = merge(
    {},
    rawMaterials.value[currentRawMaterialIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = rawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  rawMaterialFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  if (!isCreating) {
    await nextTick();
    rawMaterialForm.value.setValues(rawMaterials.value[index]);
  }
  modal.value.openModal();
  currentRawMaterialIndex = index;
}

function resetForms() {
  rawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

function getOptions(index: number): Menu {
  const options = [
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.edit",
        needsTranslate: true,
      },
      id: 1,
      action: () => handleAddOrEditRawMaterial(index),
    },
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.delete",
        needsTranslate: true,
      },
      id: 2,
      action: () => rawMaterials.value.splice(index, 1),
    },
  ];
  return {
    options,
  };
}

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (value, key, item) => {
      return item.rawMaterial.label;
    },
  },
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (value, key, item) => {
      return item.quantity;
    },
  },

  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.thickness;
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.width;
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.length;
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.volume;
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.volume * item.quantity;
    },
  },
  {
    key: "actions",
    sortable: false,
    label: t("users.table.actions"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

__expose({ resetForms });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
        _createElementVNode("hr")
      ], -1)),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("b", _hoisted_3, _toDisplayString(_unref(t)("technicalSheets.entity.rawMaterial")), 1)
      ]),
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
        _createElementVNode("hr")
      ], -1))
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: "btn ml-1 btn-gear",
          type: "button",
          onClick: handleAddRawMaterial
        }, _toDisplayString(_ctx.$t("technicalSheets.form.rawMaterial.add")), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_unref(BTable), {
          items: rawMaterials.value,
          fields: rawMaterialsFields,
          "show-empty": ""
        }, {
          "cell(actions)": _withCtx((row) => [
            _createVNode(DropdownMenu, {
              menuData: getOptions(row.index),
              name: `menu-${row.index}-${row.key}`
            }, null, 8, ["menuData", "name"])
          ]),
          empty: _withCtx(() => [
            _createElementVNode("h5", _hoisted_7, _toDisplayString(_ctx.$t("technicalSheets.tables.rawMaterials.isEmpty")), 1)
          ]),
          _: 1
        }, 8, ["items"])
      ])
    ]),
    _createVNode(GeneralModal, {
      ref_key: "modal",
      ref: modal,
      modalId: "translateModal",
      modalName: modalTitle.value,
      "data-bs-backdrop": "false",
      class: "p-4"
    }, {
      default: _withCtx(() => [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "rawMaterialForm",
          ref: rawMaterialForm,
          form: rawMaterialFormTemplateRef.value,
          "form-name": "translateForm",
          key: modalTitle.value
        }, null, 8, ["form"]))
      ]),
      _: 1
    }, 8, ["modalName"])
  ], 64))
}
}

})