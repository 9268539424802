<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("divisions.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("divisions.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('customField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      formName === 'customField'
        ? $t('customFields.title')
        : formName === 'create'
        ? $t('divisions.form.createTitle')
        : $t('divisions.form.editTitle')
    "
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CustomFields v-if="formName === 'customField'" ref="form"></CustomFields>
      <CreateOrUpdateDivision
        v-if="formName === 'create' || formName === 'edit'"
        ref="form"
        :initial-values="tempRow"
        @handle-submit="handleSubmit"
      ></CreateOrUpdateDivision>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal :title="confirmModalTitleTranslated" ref="confirmationModalRef">
    <DeleteDivision
      v-if="tempRow?.id"
      :key="tempRow.id"
      :handle-submit="handleConfirm"
      :current-row="tempRow"
    ></DeleteDivision>
  </ConfirmModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/divisions/"
    :filters="tableFilters"
    :headers="headerTable"
    name="divisionsTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import CreateOrUpdateDivision from "@/components/divisions/CreateOrUpdateDivision.vue";
import DeleteDivision from "@/components/divisions/DeleteDivision.vue";
import { DivisionsHeader } from "@/components/divisions/templates/headers/Division.headers";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { Division } from "@/store/divisions/models/Division.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

type formsName = "create" | "edit" | "customField";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteDivision } = useDivisionsServices();
const { t } = useI18n();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formName: Ref<formsName> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Division | null> = ref();

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `divisions.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { division, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [division, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, division);
  }
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "edit" | "delete",
  row: Division,
  index: number
) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = tempRow.value;
    await deleteDivision({ oldId, newId }, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
      confirmationModalRef.value?.closeConfirmationModal();
    });
  }
}

function openOffcanvasForm(newFormName: formsName) {
  tempRow.value = null;
  formName.value = newFormName;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields],
    () => {
      headerTable.value = DivisionsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
