import { translateButton } from "@/components/globals/buttons/templates/TranslateButton.template";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateStoreTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  handleTranslateButton: (
    title: LabelType,
    path: string,
    type: "text" | "textarea"
  ) => void
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "store.name",
            inputName: "storeName",
            label: {
              value: "stores.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  { value: "stores.entity.name", needsTranslate: true },
                  "store.metadata.language.name",
                  "text"
                )
              ),
            ],
          },
          {
            path: "store.division",
            inputName: "storeDivision",
            label: {
              value: "stores.entity.division",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/divisions/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "stores.form.add" : "stores.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "store", "store"),
    });
  }

  return form;
};
