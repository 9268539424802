import { useLanguageServices } from "@/composables/useLanguageServices";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isEmpty,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const InventoryTransactionFormTemplate = (
  customFields: CustomFieldInterface[],
  action: (typeTransaction?: "dispatch" | "insert" | "modify") => void,
  typeTransaction: "insert" | "dispatch" | "modify",
  isProductModule: boolean,
  onBlurLotCodeInput: (
    typeTransaction: "insert" | "dispatch" | "modify"
  ) => Promise<void>
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            fields: [
              {
                path: "inventory.store",
                inputName: "inventoryStore",
                label: {
                  value: "inventories.entity.store",
                  needsTranslate: true,
                },
                type: "select",
                metadata: {
                  tooltipPosition: "top",
                },
                selectOptions: {
                  dataSource: "/stores/",
                  idKey: "id",
                  labelKey: [`metadata.language.name.${lang.value}`, "name"],
                  isMultiple: false,
                  searchPath: "name",
                },
                methodsYup: () => {
                  return [stringYup, requiredYup];
                },
                disabled: true,
              },
              isProductModule
                ? {
                    path: "inventory.elements.element0.id",
                    inputName: "productInventory",
                    label: {
                      value: "inventories.entity.product",
                      needsTranslate: true,
                    },
                    type: "select",
                    metadata: {
                      tooltipPosition: "top",
                    },
                    selectOptions: {
                      dataSource: "/products/",
                      idKey: "id",
                      labelKey: [
                        `metadata.language.name.${lang.value}`,
                        "name",
                      ],
                      isMultiple: false,
                      searchPath: "name",
                    },
                    methodsYup: () => {
                      return [stringYup, requiredYup];
                    },
                    widthDistribution: 12,
                    disabled: true,
                  }
                : {
                    path: "inventory.elements.element0.id",
                    inputName: "rawMaterialInventory",
                    label: {
                      value: "inventories.entity.rawMaterial",
                      needsTranslate: true,
                    },
                    type: "select",
                    metadata: {
                      tooltipPosition: "top",
                    },
                    selectOptions: {
                      dataSource: "/raw-materials/",
                      idKey: "id",
                      labelKey: [
                        `metadata.language.description.${lang.value}`,
                        "description",
                      ],
                      isMultiple: false,
                      searchPath: "description",
                    },
                    methodsYup: () => {
                      return [stringYup, requiredYup];
                    },
                    widthDistribution: 12,
                    disabled: true,
                  },
              {
                path: "inventory.elements.element0.lotCode",
                inputName: "inventoryLotCode",
                label: {
                  value: "inventories.entity.lotCode",
                  needsTranslate: true,
                },
                type: "text",
                metadata: {
                  tooltipPosition: "top",
                },
                methodsYup: () => {
                  return [stringYup, isEmpty];
                },
                widthDistribution: 12,
                onBlur: () => onBlurLotCodeInput(typeTransaction),
                disabled: true,
              },
              {
                path: "inventory.elements.element0.quantity",
                inputName: "inventoryQuantity",
                label: {
                  value: "inventories.entity.quantity",
                  needsTranslate: true,
                },
                type: "number",
                metadata: {
                  tooltipPosition: "top",
                },
                methodsYup: () => {
                  return [stringYup, requiredYup];
                },
                widthDistribution: 12,
                disabled: true,
              },
              ...(["insert", "modify"].includes(typeTransaction)
                ? ([
                    {
                      path: "inventory.elements.element0.expirationDate",
                      inputName: "inventoryExpirationDate",
                      label: {
                        value: "inventories.entity.expirationDate",
                        needsTranslate: true,
                      },
                      type: "date",
                      metadata: {
                        tooltipPosition: "top",
                      },
                      methodsYup: () => {
                        return [stringYup, isEmpty];
                      },
                      widthDistribution: 12,
                      disabled: true,
                    },
                    ...customFieldsToField(
                      customFields,
                      "inventory.elements.element0",
                      "inventory.elements.element0",
                      "top"
                    ),
                  ] as Field[])
                : []),
            ],
            fieldOptions: {
              deleteDistribution: "bottom",
            },
            widthDistribution: 12,
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: `inventories.form.${typeTransaction}`,
        needsTranslate: true,
      },
      action: () => action(typeTransaction),
    },
  };
  return form;
};
