import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  dateYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateOrderTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  catalogs: CatalogClass[]
): Form => {
  const catalog =
    catalogs.find((catalog) => {
      return catalog.key === CatalogClassesKeys.ORDERS_STATUS_CLASS;
    })?.catalogs ?? [];
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "order.orderDate",
            inputName: "orderDate",
            label: {
              value: "orders.entity.orderDate",
              needsTranslate: true,
            },
            type: "date",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [dateYup, requiredYup];
            },
          },
          {
            path: "order.customer",
            inputName: "orderCustomer",
            label: {
              value: "orders.entity.customer",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/customers/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "order.status",
            inputName: "orderStatus",
            label: {
              value: "orders.entity.status",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              idKey: "id",
              labelKey: [
                `catalogMetadata.name.language.[${lang.value}]`,
                "name",
              ],
              isMultiple: false,
              initialOptions: catalog,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "orders.form.add" : "orders.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "order", "order"),
    });
  }

  return form;
};
