import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useStoresServices } from "@/composables/useStoresServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Store } from "@/store/stores/models/Store.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { get, merge, set } from "lodash";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { TranslateTemplate } from "../globals/templates/Translate.template";
import { CreateOrUpdateStoreTemplate } from "./templates/forms/CreateOrUpdateStore.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateStore',
  props: {
  initialValues: { type: Object as PropType<Store>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createStore, updateStore } = useStoresServices();
const { t } = useI18n();

const props = __props;

const initialValuesRef: Ref<Store> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});

const emit = __emit;

const currentStore = computed(() => {
  if (initialValuesRef.value) {
    const store = formatValues(initialValuesRef.value);
    return store ?? null;
  }
  return null;
});

async function handleTranslateSubmit() {
  const translateFormData = translateForm.value.values;
  translates.value = merge({}, translates.value, translateFormData);
  modal.value.closeModal();
}

async function handleTranslateButton(
  title: LabelType,
  path: string,
  type: "text" | "textarea"
) {
  formTranslateRef.value = TranslateTemplate(handleTranslateSubmit, path, type);
  modalTitle.value = title.needsTranslate ? t(title.value) : title.value;
  modal.value.openModal();

  const translate = merge(
    {},
    get(
      {
        store: { ...currentStore.value },
      },
      path
    ),
    get(translates?.value, path)
  );
  if (translate) {
    const store = set({}, path, translate);
    await nextTick();
    translateForm.value?.setValues(store);
  }
}

function callBackCreate(store: Store) {
  emit("handleSubmit", { store, isCreating: true });
}

function callBackEdit(store: Store) {
  emit("handleSubmit", {
    store,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { store } = merge({}, values, translates.value);
  if (!initialValuesRef.value) {
    await createStore(store, callBackCreate);
  } else {
    await updateStore(store, callBackEdit);
  }
}

function formatValues(values: Store) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    division: values.division,
    metadata: values.metadata,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

watch(
  currentStore,
  async () => {
    formTemplateRef.value = CreateOrUpdateStoreTemplate(
      customFields.value,
      handleSubmit,
      !currentStore.value,
      handleTranslateButton
    );
    if (currentStore.value) {
      await nextTick();
      form.value?.setValues({
        store: { ...currentStore.value },
      });
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "storeForm"
        }, null, 8, ["form"]))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          ref_key: "modal",
          ref: modal,
          modalId: "translateModal",
          modalName: modalTitle.value,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "translateForm",
              ref: translateForm,
              form: formTranslateRef.value,
              "form-name": "translateForm",
              key: modalTitle.value
            }, null, 8, ["form"]))
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})