import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { StoreInterface } from "../interfaces/Store.interface";

export class StoreDto implements StoreInterface {
  id = 0;
  name = "";
  division = null;
  createdAt = null;
  updatedAt = null;
  metadata = null;
  customFields: CustomFieldValues;
}

export class Store extends StoreDto {
  constructor(StoreDto: StoreDto) {
    super();
    Object.assign(this, StoreDto);
  }
}
