import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { GetTableDataDto } from "@/shared/globals/tables/dtos/GetTableData.dto";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { User } from "@/store/auth/models/User.model";
import { CreateOrUpdateUserInterface } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  CreateUser,
  DeleteUser,
  DisableUser,
  EnableUser,
  GetUsers,
  UpdateUser,
} from "@/store/users/services/Users.service";
import { computed, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useUsersServices() {
  const store = useStore();
  const { t } = useI18n();
  const user = computed(() => {
    return store.state.auth.user;
  });
  const alias = "users";

  const currentUsers: Ref<User[]> = ref([]);

  function getUsers(tablePayload: GetTableDataDto) {
    GetUsers(tablePayload)
      .then(({ data }) => {
        currentUsers.value = data;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function createUser(
    userPayload: CreateOrUpdateUserInterface,
    callBackCreate: (user: User) => void
  ): Promise<void> {
    await CreateUser(userPayload)
      .then((user) => {
        useToast().successToast(t("users.success.userCreated"));
        callBackCreate(user);
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function updateUser(
    payload: User,
    callBackUpdate: (user: User) => void
  ): Promise<UserInterface | void> {
    await UpdateUser(payload)
      .then((user) => {
        useToast().successToast(t("users.success.userUpdated"));
        callBackUpdate(user);
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function enableUser(
    payload: User,
    callBackUpdate: (user: User) => void
  ): Promise<void> {
    await EnableUser(payload.id)
      .then((user) => {
        useToast().successToast(t("users.success.statusUpdated"));
        callBackUpdate(user);
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function disableUser(
    payload: User,
    callBackUpdate: (user: User) => void
  ): Promise<void> {
    await DisableUser(payload.id)
      .then((user: User) => {
        useToast().successToast(t("users.success.statusUpdated"));
        callBackUpdate(user);
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function deleteUser(
    userPayload: User,
    callBackDelete: () => void
  ): Promise<void> {
    await DeleteUser(userPayload.id)
      .then(() => {
        useToast().successToast(t("users.success.userDeleted"));
        callBackDelete();
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  return {
    user,
    getUsers,
    currentUsers,
    createUser,
    updateUser,
    enableUser,
    disableUser,
    deleteUser,
  };
}
