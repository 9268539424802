import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { DeleteMeasureInterface } from "@/store/measures/interfaces/DeleteMeasure.interface";
import { Measure } from "@/store/measures/models/Measure.model";
import {
  CreateMeasure,
  DeleteMeasure,
  GetAllMeasures,
  UpdateMeasure,
} from "@/store/measures/services/Measure.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useMeasuresServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "measures";

  const measures = computed(() => {
    return store.state.measures.measures;
  });

  const getAllMeasures = async (): Promise<void> => {
    if (store.state.measures.measures.length === 0) {
      await GetAllMeasures()
        .then((measures: Partial<Measure>[]) => {
          store.commit("measures/setMeasures", measures);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
        });
    }
  };

  const createMeasure = async (
    payload: Measure,
    callBackCreate: (measure: Measure) => void
  ): Promise<void> => {
    await CreateMeasure(payload)
      .then(async (measure: Measure) => {
        await getAllMeasures();
        store.commit("measures/addMeasure", {
          id: measure.id,
          name: measure.name,
          description: measure.description,
          metadata: measure.metadata,
          customFields: measure.customFields,
        });
        callBackCreate(measure);
        useToast().successToast(t("measures.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const updateMeasure = async (
    payload: Measure,
    callBackUpdate: (measure: Measure) => void
  ): Promise<void> => {
    await UpdateMeasure(payload)
      .then(async () => {
        await getAllMeasures();
        store.commit("measures/updateMeasure", payload);
        callBackUpdate(payload);
        useToast().successToast(t("measures.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const deleteMeasure = async (
    payload: DeleteMeasureInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteMeasure(payload)
      .then(async () => {
        await getAllMeasures();
        callBackDelete();
        store.commit("measures/deleteMeasure", { id: payload.oldId });
        useToast().successToast(t("measures.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    measures,
    getAllMeasures,
    createMeasure,
    updateMeasure,
    deleteMeasure,
  };
}
