export const en = {
  language: "EN",
  name: "English",
  global: {
    buttons: {
      confirm: "Confirm",
      cancel: "Cancel",
      save: "Save",
    },
    inputs: {
      ruleName: "Rules",
      select: "Select an option",
      rules: {
        required: "Must have a value",
        upperCaseLimited: "Must contain at least {quantity} uppercase letters",
        lowerCaseLimited: "Must contain at least {quantity} lowercase letters",
        especialCharactersLimited:
          "Must contain at least {quantity} special characters",
        numberCharactersLimited:
          "Must contain at least {quantity} numeric characters",
        minQuantity: "Must contain at least {quantity} characters",
        numberCharacters: "Must be composed of numeric characters",
        email: "Must be a valid email address",
        onlyNumber: "Must be a number",
        positiveNumber: "Must be a positive number",
        isOptional: "Optional",
        isLess: "Must be less than or equal to {num}",
        isGreater: "Must be greater than or equal to {num}",
      },
    },
    warnings: {
      delete: "Deletion Warning",
    },
    tables: {
      resultsPerPage: "Results per page",
      emptyData: "Adjust the filters, no data matches these criteria",
      selectNull: "Select {entity}",
      showingPage: "Showing page {currentPage} of {totalPages}",
      filtersApplied: "Filters applied",
    },
    UNKNOWN_ERROR: "An unexpected error occurred, please contact support",
  },
  dashboard: {
    employee: "Employee",
    earnings: "Earnings",
    title: "System for Administration",
    statistics: "Statistics",
    customers: "CUSTOMERS",
    sales: "SALES",
    soldsDetails: "SALES DETAILS",
    thisWeek: "THIS WEEK",
    totalBalance: "Total Balance",
    lastTransactions: "LAST TRANSACTIONS",
  },
  auth: {
    carouselTabs: {
      signUp: {
        primaryTitle: "QUICK PALLET ACCESS",
        secondaryTitle: "ENTER YOUR EMAIL AND PASSWORD",
        hrefs: {
          validateEmail: "Want to validate your email?",
          recoveryPassword: "Forgot your password?",
        },
        anotherAccessOptions: "Or continue with",
        submit: "LOGIN",
      },
      forgotPassword: {
        primaryTitle: "Recover your password!",
        submit1: "RECOVER",
        submit2: "CHANGE",
        hrefs: {
          haveACodeYet: "Already have a code!",
          notHaveACodeYet: "Don't have a code!",
          signUp: "Sign In",
        },
      },
      validateEmail: {
        primaryTitle: "Validate your email",
        hrefs: {
          haveACodeYet: "Already have a code!",
          notHaveACodeYet: "Don't have a code!",
          signUp: "Sign In",
        },
        submit1: "SEND",
        submit2: "VALIDATE",
      },
      changePassword: {
        primaryTitle: "Set your new password",
      },
    },
    fields: {
      email: "Email",
      password: "Password",
      confirmationCode: "Confirmation Code",
      newPassword: "New password",
      oldPassword: "Old password",
    },
    errors: {
      CREDENTIALS_UNKNOWN: "Incorrect email or password, please try again.",
      invalidValidationData: "Invalid validation data",
      incorrectForgotPassData:
        "This email is not registered, please try a valid email address.",
      incorrectValidateEmailData:
        "This email is not registered, please try a valid email address.",
      ATTEMPTS_LIMIT_EXCEEDED: "Too many attempts, please try again later.",
      expiredCode: "The code has expired, please request a new one",
      incorrectValidationData: "Incorrect data",
      isValidatedYet: "This email is already validated",
    },
    success: {
      validation: "Validation successful",
      changePassword: "Password changed successfully",
      resendCode: "We sent a confirmation code to your email!",
    },
    info: {
      NEED_CHANGE_PASSWORD: "Is your fist login, you need change the password",
    },
  },
  accounts: {
    errors: {
      gettingAccount:
        "An error occurred while retrieving account information, please contact support.",
    },
  },
  users: {
    title: "Users",
    entity: {
      id: "ID",
      name: "Name",
      email: "Email",
      password: "Password",
      profile: "Profile",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
      status: "Status",
      positions: "Positions",
    },
    errors: {
      ACCESS_EXPIRED: "Your session has expired, please sign in again.",
      UNKNOWN_ERROR:
        "An unexpected error occurred while retrieving the user, please contact support",
      userAlreadyExists: "The user already exists",
    },
    success: {
      userCreated: "User created",
      userUpdated: "User updated",
      userDeleted: "User deleted",
      statusUpdated: "User status updated",
    },
    warnings: {
      status: "Status change warning",
    },
    form: {
      createTitle: "Create User",
      editTitle: "Edit User",
      add: "Add User",
      update: "Update User",
    },
    table: {
      label: "Users Table",
      actions: "Actions",
      actionList: {
        enable: "Enable",
        disable: "Disable",
        edit: "Edit",
        delete: "Delete",
      },
    },
    questionDelete:
      "Are you sure you want to delete the user <strong>{name}</strong>?",
    questionEnable:
      "Are you sure you want to enable the user <strong>{name}</strong>?",
    questionDisable:
      "Are you sure you want to disable the user <strong>{name}</strong>?",
  },
  header: {
    navbar: {
      elements: {
        home: "HOME",
        usersManagment: {
          name: "USER MANAGEMENT",
          dropdowns: {
            users: "USERS",
            profiles: "PROFILES",
            positions: "POSITIONS",
          },
        },
        crm: {
          name: "CRM",
          dropdowns: {
            customers: "CUSTOMERS",
            suppliers: "SUPPLIERS",
          },
        },
        productsManagment: {
          name: "PRODUCT MANAGEMENT",
          dropdowns: {
            products: "PRODUCTS",
            rawMaterials: "RAW MATERIALS",
            productFamily: "PRODUCT FAMILIES",
            measures: "MEASURES",
          },
        },
        operations: {
          name: "OPERATIONS",
          dropdowns: {
            orders: "ORDERS",
            shipping: "SHIPPING",
            inventories: "INVENTORIES",
          },
        },
        workplace: "WORKPLACE",
        reports: "REPORTS",
        settings: "SETTINGS",
      },
    },
  },
  catalogs: {
    USERS_STATUS_CLASS: "User Status",
    EMPLOYEES_STATUS_CLASS: "Employee Status",
  },
  customFields: {
    title: "Custom Fields",
    create: "Create custom field",
    name: "Field Name",
    required: "Required",
    type: "Field Type",
    types: {
      text: "Text",
      number: "Number",
      select: "Single Select",
      multipleSelect: "Multiple Select",
      date: "Date",
    },
    default: "Default Value",
    regularExpression: "Regular Expression",
    unitOfMeasurement: "Unit of Measurement",
    min: "Minimum Value",
    max: "Maximum Value",
    negativeInfinity: "-Infinity",
    positiveInfinity: "+Infinity",
    selectionLimit: "Selection Limit",
    values: "Enter values",
    addField: "Add Field",
    save: "Save",
    translates: "Value Translations",
    settings: "Settings",
    optionsValues: "Option Values",
    errors: {
      fieldAlreadyExists: "Custom field already exists.",
    },
    success: {
      customFieldCreated: "Custom field created",
      customFieldUpdated: "Custom field updated",
      customFieldDeleted: "Custom field deleted",
    },
    yupRules: {
      isEmailYup: "email",
      minCharsYup: "minimum characters",
      minSpecialCharsYup: "minimum special characters",
      minNumbersYup: "minimum numbers",
      minUpperCaseYup: "minimum uppercase",
      minLowerCaseYup: "minimum lowercase",
      positiveYup: "positive",
      integerYup: "integer",
      isGreaterYup: "greater than or equal to",
      isLessYup: "less than or equal to",
      isPositiveNumberYup: "positive",
    },
    quantity: `Quantity of {name}`,
    addOption: "Enter option {optionIndex}",
    updateField: "Update field",
    questionDelete:
      "Are you sure you want to delete the custom field named <strong>{name}</strong>?",
    isCurrency: "Is a currency",
    validations: "Validations",
  },
  profiles: {
    title: "Profiles",
    entity: {
      id: "ID",
      name: "Name",
      description: "Description",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
      permissions: "Permissions",
    },
    table: {
      actions: "Actions",
      actionList: {
        enable: "Enable",
        disable: "Disable",
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Profile",
      editTitle: "Edit Profile",
      add: "Add Profile",
      update: "Update Profile",
      selectNewProfile: "Select a new profile",
      deleteAndReplace: "Replace and delete profile",
    },
    success: {
      deletedAndReplaced: "Profile deleted and replaced",
    },
  },
  measures: {
    title: "Measures",
    entity: {
      id: "ID",
      name: "Name",
      description: "Description",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
    },
    errors: {
      UNKNOWN_ERROR:
        "An unexpected error occurred while retrieving the measure, please contact support",
      alreadyExists: "The measures already exist",
    },
    success: {
      created: "Measure created",
      updated: "Measure updated",
      deleted: "Measure deleted",
    },
    warnings: {
      status: "Measure change warning",
    },
    form: {
      createTitle: "Create Measure",
      editTitle: "Edit Measure",
      add: "Add Measure",
      update: "Update Measure",
      deleteAndReplace: "Replace and delete measure",
      selectNewMeasure: "Select a new measure",
    },
    tables: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    questionDelete:
      "Are you sure you want to delete the measures <strong>{name}</strong>?",
  },
  rawMaterials: {
    title: "Raw Materials",
    entity: {
      id: "ID",
      code: "Code",
      name: "Name",
      description: "Description",
      materialType: "Material Type",
      unitCost: "Unit Cost",
      quantityAvailable: "Quantity Available",
      minimumStock: "Minimum Stock",
      warehouseLocation: "Warehouse Location",
      supplier: "Supplier",
      measure: "Measure",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
      status: "Status",
    },
    errors: {
      UNKNOWN_ERROR:
        "An unexpected error occurred while retrieving the raw material, please contact support",
      alreadyExists: "The raw material already exists",
    },
    success: {
      created: "Raw material created",
      updated: "Raw material updated",
      deleted: "Raw material deleted",
    },
    warnings: {
      status: "Raw material change warning",
    },
    form: {
      createTitle: "Create Raw Material",
      editTitle: "Edit Raw Material",
      add: "Add Raw Material",
      update: "Update Raw Material",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    questionDelete:
      "Are you sure you want to delete the raw material <strong>{name}</strong>?",
  },
  productFamilies: {
    title: "Product Families",
    entity: {
      id: "ID",
      name: "Name",
      description: "Description",
      permissions: "Permissions",
      createdAt: "Creation Date",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Product Family",
      editTitle: "Edit Product Family",
      add: "Add Product Family",
      update: "Update Product Family",
      deleteAndReplace: "Replace and delete",
      selectNewProductFamily: "Select a new product family",
    },
  },
  products: {
    title: "Products",
    technicalSheetsTitle: "Technical sheets",
    workplacesFlowsTitle: "Workplaces flows",
    entity: {
      id: "ID",
      name: "Name",
      description: "Description",
      createdAt: "Creation Date",
      measure: "Measure",
      productFamily: "Product Family",
      permissions: "Permissions",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Product",
      editTitle: "Edit Product",
      add: "Add Product",
      update: "Update Product",
      technicalSheetTitle: "Technical sheet",
      workplacesFlowTitle: "Workplaces flow",
    },
    success: {
      created: "Product created",
      updated: "Product updated",
      deleted: "Product deleted",
    },
    questionDelete:
      "Are you sure you want to delete the product <strong>{name}</strong>?",
  },
  customers: {
    title: "Customers",
    entity: {
      id: "ID",
      name: "Name",
      customerType: "Customer Type",
      businessType: "Business Type",
      state: "State",
      city: "City",
      status: "Status",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Customer",
      editTitle: "Edit Customer",
      add: "Add Customer",
      update: "Update Customer",
    },
    success: {
      created: "Customer created",
      updated: "Customer updated",
      deleted: "Customer deleted",
    },
    questionDelete:
      "Are you sure you want to delete the customer <strong>{name}</strong>?",
  },
  suppliers: {
    title: "Suppliers",
    entity: {
      id: "ID",
      name: "Name",
      supplierType: "Supplier Type",
      businessType: "Business Type",
      supplierState: "State",
      city: "City",
      status: "Status",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Supplier",
      editTitle: "Edit Supplier",
      add: "Add Supplier",
      update: "Update Supplier",
      deleteAndReplace: "Replace and delete",
      selectNewSupplier: "Select a new supplier",
    },
    success: {
      created: "Supplier created",
      updated: "Supplier updated",
      deleted: "Supplier deleted",
    },
  },
  positions: {
    title: "Positions",
    entity: {
      id: "ID",
      name: "Name",
      description: "Description",
      baseSalary: "Base Salary",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
    },
    table: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    form: {
      createTitle: "Create Position",
      editTitle: "Edit Position",
      add: "Add Position",
      update: "Update Position",
    },
    success: {
      created: "Position created",
      updated: "Position updated",
      deleted: "Position deleted",
    },
    questionDelete:
      "Are you sure you want to delete the position <strong>{name}</strong>?",
  },
  orders: {
    title: "Orders",
    entity: {
      id: "ID",
      orderDate: "Order Date",
      customer: "Customer",
      customerType: "Customer Type",
      status: "Status",
      createdAt: "Creation Date",
      updatedAt: "Update Date",
    },
    errors: {
      UNKNOWN_ERROR:
        "An unexpected error occurred while retrieving the order, please contact support",
      alreadyExists: "The order already exists",
    },
    success: {
      created: "Order created",
      updated: "Order updated",
      deleted: "Order deleted",
    },
    warnings: {
      status: "Order status warning",
    },
    form: {
      createTitle: "Create Order",
      editTitle: "Edit Order",
      add: "Add Order",
      update: "Update Order",
    },
    tables: {
      actions: "Actions",
      actionList: {
        edit: "Edit",
        delete: "Delete",
      },
    },
    questionDelete:
      "Are you sure you want to delete the order <strong>{name}</strong>?",
  },
  orderDetail: {
    title: "Detail",
    entity: {
      product: "Product",
      quantity: "Quantity",
      total: "Total",
      discount: "Discount",
    },
    form: {
      createTitle: "Create Order Detail",
      editTitle: "Update Order Detail",
      add: "Add Order Detail",
      update: "Update Order Detail",
    },
  },
};
