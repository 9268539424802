import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { DeleteMeasureInterface } from "@/store/measures/interfaces/DeleteMeasure.interface";
import { Supplier } from "@/store/suppliers/models/Supplier.model";
import {
  CreateSupplier,
  DeleteSupplier,
  GetAllSuppliers,
  UpdateSupplier,
} from "@/store/suppliers/services/Supplier.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useSuppliersServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "suppliers";

  const suppliers = computed(() => {
    return store.state.suppliers.suppliers;
  });

  const getAllSuppliers = async (): Promise<void> => {
    if (store.state.suppliers.suppliers.length === 0) {
      await GetAllSuppliers()
        .then((suppliers: Partial<Supplier>[]) => {
          store.commit("suppliers/setSuppliers", suppliers);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
          return;
        });
    }
  };

  const createSupplier = async (
    payload: Supplier,
    callBackCreate: (supplier: Supplier) => void
  ): Promise<void> => {
    await CreateSupplier(payload)
      .then(async (supplier: Supplier) => {
        await getAllSuppliers();
        store.commit("suppliers/addSupplier", supplier);
        callBackCreate(supplier);
        useToast().successToast(t("suppliers.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateSupplier = async (
    payload: Supplier,
    callBackUpdate: (supplier: Supplier) => void
  ): Promise<void> => {
    await UpdateSupplier(payload)
      .then(async () => {
        await getAllSuppliers();
        store.commit("suppliers/updateSupplier", payload);
        callBackUpdate(payload);
        useToast().successToast(t("suppliers.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteSupplier = async (
    payload: DeleteMeasureInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteSupplier(payload)
      .then(async () => {
        await getAllSuppliers();
        callBackDelete();
        store.commit("suppliers/deleteSupplier", { id: payload.oldId });
        useToast().successToast(t("suppliers.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };
  return {
    suppliers,
    getAllSuppliers,
    createSupplier,
    updateSupplier,
    deleteSupplier,
  };
}
