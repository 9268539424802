import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "option d-flex align-items-center" }
const _hoisted_2 = { class: "dropdown-menu" }

import { RouterActionMenu } from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElement } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import {
  computed,
  PropType,
  ref,
} from "vue";
import { useRoute } from "vue-router";
import SubmenuOption from "./SubmenuOption.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'HeaderOption',
  props: {
  header: { type: Object as PropType<SidebarElement>, required: true },
},
  emits: ["navigating"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const route = useRoute();
const dropdown = ref();
const submenus = ref([]);
const isOpenDropdown = ref(false);
const emit = __emit;

const isActive = computed(() => {
  return props.header.submenu.some((element) => {
    return (element.action as RouterActionMenu).route === route.name;
  });
});

const closeAllDropdowns = (index: number) => {
  submenus.value.forEach((submenu, index2) => {
    if (submenu.isOpenDropdown && index2 !== index) {
      submenu.closeDropdown();
    }
  });
};

function OpenOrCloseDropdown() {
  if (isOpenDropdown.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

function openDropdown() {
  Dropdown.getOrCreateInstance(dropdown.value)?.show();
  isOpenDropdown.value = true;
}

function closeDropdown(emitClose = false) {
  Dropdown.getOrCreateInstance(dropdown.value)?.hide();
  if (emitClose) {
    emit("navigating");
  }
  isOpenDropdown.value = false;
}

const optionStyles = [
  "text-nowrap",
  "link-item",
  "px-4",
  "py-1",
  "custom-item-style",
];

__expose({ isOpenDropdown, openDropdown, closeDropdown });

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (props.header.action?.route)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: props.header.action.route },
          class: _normalizeClass(optionStyles)
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(props.header.i18nName)), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "nav-item dropdown",
          ref_key: "dropdown",
          ref: dropdown
        }, [
          _createElementVNode("a", {
            href: "#",
            role: "button",
            onClick: OpenOrCloseDropdown,
            class: _normalizeClass([...optionStyles, { active: isActive.value }])
          }, _toDisplayString(_ctx.$t(props.header.i18nName)), 3),
          _createElementVNode("ul", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.header.submenu ?? [], (element, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: element.i18nName + index,
                class: "item"
              }, [
                _createVNode(SubmenuOption, {
                  option: element,
                  "parent-is-closed": isOpenDropdown.value,
                  ref_for: true,
                  ref_key: "submenus",
                  ref: submenus,
                  onNavigating: _cache[0] || (_cache[0] = () => closeDropdown(true)),
                  onOpenDropdown: () => closeAllDropdowns(index)
                }, null, 8, ["option", "parent-is-closed", "onOpenDropdown"])
              ]))
            }), 128))
          ])
        ], 512))
  ]))
}
}

})