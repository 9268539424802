<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t(title) }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary mr-2"
                @click="() => openOffcanvasForm('insert')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("inventories.form.insert") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <button
                type="button"
                class="btn ml-1 btn-gear"
                @click="() => openOffcanvasForm('customField')"
              >
                <i class="bi bi-gear"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="$t(offCanvasTitle)"
    @closed="customFieldsClosed"
    :initialWidth="30"
    ><template v-slot:content>
      <CustomFields v-if="formType === 'customField'" ref="form"></CustomFields>
      <InventoryTransactionForm
        v-else-if="['insert', 'dispatch', 'modify'].includes(formType)"
        ref="form"
        :initial-values="tempRow"
        :transaction-type="formType"
        @handle-submit="handleSubmit"
      ></InventoryTransactionForm>
    </template>
  </GeneralOffcanvas>
  <GeneralTable
    v-if="headerTable"
    :dataSource="dataSource"
    :filters="tableFilters"
    :headers="headerTable"
    name="usersTable"
    ref="tableRef"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import InventoryTransactionForm from "@/components/inventories/InventoryTransactionForm.vue";
import { InventoriesHeader } from "@/components/inventories/templates/headers/Inventory.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { ProductsInventory } from "@/store/inventories/models/ProductsInventory.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

type FormsTypes = "insert" | "dispatch" | "modify" | "customField";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { t } = useI18n();
const route = useRoute();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formType: Ref<FormsTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const action: Ref<"modify"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductsInventory | null> = ref();

const moduleName: string = route.meta.moduleName as string;

const isProductModule = computed(() => {
  return moduleName !== "raw_materials_inventory";
});

const dataSource = computed(() => {
  if (isProductModule.value) {
    return "/products-inventories/";
  }
  return "/raw-materials-inventories/";
});

const title = computed(() => {
  if (isProductModule.value) {
    return "inventories.productsTitle";
  }
  return "inventories.rawMaterialsTitle";
});

const offCanvasTitle = computed(() => {
  if (formType.value === "customField") {
    return "customFields.title";
  } else if (formType.value === "insert") {
    if (isProductModule.value) {
      return "inventories.form.insertProductTitle";
    } else {
      return "inventories.form.insertRawMaterialTitle";
    }
  } else if (formType.value === "dispatch") {
    if (isProductModule.value) {
      return "inventories.form.dispatchProductTitle";
    } else {
      return "inventories.form.dispatchRawMaterialTitle";
    }
  } else if (formType.value === "modify") {
    if (isProductModule.value) {
      return "inventories.form.dispatchProductTitle";
    } else {
      return "inventories.form.dispatchRawMaterialTitle";
    }
  }
  return "";
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { inventories } = data;
  inventories.map((inventory) => {
    tableRef.value.addOrUpdateRow("id", inventory);
  });
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "modify" | "dispatch",
  row: ProductsInventory,
  index: number
) {
  if (actionType === "modify") {
    openOffcanvasForm("modify");
  } else {
    openOffcanvasForm("dispatch");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

function openOffcanvasForm(newFormType: FormsTypes) {
  tempRow.value = null;
  formType.value = newFormType;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    customFields,
    () => {
      headerTable.value = InventoriesHeader(
        runAction,
        customFields.value,
        isProductModule.value
      );
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
