import axiosInstance from "@/middlewares/Routing.middleware";
import { ChangePasswordDto } from "../dtos/ChangePassword.dto";
import { ConfirmForgotPasswordDto } from "../dtos/ConfirmForgotPassword.dto";
import { ForgotPasswordDto } from "../dtos/ForgotPassword.dto";
import { GetAccountDto } from "../dtos/GetAccount.dto";
import { LoginDto } from "../dtos/Login.dto";
import { ResendValidateEmailDto } from "../dtos/ResendValidateEmail.dto";
import { ValidateEmailDto } from "../dtos/ValidateEmail.dto";

export async function Login(payload: LoginDto) {
  return await axiosInstance.post("/auth/sign-in", payload);
}

export async function GetAccount(payload: GetAccountDto) {
  return await axiosInstance.get("/accounts", {
    params: payload,
  });
}

export async function GetUser() {
  return await axiosInstance.get("/users/current");
}

export async function ValidateEmail(payload: ValidateEmailDto) {
  return await axiosInstance.post("/auth/confirm-sign-up", payload);
}

export async function ResendValidateEmail(payload: ResendValidateEmailDto) {
  return await axiosInstance.post("/auth/resend-confirm-sign-up", payload);
}

export async function ForgotPassword(payload: ForgotPasswordDto) {
  return await axiosInstance.post("/auth/forgot-password", payload);
}

export async function ConfirmForgotPassword(payload: ConfirmForgotPasswordDto) {
  return await axiosInstance.post("/auth/confirm-forgot-password", payload);
}

export async function ChangePassword(
  payload: ChangePasswordDto,
  token: string
) {
  return await axiosInstance.post("/current-session/change-password", payload, {
    headers: { Authorization: `Bearer ${token}` },
  });
}
