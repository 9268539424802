import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, withCtx as _withCtx, createVNode as _createVNode, createSlots as _createSlots, unref as _unref, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }
const _hoisted_12 = { class: "dropdown" }
const _hoisted_13 = {
  class: "dropdown-menu",
  "aria-labelledby": "mainDropdown"
}
const _hoisted_14 = {
  class: "accordion",
  id: "accordionExample"
}
const _hoisted_15 = { class: "accordion-item" }
const _hoisted_16 = {
  class: "accordion-header",
  id: "headingOne"
}
const _hoisted_17 = {
  id: "collapseOne",
  class: "accordion-collapse collapse",
  "aria-labelledby": "headingOne",
  "data-bs-parent": "#accordionExample"
}
const _hoisted_18 = { class: "accordion-body" }
const _hoisted_19 = { class: "list-unstyled" }
const _hoisted_20 = {
  key: 0,
  class: "row justify-content-end"
}
const _hoisted_21 = { class: "col-auto" }
const _hoisted_22 = { class: "row justify-content-end" }
const _hoisted_23 = { class: "col-auto" }
const _hoisted_24 = { class: "row justify-content-end" }
const _hoisted_25 = { class: "col-auto" }
const _hoisted_26 = { class: "col pr-0" }
const _hoisted_27 = { class: "container w-auto" }
const _hoisted_28 = { class: "row justify-content-end" }
const _hoisted_29 = { class: "col-auto" }
const _hoisted_30 = ["onClick"]

import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateMeasure from "@/components/measures/CreateOrUpdateMeasure.vue";
import CreateOrUpdateProduct from "@/components/products/CreateOrUpdateProduct.vue";
import PreviewTechnicalSheet from "@/components/products/PreviewTechnicalSheet.vue";
import { ProductsHeader } from "@/components/products/templates/headers/Products.headers";
import CreateOrUpdateTechnicalSheet from "@/components/technicalSheets/CreateOrUpdateTechnicalSheet.vue";
import CreateOrUpdateWorkplacesFlow from "@/components/workplaces-flows/CreateOrUpdateWorkplacesFlow.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProductsServices } from "@/composables/useProductsServices";
import { Product } from "@/store/products/models/Product.model";
import { get } from "lodash";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
type CanvasTypes =
  | "create"
  | "edit"
  | "productsCustomFields"
  | "technicalSheetCustomFields"
  | "workplacesFlowCustomFields";
type CanvasChildrenTypes = "technicalSheet" | "workplacesFlow" | "measure";


export default /*@__PURE__*/_defineComponent({
  __name: 'ProductsView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteProduct } = useProductsServices();
const { t } = useI18n();

const formKeys = ref([0, 0, 0]);
const forms = ref([]);
const tableRef = ref();
const offCanvasRefs = ref([]);
const formName: Ref<CanvasTypes> = ref("create");
const formNameChildren: Ref<CanvasChildrenTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Product | null> = ref();
const technicalSheetModal = ref();
const currentProductId = ref();

const moduleName = computed(() => {
  if (
    formNameChildren.value === "technicalSheet" ||
    formName.value === "technicalSheetCustomFields"
  ) {
    return "technical_sheets";
  } else if (
    formNameChildren.value === "workplacesFlow" ||
    formName.value === "workplacesFlowCustomFields"
  ) {
    return "workplace_flows";
  }
  return "products";
});

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `products.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const openOffCanvas = (index) => {
  formKeys.value[index]++;
  offCanvasRefs.value?.[index]?.openOffCanvas?.();
};

const stopDropdownClose = (event) => {
  event.stopPropagation();
};

function handleSubmit(data) {
  const { product, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [product, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, product);
  }
  offCanvasRefs.value[0].closeOffCanvas();
}

function runAction(actionType: "edit" | "delete", row: Product, index: number) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteProduct(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function isCanvasType(
  value: CanvasTypes | CanvasChildrenTypes
): value is CanvasTypes {
  return [
    "create",
    "edit",
    "productsCustomFields",
    "technicalSheetCustomFields",
    "workplacesFlowCustomFields",
  ].includes(value);
}

function openOffcanvasForm(newFormName: CanvasTypes | CanvasChildrenTypes) {
  if (isCanvasType(newFormName)) {
    tempRow.value = null;
    formName.value = newFormName;
    formNameChildren.value = null;
    openOffCanvas(0);
  } else {
    formNameChildren.value = newFormName;
    openOffCanvas(1);
  }
}

function openTechnicalSheetPreview(id: number) {
  currentProductId.value = id;
  technicalSheetModal.value.openModal();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields],
    () => {
      headerTable.value = ProductsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("products.title")), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('create'))
                }, [
                  _cache[10] || (_cache[10] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("products.form.add")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("div", _hoisted_12, [
                  _cache[11] || (_cache[11] = _createElementVNode("button", {
                    class: "btn btn-secondary dropdown-toggle ml-1 btn-gear",
                    type: "button",
                    id: "mainDropdown",
                    "data-bs-toggle": "dropdown",
                    "aria-expanded": "false"
                  }, [
                    _createElementVNode("i", { class: "bi bi-gear" })
                  ], -1)),
                  _createElementVNode("ul", _hoisted_13, [
                    _createElementVNode("li", null, [
                      _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("div", _hoisted_15, [
                          _createElementVNode("h2", _hoisted_16, [
                            _createElementVNode("button", {
                              class: "accordion-button collapsed",
                              type: "button",
                              "data-bs-toggle": "collapse",
                              "data-bs-target": "#collapseOne",
                              "aria-expanded": "false",
                              "aria-controls": "collapseOne",
                              onClick: stopDropdownClose
                            }, _toDisplayString(_ctx.$t("customFields.title")), 1)
                          ]),
                          _createElementVNode("div", _hoisted_17, [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("ul", _hoisted_19, [
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item accordionItem",
                                    onClick: _cache[1] || (_cache[1] = 
                                    () =>
                                      openOffcanvasForm('productsCustomFields')
                                  )
                                  }, _toDisplayString(_ctx.$t("products.title")), 1)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item accordionItem",
                                    onClick: _cache[2] || (_cache[2] = 
                                    () =>
                                      openOffcanvasForm(
                                        'technicalSheetCustomFields'
                                      )
                                  )
                                  }, _toDisplayString(_ctx.$t("products.technicalSheetsTitle")), 1)
                                ]),
                                _createElementVNode("li", null, [
                                  _createElementVNode("a", {
                                    class: "dropdown-item accordionItem",
                                    onClick: _cache[3] || (_cache[3] = 
                                    () =>
                                      openOffcanvasForm(
                                        'workplacesFlowCustomFields'
                                      )
                                  )
                                  }, _toDisplayString(_ctx.$t("products.workplacesFlowsTitle")), 1)
                                ])
                              ])
                            ])
                          ])
                        ])
                      ])
                    ])
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref: (el) => (offCanvasRefs.value[0] = el),
      canvasName: 
      formName.value === 'productsCustomFields'
        ? _ctx.$t('customFields.title')
        : formName.value === 'create'
        ? _ctx.$t('products.form.createTitle')
        : formName.value === 'edit'
        ? _ctx.$t('products.form.editTitle')
        : `${_ctx.$t('customFields.title')} - ${_ctx.$t(
            `products.form.${formName.value?.split('CustomFields')[0]}Title`
          )}`
    
    }, {
      content: _withCtx(() => [
        (
          [
            'productsCustomFields',
            'technicalSheetCustomFields',
            'workplacesFlowCustomFields',
          ].includes(formName.value)
        )
          ? (_openBlock(), _createBlock(CustomFields, {
              "initial-module-name": moduleName.value,
              ref: (el) => (forms.value[0] = el),
              key: formKeys.value[0]
            }, null, 8, ["initial-module-name"]))
          : _createCommentVNode("", true),
        (formName.value === 'create' || formName.value === 'edit')
          ? (_openBlock(), _createBlock(CreateOrUpdateProduct, {
              ref: (el) => (forms.value[0] = el),
              "initial-values": tempRow.value,
              onHandleSubmit: handleSubmit,
              key: formKeys.value[0],
              openMeasureOffcanvas: () => openOffcanvasForm('measure')
            }, {
              default: _withCtx(() => [
                (formName.value === 'edit')
                  ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                      _createElementVNode("div", _hoisted_21, [
                        _createElementVNode("div", _hoisted_22, [
                          _createElementVNode("div", _hoisted_23, [
                            _createElementVNode("span", {
                              class: "actionableLabel",
                              onClick: _cache[4] || (_cache[4] = () => openOffcanvasForm('technicalSheet'))
                            }, _toDisplayString(_ctx.$t("products.form.technicalSheet")), 1)
                          ])
                        ]),
                        _createElementVNode("div", _hoisted_24, [
                          _createElementVNode("div", _hoisted_25, [
                            _createElementVNode("span", {
                              class: "actionableLabel",
                              onClick: _cache[5] || (_cache[5] = () => openOffcanvasForm('workplacesFlow'))
                            }, _toDisplayString(_ctx.$t("products.form.worplaceFlow")), 1)
                          ])
                        ])
                      ])
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["initial-values", "openMeasureOffcanvas"]))
          : _createCommentVNode("", true)
      ]),
      offcanvasChild: _withCtx(({ setRef }) => [
        _createVNode(GeneralOffcanvas, {
          canvasId: `${formNameChildren.value}`,
          ref: 
          (el) => {
            offCanvasRefs.value[1] = el;
            setRef(el);
          }
        ,
          canvasName: 
          formNameChildren.value === 'technicalSheet'
            ? _ctx.$t('technicalSheets.title', {name:(tempRow.value as Product).name, measure:(tempRow.value as Product).measure.name })
            : (formNameChildren.value === 'workplacesFlow'? _ctx.$t('workplacesFlows.title'): _ctx.$t('measures.title'))
        ,
          initialWidth: formNameChildren.value === 'technicalSheet' ? 80 : 30
        }, _createSlots({
          content: _withCtx(() => [
            (formNameChildren.value === 'technicalSheet' && formName.value === 'edit')
              ? (_openBlock(), _createBlock(CreateOrUpdateTechnicalSheet, {
                  ref: (el) => (forms.value[1] = el),
                  key: formKeys.value[1],
                  "product-id": tempRow.value?.id,
                  onHandleSubmit: _cache[7] || (_cache[7] = () => offCanvasRefs.value[1].closeOffCanvas())
                }, null, 8, ["product-id"]))
              : (
              formNameChildren.value === 'workplacesFlow' && formName.value === 'edit'
            )
                ? (_openBlock(), _createBlock(CreateOrUpdateWorkplacesFlow, {
                    key: formKeys.value[1] + 1,
                    ref: (el) => (forms.value[1] = el),
                    "product-id": tempRow.value?.id,
                    onHandleSubmit: _cache[8] || (_cache[8] = () => offCanvasRefs.value[1].closeOffCanvas())
                  }, null, 8, ["product-id"]))
                : (formNameChildren.value === 'measure')
                  ? (_openBlock(), _createBlock(CreateOrUpdateMeasure, {
                      key: 2,
                      ref: (el) => (forms.value[1] = el),
                      onHandleSubmit: _cache[9] || (_cache[9] = () => offCanvasRefs.value[1].closeOffCanvas())
                    }, null, 512))
                  : _createCommentVNode("", true)
          ]),
          offcanvasChild: _withCtx(({ setRef }) => [
            _createVNode(GeneralOffcanvas, {
              canvasId: "customFieldTechnicalSheetsCanvas",
              ref: 
              (el) => {
                offCanvasRefs.value[2] = el;
                setRef(el);
              }
            ,
              canvasName: `${_ctx.$t('customFields.title')} - ${_ctx.$t(
              `products.form.${formName.value?.split('CustomFields')[0]}Title`
            )}`
            }, {
              content: _withCtx(() => [
                (_openBlock(), _createBlock(CustomFields, {
                  "initial-module-name": moduleName.value,
                  key: moduleName.value
                }, null, 8, ["initial-module-name"]))
              ]),
              _: 2
            }, 1032, ["canvasName"])
          ]),
          _: 2
        }, [
          (formNameChildren.value && formName.value === 'edit')
            ? {
                name: "headerComplement",
                fn: _withCtx(() => [
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("div", _hoisted_27, [
                      _createElementVNode("div", _hoisted_28, [
                        _createElementVNode("div", _hoisted_29, [
                          _createElementVNode("button", {
                            class: "btn ml-1 btn-gear",
                            type: "button",
                            onClick: _cache[6] || (_cache[6] = () => openOffCanvas(2))
                          }, _toDisplayString(_ctx.$t("customFields.title")), 1)
                        ])
                      ])
                    ])
                  ])
                ]),
                key: "0"
              }
            : undefined
        ]), 1032, ["canvasId", "canvasName", "initialWidth"])
      ]),
      _: 1
    }, 8, ["canvasName"]),
    _createVNode(ConfirmModal, {
      title: confirmModalTitleTranslated.value,
      ref_key: "confirmationModalRef",
      ref: confirmationModalRef,
      message: confirmationQuestion.value,
      confirmAction: handleConfirm
    }, null, 8, ["title", "message"]),
    (headerTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: "/products/",
          filters: tableFilters.value,
          headers: headerTable.value,
          name: "productsTable",
          ref_key: "tableRef",
          ref: tableRef
        }, {
          technicalSheet: _withCtx(({ row }) => [
            (_unref(get)(row, 'technicalSheet', []).length > 0)
              ? (_openBlock(), _createElementBlock("i", {
                  key: 0,
                  class: "bi bi-file-earmark-arrow-down fs-4",
                  onClick: ($event: any) => (openTechnicalSheetPreview(row.id))
                }, null, 8, _hoisted_30))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }, 8, ["filters", "headers"]))
      : _createCommentVNode("", true),
    _createVNode(GeneralModal, {
      ref_key: "technicalSheetModal",
      ref: technicalSheetModal,
      modalId: "technicalSheetPreview",
      modalName: "FICHA TÉCNICA DEL PRODUCTO",
      size: "modal-xl"
    }, {
      default: _withCtx(() => [
        (currentProductId.value)
          ? (_openBlock(), _createBlock(PreviewTechnicalSheet, {
              "product-id": currentProductId.value,
              key: currentProductId.value
            }, null, 8, ["product-id"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ], 64))
}
}

})