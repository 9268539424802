import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export async function convertElementToPDF(element: HTMLElement): Promise<void> {
  const margin = 100;

  html2canvas(element, {
    useCORS: true,
    //allowTaint: true,
    scale: 2,
  }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [canvas.width + margin * 2, canvas.height + margin * 2],
    });
    pdf.addImage(imgData, "PNG", margin, margin, canvas.width, canvas.height);
    pdf.save("document.pdf");
  });
}
