import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { computed, Ref } from "vue";
import { useRoute } from "vue-router";
import InventoryView from "./InventoryView.vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'InventoriesView',
  setup(__props) {

const route = useRoute();
const routeKey: Ref<string> = computed(
  () => (route.fullPath || route.meta.moduleName) as string
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(InventoryView, { key: routeKey.value }))
}
}

})