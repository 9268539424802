import { ProfileInterface } from "../interfaces/Profile.interface";

export class ProfileDto implements ProfileInterface {
  id = NaN;
  name = "";
  description = "";
  updateAt = new Date();
  createAt = new Date();
  accountId = NaN;
  metadata = null;
  permissions = [];
}

export class Profile extends ProfileDto {
  constructor(profileDto: ProfileDto) {
    super();
    Object.assign(this, profileDto);
  }
}
