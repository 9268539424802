import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { DeleteDivisionInterface } from "@/store/divisions/interfaces/DeleteDivision.interface";
import { Division } from "@/store/divisions/models/Division.model";
import {
  CreateDivision,
  DeleteDivision,
  UpdateDivision,
} from "@/store/divisions/services/Division.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useDivisionsServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "divisions";

  const createDivision = async (
    payload: Division,
    callBackCreate: (division: Division) => void
  ): Promise<void> => {
    await CreateDivision(payload)
      .then((division: Division) => {
        store.commit("divisions/addDivision", division);
        callBackCreate(division);
        useToast().successToast(t("divisions.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateDivision = async (
    payload: Division,
    callBackUpdate: (division: Division) => void
  ): Promise<void> => {
    await UpdateDivision(payload)
      .then((division: Division) => {
        store.commit("divisions/updateDivision", payload);
        callBackUpdate(division);
        useToast().successToast(t("divisions.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteDivision = async (
    payload: DeleteDivisionInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteDivision(payload)
      .then(() => {
        callBackDelete();
        store.commit("divisions/deleteDivision", payload);
        useToast().successToast(t("divisions.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createDivision,
    updateDivision,
    deleteDivision,
  };
}
