<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("products.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => openOffcanvasForm('create')"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("products.form.add") }}
              </button>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-end">
              <div class="dropdown">
                <button
                  class="btn btn-secondary dropdown-toggle ml-1 btn-gear"
                  type="button"
                  id="mainDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i class="bi bi-gear"></i>
                </button>
                <ul class="dropdown-menu" aria-labelledby="mainDropdown">
                  <li>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button
                            class="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="false"
                            aria-controls="collapseOne"
                            @click="stopDropdownClose"
                          >
                            {{ $t("customFields.title") }}
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          class="accordion-collapse collapse"
                          aria-labelledby="headingOne"
                          data-bs-parent="#accordionExample"
                        >
                          <div class="accordion-body">
                            <ul class="list-unstyled">
                              <li>
                                <a
                                  class="dropdown-item accordionItem"
                                  @click="
                                    () =>
                                      openOffcanvasForm('productsCustomFields')
                                  "
                                  >{{ $t("products.title") }}</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item accordionItem"
                                  @click="
                                    () =>
                                      openOffcanvasForm(
                                        'technicalSheetCustomFields'
                                      )
                                  "
                                  >{{ $t("products.technicalSheetsTitle") }}</a
                                >
                              </li>
                              <li>
                                <a
                                  class="dropdown-item accordionItem"
                                  @click="
                                    () =>
                                      openOffcanvasForm(
                                        'workplacesFlowCustomFields'
                                      )
                                  "
                                  >{{ $t("products.workplacesFlowsTitle") }}</a
                                >
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    :ref="(el) => (offCanvasRefs[0] = el)"
    :canvasName="
      formName === 'productsCustomFields'
        ? $t('customFields.title')
        : formName === 'create'
        ? $t('products.form.createTitle')
        : formName === 'edit'
        ? $t('products.form.editTitle')
        : `${$t('customFields.title')} - ${$t(
            `products.form.${formName?.split('CustomFields')[0]}Title`
          )}`
    "
  >
    <template #content>
      <CustomFields
        v-if="
          [
            'productsCustomFields',
            'technicalSheetCustomFields',
            'workplacesFlowCustomFields',
          ].includes(formName)
        "
        :initial-module-name="moduleName"
        :ref="(el) => (forms[0] = el)"
        :key="formKeys[0]"
      ></CustomFields>
      <CreateOrUpdateProduct
        v-if="formName === 'create' || formName === 'edit'"
        :ref="(el) => (forms[0] = el)"
        :initial-values="tempRow"
        @handle-submit="handleSubmit"
        :key="formKeys[0]"
        :openMeasureOffcanvas="() => openOffcanvasForm('measure')"
      >
        <div class="row justify-content-end" v-if="formName === 'edit'">
          <div class="col-auto">
            <div class="row justify-content-end">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  @click="() => openOffcanvasForm('technicalSheet')"
                >
                  {{ $t("products.form.technicalSheet") }}
                </span>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <span
                  class="actionableLabel"
                  @click="() => openOffcanvasForm('workplacesFlow')"
                >
                  {{ $t("products.form.worplaceFlow") }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </CreateOrUpdateProduct>
    </template>
    <template #offcanvasChild="{ setRef }">
      <GeneralOffcanvas
        :canvasId="`${formNameChildren}`"
        :ref="
          (el) => {
            offCanvasRefs[1] = el;
            setRef(el);
          }
        "
        :canvasName="
          formNameChildren === 'technicalSheet'
            ? $t('technicalSheets.title', {name:(tempRow as Product).name, measure:(tempRow as Product).measure.name })
            : (formNameChildren === 'workplacesFlow'? $t('workplacesFlows.title'): $t('measures.title'))
        "
        :initialWidth="formNameChildren === 'technicalSheet' ? 80 : 30"
      >
        <template
          #headerComplement
          v-if="formNameChildren && formName === 'edit'"
        >
          <div class="col pr-0">
            <div class="container w-auto">
              <div class="row justify-content-end">
                <div class="col-auto">
                  <button
                    class="btn ml-1 btn-gear"
                    type="button"
                    @click="() => openOffCanvas(2)"
                  >
                    {{ $t("customFields.title") }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template #content>
          <CreateOrUpdateTechnicalSheet
            v-if="formNameChildren === 'technicalSheet' && formName === 'edit'"
            :ref="(el) => (forms[1] = el)"
            :key="formKeys[1]"
            :product-id="tempRow?.id"
            @handle-submit="() => offCanvasRefs[1].closeOffCanvas()"
          />
          <CreateOrUpdateWorkplacesFlow
            v-else-if="
              formNameChildren === 'workplacesFlow' && formName === 'edit'
            "
            :key="formKeys[1] + 1"
            :ref="(el) => (forms[1] = el)"
            :product-id="tempRow?.id"
            @handle-submit="() => offCanvasRefs[1].closeOffCanvas()"
          ></CreateOrUpdateWorkplacesFlow>
          <CreateOrUpdateMeasure
            v-else-if="formNameChildren === 'measure'"
            :ref="(el) => (forms[1] = el)"
            @handle-submit="() => offCanvasRefs[1].closeOffCanvas()"
          ></CreateOrUpdateMeasure>
        </template>
        <template #offcanvasChild="{ setRef }">
          <GeneralOffcanvas
            canvasId="customFieldTechnicalSheetsCanvas"
            :ref="
              (el) => {
                offCanvasRefs[2] = el;
                setRef(el);
              }
            "
            :canvasName="`${$t('customFields.title')} - ${$t(
              `products.form.${formName?.split('CustomFields')[0]}Title`
            )}`"
          >
            <template #content>
              <CustomFields
                :initial-module-name="moduleName"
                :key="moduleName"
              ></CustomFields>
            </template>
          </GeneralOffcanvas>
        </template>
      </GeneralOffcanvas>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal
    :title="confirmModalTitleTranslated"
    ref="confirmationModalRef"
    :message="confirmationQuestion"
    :confirmAction="handleConfirm"
  ></ConfirmModal>
  <GeneralTable
    v-if="headerTable"
    dataSource="/products/"
    :filters="tableFilters"
    :headers="headerTable"
    name="productsTable"
    ref="tableRef"
  >
    <template #technicalSheet="{ row }">
      <i
        v-if="get(row, 'technicalSheet', []).length > 0"
        class="bi bi-file-earmark-arrow-down fs-4"
        @click="openTechnicalSheetPreview(row.id)"
      ></i>
    </template>
  </GeneralTable>
  <GeneralModal
    ref="technicalSheetModal"
    modalId="technicalSheetPreview"
    modalName="FICHA TÉCNICA DEL PRODUCTO"
    size="modal-xl"
  >
    <PreviewTechnicalSheet
      v-if="currentProductId"
      :product-id="currentProductId"
      :key="currentProductId"
    ></PreviewTechnicalSheet>
  </GeneralModal>
</template>

<script lang="ts" setup>
import CustomFields from "@/components/customFields/CustomFields.vue";
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralModal from "@/components/globals/modals/GeneralModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateMeasure from "@/components/measures/CreateOrUpdateMeasure.vue";
import CreateOrUpdateProduct from "@/components/products/CreateOrUpdateProduct.vue";
import PreviewTechnicalSheet from "@/components/products/PreviewTechnicalSheet.vue";
import { ProductsHeader } from "@/components/products/templates/headers/Products.headers";
import CreateOrUpdateTechnicalSheet from "@/components/technicalSheets/CreateOrUpdateTechnicalSheet.vue";
import CreateOrUpdateWorkplacesFlow from "@/components/workplaces-flows/CreateOrUpdateWorkplacesFlow.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProductsServices } from "@/composables/useProductsServices";
import { Product } from "@/store/products/models/Product.model";
import { get } from "lodash";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
type CanvasTypes =
  | "create"
  | "edit"
  | "productsCustomFields"
  | "technicalSheetCustomFields"
  | "workplacesFlowCustomFields";
type CanvasChildrenTypes = "technicalSheet" | "workplacesFlow" | "measure";

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { deleteProduct } = useProductsServices();
const { t } = useI18n();

const formKeys = ref([0, 0, 0]);
const forms = ref([]);
const tableRef = ref();
const offCanvasRefs = ref([]);
const formName: Ref<CanvasTypes> = ref("create");
const formNameChildren: Ref<CanvasChildrenTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<Product | null> = ref();
const technicalSheetModal = ref();
const currentProductId = ref();

const moduleName = computed(() => {
  if (
    formNameChildren.value === "technicalSheet" ||
    formName.value === "technicalSheetCustomFields"
  ) {
    return "technical_sheets";
  } else if (
    formNameChildren.value === "workplacesFlow" ||
    formName.value === "workplacesFlowCustomFields"
  ) {
    return "workplace_flows";
  }
  return "products";
});

const confirmModalTitleTranslated = computed(() => {
  if (action.value === "delete") {
    return t("global.warnings.delete");
  }
  return "";
});

const question = computed(() => {
  if (action.value === "delete") {
    return "questionDelete";
  }
  return "";
});

const confirmationQuestion = computed(() => {
  return {
    value: `products.${question.value}`,
    params: {
      name: tempRow.value?.name || "",
    },
    needsTranslate: true,
  };
});

const openOffCanvas = (index) => {
  formKeys.value[index]++;
  offCanvasRefs.value?.[index]?.openOffCanvas?.();
};

const stopDropdownClose = (event) => {
  event.stopPropagation();
};

function handleSubmit(data) {
  const { product, isCreating } = data;
  if (isCreating) {
    tableRef.value.currentData = [product, ...tableRef.value.currentData];
  } else {
    tableRef.value.updateRow(lastRowIndex.value, product);
  }
  offCanvasRefs.value[0].closeOffCanvas();
}

function runAction(actionType: "edit" | "delete", row: Product, index: number) {
  if (actionType === "edit") {
    openOffcanvasForm("edit");
  } else if (actionType === "delete") {
    confirmationModalRef.value?.openConfirmationModal();
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

async function handleConfirm() {
  if (action.value === "delete") {
    await deleteProduct(tempRow.value, () => {
      tableRef.value.deleteRow(lastRowIndex.value);
    });
  }
}

function isCanvasType(
  value: CanvasTypes | CanvasChildrenTypes
): value is CanvasTypes {
  return [
    "create",
    "edit",
    "productsCustomFields",
    "technicalSheetCustomFields",
    "workplacesFlowCustomFields",
  ].includes(value);
}

function openOffcanvasForm(newFormName: CanvasTypes | CanvasChildrenTypes) {
  if (isCanvasType(newFormName)) {
    tempRow.value = null;
    formName.value = newFormName;
    formNameChildren.value = null;
    openOffCanvas(0);
  } else {
    formNameChildren.value = newFormName;
    openOffCanvas(1);
  }
}

function openTechnicalSheetPreview(id: number) {
  currentProductId.value = id;
  technicalSheetModal.value.openModal();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    [customFields],
    () => {
      headerTable.value = ProductsHeader(runAction, customFields.value);
    },
    { immediate: true, deep: true }
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

i {
  cursor: pointer;
}

.modal {
  background-color: $BackgroundModal;
}

.accordion-item {
  border: none;
}

.accordionItem {
  border-bottom: rgb(204, 204, 204) 1px solid;
  cursor: pointer;
  &:hover {
    background-color: rgb(216, 216, 216);
  }
}

.accordion-body {
  background-color: #f6f6f6;
}

.accordion-button:not(.collapsed) {
  background-color: #ffffff;
  box-shadow: none;
}
</style>
