<template>
  <div class="row">
    <div class="col-12">
      <h1 style="display: flex">{{ $t("profiles.title") }}</h1>
    </div>
    <div class="col-12">
      <div class="row">
        <div class="col">
          <div class="row g-2">
            <div class="col-12 d-flex justify-content-start">
              <button
                type="button"
                class="btn btn-primary"
                @click="() => offCanvasRef.openOffCanvas()"
              >
                <i class="bi bi-person-plus me-2"></i>
                {{ $t("profiles.form.add") }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <GeneralOffcanvas
    canvasId="customFieldCanvas"
    ref="offCanvasRef"
    :canvasName="
      !profileTemp
        ? $t('profiles.form.createTitle')
        : $t('profiles.form.editTitle')
    "
    @closed="customFieldsClosed"
    ><template v-slot:content>
      <CreateOrUpdateProfile
        ref="form"
        :initial-values="profileTemp"
        @handle-submit="handleProfileSubmit"
      ></CreateOrUpdateProfile>
    </template>
  </GeneralOffcanvas>
  <ConfirmModal :title="confirmModalDeleteProfile" ref="confirmationModalRef">
    <DeleteProfile
      v-if="profileTemp?.id"
      :key="profileTemp.id"
      :handle-submit="handleConfirm"
      :current-row="profileTemp"
    ></DeleteProfile>
  </ConfirmModal>
  <GeneralTable
    v-if="profilesHeaderTable"
    dataSource="/profiles/"
    :filters="tableFilters"
    :headers="profilesHeaderTable"
    name="usersTable"
    ref="profileTable"
  ></GeneralTable>
</template>

<script lang="ts" setup>
import ConfirmModal from "@/components/globals/modals/ConfirmModal.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import CreateOrUpdateProfile from "@/components/profiles/CreateOrUpdateProfile.vue";
import DeleteProfile from "@/components/profiles/DeleteProfile.vue";
import { ProfileHeader } from "@/components/profiles/templates/headers/Profile.headers";
import { usePermissionsServices } from "@/composables/usePermissionsServices";
import { useProfilesServices } from "@/composables/useProfilesServices";
import { User } from "@/store/auth/models/User.model";
import { Profile } from "@/store/profiles/models/Profile.model";
import { onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";

const { permissions, getPermissions } = usePermissionsServices();
const { deleteProfile } = useProfilesServices();
const { t } = useI18n();

const form = ref();
const profileTable = ref();
const offCanvasRef = ref();
const profilesHeaderTable = ref();
const profileTemp: Ref<Profile | null> = ref();
const lastRowIndex: Ref<number> = ref();
const confirmationModalRef = ref();
const action: Ref<"edit" | "delete"> = ref();
const confirmModalDeleteProfile: Ref<string> = ref("");

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

const tableFilters = ref([]);

function handleProfileSubmit(data) {
  const { profile, isCreating } = data;
  if (isCreating) {
    profileTable.value.currentData = [
      profile,
      ...profileTable.value.currentData,
    ];
  } else {
    profileTable.value.currentData[lastRowIndex.value] = {
      ...profileTable.value.currentData[lastRowIndex.value],
      ...profile,
    };
  }
  offCanvasRef.value.closeOffCanvas();
}

function optionEdit(profile: Profile, index: number) {
  offCanvasRef.value.openOffCanvas();
  action.value = "edit";
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
}

function optionDelete(profile: Profile, index: number) {
  action.value = "delete";
  confirmModalDeleteProfile.value = t("global.warnings.delete");
  profileTemp.value = { ...profile };
  lastRowIndex.value = index;
  confirmationModalRef.value?.openConfirmationModal();
}

async function handleConfirm(values: { newId: { id: number; label: string } }) {
  if (action.value === "delete") {
    const {
      newId: { id: newId },
    } = values;
    const { id: oldId } = profileTemp.value;

    await deleteProfile({ oldId, newId }, () => {
      profileTable.value.currentData = profileTable.value.currentData.filter(
        (profile: User, index) => {
          return index !== lastRowIndex.value;
        }
      );
    });
  }
  confirmationModalRef.value?.closeConfirmationModal();
}

onMounted(async () => {
  await getPermissions();
  profilesHeaderTable.value = ProfileHeader(
    optionEdit,
    optionDelete,
    permissions.value
  );
});
</script>

<style lang="scss" scoped>
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}

.modal {
  background-color: $BackgroundModal;
}
</style>
