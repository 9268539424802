<template>
  <div v-if="debug">{{ values }}</div>
  <div class="container text-start">
    <form @submit="onSubmit" class="row" :key="formName">
      <div
        class="col-12"
        v-for="(section, sectionIndex) in form?.sections"
        :key="`${formName}Section-${sectionIndex}`"
      >
        <div
          v-if="section.conditions ? section.conditions() : true"
          class="row"
        >
          <SectionName v-if="section.name" :name="section.name" />
          <div
            v-for="(field, fieldIndex) in section?.fields"
            :key="`${formName}Section-${sectionIndex}-Field-${fieldIndex}`"
            :class="`col-lg-${field.widthDistribution ?? 12} mt-2`"
            :style="`display: ${
              field.conditions?.() ?? true ? 'block' : 'none'
            }`"
          >
            <div class="row" v-if="field.conditions?.() ?? true">
              <div :class="`col-12 mt-2`">
                <MultipleInputMaster
                  v-if="'fields' in field"
                  :form="formRef"
                  :errorBag="errorBag"
                  :sectionIndex="sectionIndex"
                  :fieldIndex="fieldIndex"
                >
                  <template
                    #dynamic-slot="{ sectionIndex, fieldIndex, subfieldIndex }"
                  >
                    <slot
                      :name="`section-${sectionIndex}-field-${fieldIndex}-subField-${subfieldIndex}`"
                    >
                    </slot>
                  </template>
                </MultipleInputMaster>
                <InputMaster
                  v-else
                  :field="field as Field"
                  :errorBag="errorBag"
                  :disabled-default-option="true"
                ></InputMaster>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div v-if="debug">
                  {{ `section-${sectionIndex}-field-${fieldIndex}` }}
                </div>
                <slot
                  :name="`section-${sectionIndex}-field-${fieldIndex}`"
                ></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <slot></slot>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col-12">
            <div class="col-12 p-0 mt-2">
              <ButtonMaster
                v-if="form?.submit?.text"
                :text="form?.submit?.text"
                :isLoading="isSubmitting"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form as FormType } from "@/shared/globals/forms/interfaces/Form.interface";
import { YupValidator } from "@/shared/globals/helpers/YupValidator";
import { useForm } from "vee-validate";
import { defineExpose, defineProps, PropType, ref, toRef, watch } from "vue";
import ButtonMaster from "../buttons/ButtonMaster.vue";
import MultipleInputMaster from "../inputs/MultipleInputMaster.vue";
import SectionName from "./SectionName.vue";

const props = defineProps({
  form: { type: Object as PropType<FormType>, required: true },
  formName: { type: String, required: true },
  debug: { type: Boolean, required: false, default: false },
});
const yupValidator = ref(createYupValidator());

const formRef = toRef(props.form);
const formProps = useForm();
const {
  values,
  handleSubmit,
  isSubmitting,
  setErrors,
  resetForm,
  setFieldValue,
} = formProps;

const errorBag = ref();

const onSubmit = handleSubmit(async () => {
  const isValid = await validateForm();
  if (isValid) {
    await props.form.submit.action();
  }
});

async function validateForm(): Promise<boolean> {
  const validations = await yupValidator.value.getValidations(values);
  const isValid = Object.values(validations).every((validation) => {
    const value = validation as { error: string; valid: boolean }[];
    return value.every((v) => v.valid);
  });
  if (!isValid) {
    setErrors(validations);
  }
  return isValid;
}

function createYupValidator() {
  const yupValidator = new YupValidator();
  const schema = props.form
    ? props.form.sections.reduce((acc, section) => {
        if (!section.conditions || section.conditions()) {
          section.fields.reduce((acc, field) => {
            if (!field.conditions || field.conditions()) {
              if (
                !Array.isArray(field) &&
                "path" in field &&
                field.methodsYup
              ) {
                const keys = field.path.split(".");
                keys.reduce((acc, key, index, arr) => {
                  if (index === arr.length - 1) {
                    acc[key] = field.methodsYup();
                  }
                  return acc[key] || (acc[key] = {});
                }, acc);
              } else if ("fields" in field) {
                for (const oneField of field.fields) {
                  const keys = oneField.path.split(".");
                  keys.reduce((acc, key, index, arr) => {
                    if (index === arr.length - 1) {
                      acc[key] = oneField.methodsYup?.();
                    }
                    return acc[key] || (acc[key] = {});
                  }, acc);
                }
              }
            }
            return acc;
          }, acc);
        }

        return acc;
      }, {})
    : {};
  yupValidator.addSchema(schema, []);
  return yupValidator;
}

function resetAllFields() {
  resetForm();
  props.form?.sections?.forEach((section) => {
    section.fields?.forEach((field) => {
      if ("fields" in field) {
        for (const oneField of field.fields) {
          if ("type" in oneField && oneField.type === "select") {
            setFieldValue(oneField.path, "");
          }
        }
      } else if ("type" in field && field.type === "select") {
        setFieldValue(field.path, "");
      }
    });
  });
}

watch(
  [values, formRef],
  async () => {
    yupValidator.value = createYupValidator();
    const error = await yupValidator.value.getValidations(values);
    errorBag.value = error;
  },
  { deep: true, immediate: true }
);

defineExpose({ ...formProps, validateForm, resetAllFields });
</script>

<style lang="scss"></style>
