<template>
  <InputMaster
    v-if="columnHeader.filterType"
    v-model="value"
    @enter="() => applyFilter(true)"
    :field="inputData"
    :reachableValue="true"
    :ref="inputData.inputName"
  />
</template>

<script lang="ts" setup>
import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { generateFilterField } from "@/shared/globals/tables/helpers/tables.helper";
import { get } from "lodash";
import { FieldContext } from "vee-validate";
import {
  PropType,
  Ref,
  computed,
  defineEmits,
  defineProps,
  onMounted,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";

const props = defineProps({
  columnHeader: { type: Object as PropType<TableHeader>, required: true },
});
const value: Ref<{ value: any; type: FieldType }> = ref();
const emit = defineEmits(["applyFilters"]);

const inputData = computed(() => {
  return {
    ...generateFilterField(props.columnHeader),
    disabledDefaultOption: false,
  };
});

const inputRef = useTemplateRef<FieldContext>(inputData.value.inputName);

const inputValue = computed(() => {
  if (["select", "multiple-select"].includes(props.columnHeader.filterType)) {
    return get(value, "value.id", null);
  }
  return get(value, "value", null);
});

const path = computed(() => {
  if (props.columnHeader.isCustomField) {
    return `customFields.${props.columnHeader.mappedKey}`;
  }
  return props.columnHeader.key;
});

watch(inputValue, () => {
  if (
    ["select", "multiple-select", "date"].includes(
      props.columnHeader.filterType
    )
  ) {
    applyFilter(true);
  } else if (
    ["text"].includes(props.columnHeader.filterType) &&
    inputValue.value === ""
  ) {
    applyFilter(true);
  }
});

function applyFilter(forced?: boolean) {
  if (![undefined, null, ""].includes(inputValue.value) || forced) {
    emit("applyFilters", {
      value: inputValue.value,
      path: path.value,
    });
  }
}

onMounted(() => {
  inputRef.value?.setValue("");
});
</script>
