import axiosInstance from "@/middlewares/Routing.middleware";
import { DeleteDivisionInterface } from "@/store/divisions/interfaces/DeleteDivision.interface";
import { Division } from "../models/Division.model";

export async function CreateDivision(payload: Division): Promise<Division> {
  return (await axiosInstance.post(`divisions`, payload)).data;
}

export async function UpdateDivision(payload: Division): Promise<Division> {
  const { id, ...divisionProps } = payload;
  return (await axiosInstance.put(`divisions/${id}`, divisionProps)).data;
}

export async function DeleteDivision(
  payload: DeleteDivisionInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`divisions?newId=${newId}&oldId=${oldId}`);
}
