import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { t } from "@/shared/locales/services/i18n.services";
import { ButtonTranslateTemplate } from "./CreateOrUpdateSelectSettings.template";

export const CreateOrUpdateCustomFieldTemplate = (
  action,
  formMain,
  validations,
  initialValues,
  isCreating,
  actionSettings,
  actionTranslate
): Form => {
  const { lang } = useLanguageServices();

  return {
    sections: [
      {
        fields: [
          {
            path: "customField.name",
            inputName: "customFieldName",
            label: {
              value: "customFields.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
            buttons: [{ ...ButtonTranslateTemplate, action: actionTranslate }],
          },
          {
            path: "customField.type",
            inputName: "customFieldType",
            label: {
              value: "customFields.type",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "value",
              labelKey: "name",
              isMultiple: false,
              initialOptions: [
                {
                  name: t("customFields.types.text"),
                  value: "text",
                },
                {
                  name: t("customFields.types.number"),
                  value: "number",
                },
                {
                  name: t("customFields.types.select"),
                  value: "select",
                },
                {
                  name: t("customFields.types.multipleSelect"),
                  value: "multiple-select",
                },
                {
                  name: t("customFields.types.date"),
                  value: "date",
                },
              ],
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
            buttons: [
              {
                conditions(value) {
                  if (["text", "number", "multiple-select"].includes(value)) {
                    return true;
                  }
                  return false;
                },
                backgroundColor: "#7451c2",
                customClass: "ml-1",
                disableCustomClass: "disabled",
                graphic: {
                  icon: {
                    icon: "bi bi-gear",
                    color: "white",
                    backgroundColor: "#7451c2",
                  },
                },
                action: actionSettings,
                metadata: {
                  isAppend: false,
                  positionInField: "end",
                },
              },
            ],
            disabled: initialValues ? true : false,
          },
          {
            path: "customField.required",
            inputName: "isCustomFieldRequired",
            label: {
              value: "customFields.required",
              needsTranslate: true,
            },
            type: "checkbox",
            metadata: {
              labelPosition: "right",
              tooltipPosition: "top",
            },
          },
        ],
      },
      {
        conditions: () => {
          return (
            formMain.value?.values?.customField?.type?.id === "select" ||
            formMain.value?.values?.customField?.type?.id === "multiple-select"
          );
        },
        name: { value: "customFields.optionsValues", needsTranslate: true },
        fields: [],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "customFields.addField"
          : "customFields.updateField",
        needsTranslate: true,
      },
      action,
    },
  };
};
