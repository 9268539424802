import axiosInstance from "@/middlewares/Routing.middleware";
import { DeleteMeasureInterface } from "@/store/measures/interfaces/DeleteMeasure.interface";
import { Measure } from "../models/Measure.model";

export async function GetAllMeasures(): Promise<Measure[]> {
  return (await axiosInstance.get(`measures/all`)).data;
}

export async function CreateMeasure(payload: Measure): Promise<Measure> {
  return (await axiosInstance.post(`measures`, payload)).data;
}

export async function UpdateMeasure(payload: Measure): Promise<Measure> {
  const { id, ...measureProps } = payload;
  return (await axiosInstance.put(`measures/${id}`, measureProps)).data;
}

export async function DeleteMeasure(
  payload: DeleteMeasureInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`measures?newId=${newId}&oldId=${oldId}`);
}
