import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "text-start" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "text-start" }
const _hoisted_5 = { class: "d-flex flex-row justify-content-center flex-wrap" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "text-start" }
const _hoisted_9 = {
  key: 0,
  class: "col-12"
}
const _hoisted_10 = { class: "text-start" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "text-start" }
const _hoisted_13 = { class: "d-flex flex-row justify-content-center flex-wrap" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "col-12" }
const _hoisted_16 = { class: "text-start" }
const _hoisted_17 = { class: "text-start" }
const _hoisted_18 = { class: "col-12" }
const _hoisted_19 = { class: "text-start" }
const _hoisted_20 = { class: "d-flex flex-row justify-content-center flex-wrap mb-3" }
const _hoisted_21 = ["src"]
const _hoisted_22 = { class: "col-12" }
const _hoisted_23 = { class: "text-start" }
const _hoisted_24 = { class: "col-12" }
const _hoisted_25 = { class: "text-start" }
const _hoisted_26 = { class: "col-12" }
const _hoisted_27 = { class: "text-start" }
const _hoisted_28 = { class: "col-12" }
const _hoisted_29 = { class: "text-start" }
const _hoisted_30 = { class: "row" }
const _hoisted_31 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { convertElementToPDF } from "@/shared/globals/helpers/ComponentConverter.helper";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { get } from "lodash";
import { computed, onMounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { createPrintButton } from "../globals/buttons/templates/PrintButton.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'PreviewTechnicalSheet',
  props: {
  productId: { type: Number, required: true },
},
  setup(__props) {

const props = __props;

const elementToPrint: Ref<HTMLElement> = ref();

const printButton = createPrintButton(() => {
  convertElementToPDF(elementToPrint.value);
});

const { t } = useI18n();

const { technicalSheet, getTechnicalSheet } = useTechnicalSheetsServices(
  props.productId
);
const { workplaceFlow, getWorkplacesFlow } = useWorkplacesFlowsServices(
  props.productId
);
const {
  customFields: technicalSheetCustomFields,
  getModuleCustomFields: getTechnicalSheetCustomFields,
} = useCustomFieldsServices("technical_sheets");
const {
  customFields: workplaceFlowsCustomFields,
  getModuleCustomFields: getWorkplaceFlowsCustomFields,
} = useCustomFieldsServices("workplace_flows");

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (value, key, item) => {
      return item.quantity;
    },
  },
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (value, key, item) => {
      return item.rawMaterial.description;
    },
  },
  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (value, key, item) => {
      return item.rawMaterial.thickness;
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (value, key, item) => {
      return item.rawMaterial.width;
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (value, key, item) => {
      return item.rawMaterial.length;
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.volume;
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.volume * item.quantity;
    },
  },
];

const criteriasFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "order",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.criteriaOrder"),
    formatter: (value, key, item) => {
      return item.order;
    },
  },
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.description"),
    formatter: (value, key, item) => {
      return item.description;
    },
  },
  {
    key: "isValid",
    sortable: false,
    label: t("technicalSheets.entity.qualityCriteria.isValid"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

const technicalSheetCustomFieldsFields: Exclude<TableFieldRaw<any>, string>[] =
  [
    {
      key: "fields",
      sortable: false,
      label: t("customFields.singularTitle"),
      formatter: (value, key, item) => {
        return translateFromKey(item, "name");
      },
    },
    {
      key: "values",
      sortable: false,
      label: t("customFields.value"),
      formatter: (value, key, item) => {
        return get(
          technicalSheet.value?.product?.technicalSheetCustomFields,
          item.id,
          ""
        );
      },
    },
  ];

const workplaceFlowFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "step",
    sortable: false,
    label: t("workplacesFlows.entity.step"),
    formatter: (value, key, item) => {
      return item.step;
    },
  },
  {
    key: "workplace",
    sortable: false,
    label: t("workplacesFlows.entity.workplace"),
    formatter: (value, key, item) => {
      return translateFromKey(item.workplace, "name");
    },
  },
  {
    key: "duration",
    sortable: false,
    label: t("workplacesFlows.entity.workTime"),
    formatter: (value, key, item) => {
      return item.workTime;
    },
  },
];

const workplaceFlowsCustomFieldsFields: Exclude<TableFieldRaw<any>, string>[] =
  [
    {
      key: "fields",
      sortable: false,
      label: t("customFields.singularTitle"),
      formatter: (value, key, item) => {
        return translateFromKey(item, "name");
      },
    },
    {
      key: "values",
      sortable: false,
      label: t("customFields.value"),
      formatter: (value, key, item) => {
        return get(
          technicalSheet.value?.product?.workplaceFlowCustomFields,
          item.id,
          ""
        );
      },
    },
  ];

const materialType = computed(() => {
  return `technicalSheets.type.${technicalSheet.value?.product?.materialType}`;
});

onMounted(async () => {
  await Promise.all([
    getTechnicalSheet(),
    getWorkplacesFlow(),
    getTechnicalSheetCustomFields(),
    getWorkplaceFlowsCustomFields(),
  ]);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: "row",
      ref_key: "elementToPrint",
      ref: elementToPrint
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.name")), 1),
          _createElementVNode("span", null, _toDisplayString(_unref(technicalSheet)?.product?.name) + " " + _toDisplayString(_unref(technicalSheet)?.product?.measure?.name), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("p", _hoisted_4, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.images")) + ": ", 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(technicalSheet)?.product?.descriptiveImages ??
          [], (image, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: `descriptiveImages${index}`,
              style: {"object-fit":"cover","height":"200px"},
              src: image,
              crossOrigin: ""
            }, null, 8, _hoisted_6))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("p", _hoisted_8, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.materialType")), 1),
          _createTextVNode(_toDisplayString(_ctx.$t(materialType.value)), 1)
        ])
      ]),
      (_unref(technicalSheet)?.rawMaterials)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, [
              _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.rawMaterial")) + ":", 1)
            ]),
            _createVNode(_unref(BTable), {
              items: _unref(technicalSheet)?.rawMaterials,
              fields: rawMaterialsFields
            }, null, 8, ["items"])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("p", _hoisted_12, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.manufactureSpecification.title")) + ": ", 1)
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(technicalSheet)?.product
            ?.manufactureSpecification.images ?? [], (image, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: `descriptiveImages${index}`,
              style: {"object-fit":"cover","width":"100%"},
              src: image,
              crossOrigin: ""
            }, null, 8, _hoisted_14))
          }), 128))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("p", _hoisted_16, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.manufactureSpecification.subtitle")) + ": ", 1)
        ]),
        _createElementVNode("div", null, [
          _createElementVNode("ul", _hoisted_17, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(technicalSheet)?.product
              ?.manufactureSpecification?.details ?? [], (detail, index) => {
              return (_openBlock(), _createElementBlock("li", {
                key: `detail.${index}`
              }, _toDisplayString(detail), 1))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_18, [
        _createElementVNode("p", _hoisted_19, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.instructions.title")), 1)
        ]),
        _createElementVNode("div", _hoisted_20, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(technicalSheet)?.product?.instructions
            .images ?? [], (image, index) => {
            return (_openBlock(), _createElementBlock("img", {
              key: `instructions${index}`,
              style: {"object-fit":"cover","height":"200px"},
              src: image,
              crossOrigin: ""
            }, null, 8, _hoisted_21))
          }), 128))
        ]),
        _createElementVNode("b", null, _toDisplayString(_unref(technicalSheet)?.product?.instructions.description), 1)
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("p", _hoisted_23, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.qualityCriteria.title")), 1)
        ]),
        _createVNode(_unref(BTable), {
          items: 
          _unref(technicalSheet)?.product?.qualityCriteria?.qualityCriterias ?? []
        ,
          fields: criteriasFields
        }, null, 8, ["items"]),
        _createElementVNode("p", null, [
          _createElementVNode("b", null, _toDisplayString(_unref(technicalSheet)?.product?.instructions.description), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_24, [
        _createElementVNode("p", _hoisted_25, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("technicalSheets.entity.customfields")), 1)
        ]),
        _createVNode(_unref(BTable), {
          items: _unref(technicalSheetCustomFields) ?? [],
          fields: technicalSheetCustomFieldsFields
        }, null, 8, ["items"])
      ]),
      _createElementVNode("div", _hoisted_26, [
        _createElementVNode("p", _hoisted_27, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("workplacesFlows.form.operativeSequency")), 1)
        ]),
        _createVNode(_unref(BTable), {
          items: _unref(workplaceFlow)?.steps ?? [],
          fields: workplaceFlowFields
        }, null, 8, ["items"])
      ]),
      _createElementVNode("div", _hoisted_28, [
        _createElementVNode("p", _hoisted_29, [
          _createElementVNode("b", null, _toDisplayString(_ctx.$t("workplacesFlows.entity.customfields")), 1)
        ]),
        _createVNode(_unref(BTable), {
          items: _unref(workplaceFlowsCustomFields) ?? [],
          fields: workplaceFlowsCustomFieldsFields
        }, null, 8, ["items"])
      ])
    ], 512),
    _createElementVNode("div", _hoisted_30, [
      _createElementVNode("div", _hoisted_31, [
        _createVNode(ButtonField, { "button-field": _unref(printButton) }, null, 8, ["button-field"])
      ])
    ])
  ], 64))
}
}

})