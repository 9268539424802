import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, isRef as _isRef, vModelText as _vModelText, withKeys as _withKeys, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "row g-1" }
const _hoisted_3 = { class: "col-12 text-start" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "row g-0 flex-nowrap" }
const _hoisted_7 = {
  key: 0,
  class: "col-auto my-auto text-start"
}
const _hoisted_8 = ["id"]
const _hoisted_9 = ["id"]
const _hoisted_10 = ["name", "id", "placeholder", "aria-describedby", "disabled"]
const _hoisted_11 = ["type", "name", "id", "placeholder", "aria-describedby", "disabled", "step"]
const _hoisted_12 = ["id"]
const _hoisted_13 = { key: 0 }
const _hoisted_14 = ["innerHTML"]
const _hoisted_15 = {
  key: 1,
  class: "col-auto my-auto text-start"
}
const _hoisted_16 = ["id"]

import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Dropdown } from "bootstrap";
import { merge } from "lodash";
import { useField } from "vee-validate";
import {
  computed,
  onMounted,
  PropType,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ProcessText from "../UiTools/ProcessText.vue";
import ButtonField from "../buttons/ButtonField.vue";
import { eyeClosedButton } from "../buttons/templates/EyeClosedButton.template";
import { eyeOpenedButton } from "../buttons/templates/EyeOpenedButton.template";
import FilesMaster from "./FilesMaster.vue";
import SelectMaster from "./SelectMaster.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'InputMaster',
  props: {
  field: {
    type: Object as PropType<Field>,
    required: true,
  },
  errorBag: {
    type: Object,
    required: false,
  },
  disabledDefaultOption: {
    type: Boolean,
    required: false,
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["update:modelValue", "enter"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const emit = __emit;
const { t } = useI18n();
const dropdown: Ref<Dropdown> = ref(undefined);
const passwordType = ref("password");

const { value, meta, errors, setValue, ...propsField } = useField<any>(
  props.field.path
);
const eyeButtonTemplate = computed(() => {
  if (passwordType.value === "password") {
    return eyeOpenedButton(() => {
      passwordType.value = "text";
    });
  }
  return eyeClosedButton(() => {
    passwordType.value = "password";
  });
});

const inputType = computed(() => {
  if (props.field.type === "decimal") {
    return "number";
  }
  if (props.field.type === "password") {
    return passwordType.value;
  }
  return props.field.type;
});

function showDropdown(): void {
  if (
    props.errorBag &&
    props.errorBag[props.field.path] &&
    props.errorBag[props.field.path].length > 0
  ) {
    dropdown.value.show();
  }
}

function hideDropdown(): void {
  if (props.errorBag && props.errorBag[props.field.path]) {
    dropdown.value.hide();
  }
}

const getInputClass = computed(() => {
  if (props.field.type == "checkbox") {
    return "form-check-input";
  }
  return "form-control";
});
const getPlaceholder = (): string => {
  if (!props.field.placeholder) return "";
  if (props.field.placeholder.needsTranslate) {
    return t(props.field.placeholder.value, props.field.placeholder.params);
  }
  return props.field.placeholder.value;
};

const mergedMetadata = computed(() => {
  const defaultMetadata = {
    tooltipPosition: "right",
    iconPosition: "end",
    labelPosition: "top",
    fieldSettings: {
      color: "blue",
    },
  };
  return merge({}, defaultMetadata, props.field.metadata);
});

const formButtons = computed(() => {
  return {
    startAppendButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && button.metadata.isAppend
    ),
    endAppendButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && button.metadata.isAppend
    ),
    startButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "start" && !button.metadata.isAppend
    ),
    endButtons: props.field.buttons?.filter(
      (button) =>
        button.metadata.positionInField === "end" && !button.metadata.isAppend
    ),
  };
});

const isValidClassComputed = computed(() => {
  if (
    props.errorBag &&
    ((errors.value.length > 0 && meta.validated) || value.value)
  ) {
    const isValid = props.errorBag[props.field.path]
      ? props.errorBag[props.field.path].every((e) => e.valid)
      : true;
    if (isValid) {
      return "is-valid";
    }
    return "is-invalid";
  }
  return "";
});

const windowWidth = ref();
window.addEventListener("resize", () => {
  windowWidth.value = window.innerWidth;
});

watch(value, () => {
  emit("update:modelValue", value.value);
});

onMounted(() => {
  watch(
    windowWidth,
    () => {
      let tooltipPosition = mergedMetadata.value.tooltipPosition;
      if (
        windowWidth.value < 992 &&
        (tooltipPosition === "left" || tooltipPosition === "right")
      ) {
        tooltipPosition = "bottom";
      }
      try {
        dropdown.value = new Dropdown(
          `#${props.field.inputName}InputDropdown`,
          {
            display: "dynamic",
            popperConfig: function (defaultBsPopperConfig) {
              defaultBsPopperConfig.placement = tooltipPosition;
              return defaultBsPopperConfig;
            },
          }
        );
      } catch (e) {
        console.error(e);
      }
    },
    { immediate: true }
  );
});

__expose({ value, meta, errors, setValue, ...propsField });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, "value: " + _toDisplayString(_unref(value)), 1))
      : _createCommentVNode("", true),
    (_openBlock(), _createElementBlock("div", {
      key: __props.field.path
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (mergedMetadata.value?.labelPosition === 'top' && props.field.label)
            ? (_openBlock(), _createElementBlock("label", {
                key: 0,
                for: props.field.inputName,
                class: "fw-medium fs-6"
              }, [
                _createVNode(ProcessText, {
                  value: props.field.label
                }, null, 8, ["value"])
              ], 8, _hoisted_4))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (mergedMetadata.value?.labelPosition === 'left' && props.field.label)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("span", {
                    class: "fw-medium fs-6 pr-1",
                    id: `${props.field.inputName}GroupPrepend`
                  }, [
                    _createVNode(ProcessText, {
                      value: props.field.label
                    }, null, 8, ["value"])
                  ], 8, _hoisted_8)
                ]))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startButtons, (startButton, startButtonIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-auto",
                key: `startButton${props.field.inputName}-${startButtonIndex}`
              }, [
                (
                startButton.conditions ? startButton.conditions(_unref(value)) : true
              )
                  ? (_openBlock(), _createBlock(ButtonField, {
                      key: 0,
                      "button-field": startButton
                    }, null, 8, ["button-field"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            _createElementVNode("div", {
              class: _normalizeClass(`${
              props.field.type == 'checkbox' ? 'col-auto my-auto' : 'col'
            }`)
            }, [
              _createElementVNode("div", {
                id: `${props.field.inputName}InputDropdown`
              }, [
                _createElementVNode("div", {
                  class: "d-flex justify-content-center align-items-center w-100 input-group",
                  onMouseover: showDropdown,
                  onMouseleave: hideDropdown
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.startAppendButtons, (startButton, startButtonIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `startAppendButton-${props.field.inputName}-${startButtonIndex}`
                    }, [
                      (
                      startButton.conditions
                        ? startButton.conditions(_unref(value))
                        : true
                    )
                        ? (_openBlock(), _createBlock(ButtonField, {
                            key: 0,
                            "button-field": startButton
                          }, null, 8, ["button-field"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128)),
                  (
                    ['file'].includes(props.field.type) &&
                    props.field.fileOptions
                  )
                    ? (_openBlock(), _createBlock(FilesMaster, {
                        key: 0,
                        modelValue: _unref(value),
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                        "file-options": props.field.fileOptions,
                        initialFiles: _unref(value),
                        class: "w-100"
                      }, null, 8, ["modelValue", "file-options", "initialFiles"]))
                    : (
                    ['select', 'multiple-select'].includes(props.field.type) &&
                    props.field.selectOptions
                  )
                      ? (_openBlock(), _createBlock(SelectMaster, {
                          key: 1,
                          field: props.field,
                          initialValue: _unref(value),
                          isValidClass: isValidClassComputed.value,
                          modelValue: _unref(value),
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_isRef(value) ? (value).value = $event : null))
                        }, null, 8, ["field", "initialValue", "isValidClass", "modelValue"]))
                      : (props.field.type == 'textarea')
                        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
                            key: 2,
                            name: props.field.inputName,
                            class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                            id: props.field.inputName,
                            placeholder: getPlaceholder(),
                            "aria-describedby": `${props.field.inputName}GroupPrepend ${props.field.inputName}Feedback`,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                            disabled: __props.field.disabled,
                            onKeyup: _cache[3] || (_cache[3] = _withKeys(() => emit('enter'), ["enter"]))
                          }, null, 42, _hoisted_10)), [
                            [_vModelText, _unref(value)]
                          ])
                        : _withDirectives((_openBlock(), _createElementBlock("input", {
                            key: 3,
                            type: inputType.value,
                            name: props.field.inputName,
                            class: _normalizeClass(`${getInputClass.value} ${isValidClassComputed.value} m-0`),
                            id: props.field.inputName,
                            placeholder: getPlaceholder(),
                            "aria-describedby": `${props.field.inputName}GroupPrepend ${props.field.inputName}Feedback`,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (_isRef(value) ? (value).value = $event : null)),
                            disabled: __props.field.disabled,
                            step: `${props.field.type === 'decimal' ? '.01' : ''}`,
                            onKeyup: _cache[5] || (_cache[5] = _withKeys(() => emit('enter'), ["enter"])),
                            onBlur: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (props?.field?.onBlur && props?.field?.onBlur(...args)))
                          }, null, 42, _hoisted_11)), [
                            [_vModelDynamic, _unref(value)]
                          ]),
                  (__props.field.type === 'password')
                    ? (_openBlock(), _createBlock(ButtonField, {
                        key: 4,
                        "button-field": eyeButtonTemplate.value
                      }, null, 8, ["button-field"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endAppendButtons, (endButton, endButtonIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `endAppendButton-${props.field.inputName}-${endButtonIndex}`
                    }, [
                      (
                      endButton.conditions ? endButton.conditions(_unref(value)) : true
                    )
                        ? (_openBlock(), _createBlock(ButtonField, {
                            key: 0,
                            "button-field": endButton
                          }, null, 8, ["button-field"]))
                        : _createCommentVNode("", true)
                    ]))
                  }), 128))
                ], 32)
              ], 8, _hoisted_9),
              _createElementVNode("ul", {
                ref: props.field.inputName,
                class: "dropdown-menu",
                id: `${props.field.inputName}DropdownMenu`
              }, [
                (__props.errorBag && __props.errorBag[__props.field.path])
                  ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.errorBag[__props.field.path], (error, index) => {
                        return (_openBlock(), _createElementBlock("li", {
                          key: `${error}+${index}+${props.field.label}`,
                          class: _normalizeClass(`mx-3 ${
                    error.valid ? 'text-success' : 'text-danger'
                  } text-nowrap`)
                        }, [
                          _createElementVNode("span", {
                            innerHTML: error.error
                          }, null, 8, _hoisted_14)
                        ], 2))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 8, _hoisted_12)
            ], 2),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(formButtons.value.endButtons, (endButton, endButtonIndex) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "col-auto",
                key: `endButton${props.field.inputName}-${endButtonIndex}`
              }, [
                (endButton.conditions ? endButton.conditions(_unref(value)) : true)
                  ? (_openBlock(), _createBlock(ButtonField, {
                      key: 0,
                      "button-field": endButton
                    }, null, 8, ["button-field"]))
                  : _createCommentVNode("", true)
              ]))
            }), 128)),
            (
              mergedMetadata.value?.labelPosition === 'right' && props.field.label
            )
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                  _createElementVNode("span", {
                    class: "fw-medium fs-6 pl-1",
                    id: `${props.field.inputName}GroupPrepend`
                  }, [
                    _createVNode(ProcessText, {
                      value: props.field.label
                    }, null, 8, ["value"])
                  ], 8, _hoisted_16)
                ]))
              : _createCommentVNode("", true)
          ])
        ])
      ])
    ]))
  ], 64))
}
}

})