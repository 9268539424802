import { ProductFamilyInterface } from "../interfaces/ProductFamily.interface";

export class ProductFamilyDto implements ProductFamilyInterface {
  id = 0;
  name = "";
  description = "";
  createdAt = null;
  updatedAt = null;
  metadata = null;
  customFields = null;
}

export class ProductFamily extends ProductFamilyDto {
  constructor(productFamilyDto: ProductFamilyDto) {
    super();
    Object.assign(this, productFamilyDto);
  }
}
