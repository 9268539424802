<template>
  <router-link
    v-if="!props.option.submenu"
    :to="{ name: (props.option.action as RouterActionMenu).route }"
    :class="[...optionStyles]"
    @click="() => emit('navigating')"
  >
    {{ $t(props.option.i18nName) }}
  </router-link>
  <a
    v-else
    href="#"
    role="button"
    @click="OpenOrCloseDropdown"
    :class="[...optionStyles]"
  >
    {{ $t(props.option.i18nName) }}
  </a>
  <div class="nav-item dropdown" ref="dropdown">
    <ul class="dropdown-menu">
      <li
        v-for="(element, index) of props.option.submenu"
        :key="element.i18nName + index"
      >
        <div v-if="element.submenu">
          <SubmenuOption
            :option="element"
            :parent-is-closed="isOpenDropdown"
            ref="submenus"
            @navigating="() => closeDropdown(true)"
            @openDropdown="() => closeAllDropdowns(index)"
          ></SubmenuOption>
        </div>
        <a
          v-else
          @click="navigateTo(element.action)"
          :class="[...optionStyles]"
          href="#"
        >
          {{ $t(element.i18nName) }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import {
  CustomActionMenu,
  RouterActionMenu,
} from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElementSubmenu } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import {
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import { useRouter } from "vue-router";

const props = defineProps({
  option: {
    type: Object as PropType<SidebarElementSubmenu>,
    required: true,
  },
  parentIsClosed: {
    type: Boolean,
    required: true,
  },
});
const router = useRouter();
const dropdown = ref();
const parentIsClosedRef = toRef(props, "parentIsClosed");
const isOpenDropdown = ref(false);
const submenus = ref([]);

const emit = defineEmits(["navigating", "openDropdown"]);

const closeAllDropdowns = (index: number) => {
  submenus.value.forEach((submenu, index2) => {
    if (submenu.isOpenDropdown && index2 !== index) {
      submenu.closeDropdown();
    }
  });
};

function OpenOrCloseDropdown() {
  if (isOpenDropdown.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

function openDropdown() {
  Dropdown.getOrCreateInstance(dropdown.value)?.show();
  isOpenDropdown.value = true;
  emit("openDropdown");
}

function closeDropdown(emitClose = false) {
  Dropdown.getOrCreateInstance(dropdown.value)?.hide();
  if (emitClose) {
    emit("navigating");
  }
  isOpenDropdown.value = false;
}

function navigateTo(action: RouterActionMenu | CustomActionMenu): void {
  if ("route" in action) {
    router.push({ name: action.route });
  }
  closeDropdown(true);
}

onMounted(() => {
  if (props.option.submenu) {
    Dropdown.getOrCreateInstance(dropdown.value, {
      display: "dynamic",
      popperConfig: function (defaultBsPopperConfig) {
        defaultBsPopperConfig.placement = "right";
        defaultBsPopperConfig.modifiers = [
          {
            name: "preventOverflow",
            options: {
              boundary: "clippingParents",
            },
          },
        ];
        return defaultBsPopperConfig;
      },
    });
  }
});

watch(parentIsClosedRef, () => {
  if (!parentIsClosedRef.value) {
    closeDropdown();
  }
});
const optionStyles = ["d-flex", "justify-content-start", "text-nowrap"];

defineExpose({ isOpenDropdown, closeDropdown });
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  color: inherit;
  color: #8e9db8;
  font-size: 14px;
  font-weight: 400;
  margin: auto 10px;
  border-radius: 4px;
  padding: 2px 10px;
}

a:hover {
  background-color: #ebedf2;
  color: #4375ef;
}
</style>
