import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  GetWorkplacesFlow,
  UpdateWorkplacesFlow,
} from "@/store/workplaces-flow/services/WorkplacesFlow.service";
import { ref, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useWorkplacesFlowsServices(productId: number) {
  const { t } = useI18n();
  const alias = "workplacesFlows";

  const workplaceFlow: Ref<WorkplacesFlow> = ref();

  const getWorkplacesFlow = async (): Promise<void> => {
    await GetWorkplacesFlow(productId)
      .then((workplaceFlowTemp: WorkplacesFlow) => {
        workplaceFlow.value = workplaceFlowTemp;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateWorkplacesFlow = async (
    productId: number,
    payload: WorkplacesFlow,
    callBackUpdate: (workplacesFlow: WorkplacesFlow) => void
  ): Promise<void> => {
    await UpdateWorkplacesFlow(productId, payload)
      .then((workplacesFlow: WorkplacesFlow) => {
        callBackUpdate(workplacesFlow);
        useToast().successToast(t("workplacesFlows.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    workplaceFlow,
    getWorkplacesFlow,
    updateWorkplacesFlow,
  };
}
