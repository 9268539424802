import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, unref as _unref, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row mt-3" }
const _hoisted_5 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_6 = { class: "m-0 fs-5" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col-auto p-0" }
const _hoisted_9 = { class: "col-auto pl-0" }
const _hoisted_10 = { class: "col-12 text-end" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-center" }
const _hoisted_12 = { class: "col-12" }
const _hoisted_13 = { class: "text-end" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useOrdersServices } from "@/composables/useOrdersServices";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Order } from "@/store/orders/models/Order.model";
import { Product } from "@/store/products/models/Product.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { merge } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import OrderDetail from "./OrderDetail.vue";
import { CreateOrUpdateDetailTemplate } from "./templates/forms/CreateOrUpdateDetail.template";
import { CreateOrUpdateOrderTemplate } from "./templates/forms/CreateOrUpdateOrder.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateOrder',
  props: {
  initialValues: {
    type: Object as PropType<Order>,
    required: false,
  },
  isCreating: {
    type: Boolean,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createOrder, updateOrder } = useOrdersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const { t } = useI18n();

const props = __props;

let currentDetailIndex = -1;

const initialValuesRef: Ref<Order> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const detailForm = ref();
const detailFormTemplateRef = ref();
const details: Ref<
  Array<{
    detail: {
      product: { id: number; label?: string; item: Product; items: Product[] };
      discount: number;
      quantity: number;
    };
  }>
> = ref([]);
const addButtonTemplate = ref(
  addButton(async () => {
    currentDetailIndex = details.value.length;
    await handleAddOrEditDetail(currentDetailIndex);
  })
);
const emit = __emit;

const isCreatingRef: Ref<boolean> = toRef(props, "isCreating");

const total = computed(() => {
  return details.value
    .map(({ detail }) => {
      return (
        detail.quantity *
        detail.product.item.price *
        ((100 - detail.discount) / 100)
      );
    })
    .reduce((accumulator, current) => {
      accumulator = accumulator + current;
      return accumulator;
    }, 0);
});

const subtotal = computed(() => {
  const { values } = detailForm.value ?? {};
  const { detail } = values ?? {};
  if (detail?.quantity && detail?.product) {
    return (
      detail.quantity *
      detail.product.item.price *
      ((100 - (detail.discount ?? 0)) / 100)
    );
  }
  return 0;
});

const currentOrder: Ref<Partial<Order> | null> = computed(() => {
  if (initialValuesRef.value) {
    const order = formatValues(initialValuesRef.value);
    return order ?? null;
  }
  return null;
});

async function handleSubmitDetail() {
  const { values } = detailForm.value;
  details.value[currentDetailIndex] = merge(
    {},
    details.value[currentDetailIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditDetail(detailIndex: number) {
  const isCreating = details.value.length <= detailIndex;
  detailForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("orderDetail.form.createTitle")
    : t("orderDetail.form.editTitle");
  detailFormTemplateRef.value = CreateOrUpdateDetailTemplate(
    handleSubmitDetail,
    isCreating
  );
  if (!isCreating) {
    await nextTick();
    detailForm.value.setValues(details.value[detailIndex]);
  }
  modal.value.openModal();
  currentDetailIndex = detailIndex;
}

function callBackCreate(order: Order) {
  emit("handleSubmit", { order, isCreating: true });
}

function callBackEdit(order: Order) {
  emit("handleSubmit", {
    order,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { order } = merge({}, values, details.value);
  const detail = details.value.map((detail) => ({
    ...detail.detail,
    product: { id: detail.detail.product.id },
  }));
  if (isCreatingRef.value) {
    await createOrder(
      {
        ...order,
        status: { id: order.status.id },
        customer: { id: order.customer.id },
        details: detail,
      },
      callBackCreate
    );
  } else {
    await updateOrder({ ...order, details: detail }, callBackEdit);
  }
}

function formatValues(values: Order) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    orderDate: DateHelper.of().format(values.orderDate, "yyyy-MM-dd"),
    customer: values.customer,
    status: values.status,
    metadata: values.metadata,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
  detailForm.value?.resetForm();
  details.value = [];
  modal.value.closeModal();
}

onMounted(async () => {
  await Promise.all([getAllCatalogs()]);
  watch(
    [currentOrder, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateOrderTemplate(
          customFields.value,
          handleSubmit,
          !currentOrder.value,
          catalogs.value
        );
        if (currentOrder.value) {
          await nextTick();
          form.value?.setValues({
            order: currentOrder.value,
          });
          details.value =
            initialValuesRef.value?.details?.map((detail) => {
              return {
                detail: {
                  ...detail.data,
                  product: detail?.product
                    ? {
                        id: detail?.product.id,
                        label: `${translateFromKey(
                          detail?.product,
                          "name"
                        )} ($${detail?.product.price})`,
                        item: detail?.product,
                        items: [detail?.product],
                      }
                    : {},
                },
              };
            }) ?? [];
        }
      }
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formTemplateRef.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "form",
              ref: form,
              form: formTemplateRef.value,
              key: JSON.stringify(props.initialValues),
              "form-name": "orderForm"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
                    _createElementVNode("hr")
                  ], -1)),
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("b", _hoisted_6, _toDisplayString(_unref(t)("orderDetail.title")), 1)
                  ]),
                  _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
                    _createElementVNode("hr")
                  ], -1))
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(details.value, (detail, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "row mt-3",
                    key: `detail${index}`
                  }, [
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(OrderDetail, {
                        detail: detail.detail
                      }, null, 8, ["detail"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createVNode(ButtonField, {
                        "button-field": 
                  _unref(editButton)(() => {
                    handleAddOrEditDetail(index);
                  })
                
                      }, null, 8, ["button-field"])
                    ]),
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(ButtonField, {
                        "button-field": 
                  _unref(deleteButton)(() => {
                    details.value.splice(index, 1);
                  })
                
                      }, null, 8, ["button-field"])
                    ])
                  ]))
                }), 128)),
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("h4", null, "Total: " + _toDisplayString(total.value), 1)
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createVNode(ButtonField, { buttonField: addButtonTemplate.value }, null, 8, ["buttonField"])
                ])
              ]),
              _: 1
            }, 8, ["form"]))
          ]),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(GeneralModal, {
              ref_key: "modal",
              ref: modal,
              modalId: "translateModal",
              modalName: modalTitle.value,
              "data-bs-backdrop": "false",
              class: "p-4"
            }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(GeneralForm, {
                  ref_key: "detailForm",
                  ref: detailForm,
                  form: detailFormTemplateRef.value,
                  "form-name": "translateForm",
                  key: modalTitle.value
                }, {
                  "section-0-field-2": _withCtx(() => [
                    _cache[2] || (_cache[2] = _createElementVNode("div", { class: "mt-2" }, null, -1)),
                    _createElementVNode("h4", _hoisted_13, "Subtotal: " + _toDisplayString(subtotal.value), 1)
                  ]),
                  _: 1
                }, 8, ["form"]))
              ]),
              _: 1
            }, 8, ["modalName"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})