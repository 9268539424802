import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useProductsInventoryServices } from "@/composables/useProductsInventoryServices";
import { useRawMaterialsInventoryServices } from "@/composables/useRawMaterialsInventoryServices";
import { DateHelper } from "@/shared/dates/helpers/Date.helper";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { InventoryTransaction } from "@/store/inventories/interfaces/TransactionInventory.interface";
import { ProductsInventory } from "@/store/inventories/models/ProductsInventory.model";
import { RawMaterialsInventory } from "@/store/inventories/models/RawMaterialsInventory.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { get, merge } from "lodash";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import SectionName from "../globals/forms/SectionName.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { InventoryTransactionFormTemplate } from "./templates/forms/InventoryTransactionForm.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'InventoryTransactionForm',
  props: {
  initialValues: {
    type: Object as PropType<ProductsInventory>,
    required: false,
  },
  transactionType: {
    type: Object as PropType<"insert" | "dispatch" | "modify">,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const {
  dispatchProductInventory,
  insertProductInventory,
  modifyProductInventory,
  checkProductInventory,
} = useProductsInventoryServices();
const {
  dispatchRawMaterialInventory,
  insertRawMaterialInventory,
  modifyRawMaterialInventory,
  checkRawMaterialInventory,
} = useRawMaterialsInventoryServices();
const { t } = useI18n();
const route = useRoute();

const props = __props;
const moduleName: string = route.meta.moduleName as string;

const isProductModule = computed(() => {
  return moduleName !== "raw_materials_inventory";
});
const customFieldLabel: LabelType = {
  value: "customFields.title",
  needsTranslate: true,
};

const initialValuesRef: Ref<ProductsInventory> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const modal = ref();
const modalTitle: Ref<string> = ref();
const translateForm = ref();
const formTranslateRef = ref();
const translates = ref({});
const transactionTypeRef: Ref<"insert" | "dispatch" | "modify"> = toRef(
  props,
  "transactionType"
);

const emit = __emit;

const currentInventory = computed(() => {
  if (initialValuesRef.value) {
    const inventory = formatValues(initialValuesRef.value);
    return inventory ?? null;
  }
  return null;
});

function callBackCreateOrUpdate(
  inventories: ProductsInventory[] | RawMaterialsInventory[]
) {
  emit("handleSubmit", { inventories });
}

async function handleSubmit(
  typeTransaction?: "dispatch" | "insert" | "modify"
) {
  const { values } = form.value;
  const { inventory } = merge({}, values, translates.value);
  const inventoryFormatted: InventoryTransaction = {
    store: {
      id: inventory.store.id,
    },
  };
  const elements = [];
  for (const key of Object.keys(inventory.elements)) {
    elements.push({
      id: +get(inventory, `elements.${key}.id.id`),
      quantity: +get(inventory, `elements.${key}.quantity`),
      lotCode: get(inventory, `elements.${key}.lotCode`),
      expirationDate: get(inventory, `elements.${key}.expirationDate`),
      customFields: get(inventory, `elements.${key}.customFields`),
    });
  }
  if (["dispatch", "insert"].includes(typeTransaction)) {
    inventoryFormatted.elements = elements;
    if (isProductModule.value) {
      if (typeTransaction === "dispatch") {
        dispatchProductInventory(inventoryFormatted, callBackCreateOrUpdate);
      } else {
        insertProductInventory(inventoryFormatted, callBackCreateOrUpdate);
      }
    } else {
      if (typeTransaction === "dispatch") {
        dispatchRawMaterialInventory(
          inventoryFormatted,
          callBackCreateOrUpdate
        );
      } else {
        insertRawMaterialInventory(inventoryFormatted, callBackCreateOrUpdate);
      }
    }
  } else {
    const [element] = elements;
    inventoryFormatted.element = element;
    inventoryFormatted.id = inventory.id;
    if (isProductModule.value) {
      modifyProductInventory(inventoryFormatted, callBackCreateOrUpdate);
    } else {
      modifyRawMaterialInventory(inventoryFormatted, callBackCreateOrUpdate);
    }
  }
}

function formatValues(values: ProductsInventory | RawMaterialsInventory) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  const product = {
    id: get(values, "product.id", null),
    label: get(values, "product.name", null),
    item: get(values, "product", null),
    items: [get(values, "product", null)],
  };
  const rawMaterial = {
    id: get(values, "rawMaterial.id", null),
    label: get(values, "rawMaterial.description", null),
    item: get(values, "rawMaterial", null),
    items: [get(values, "rawMaterial", null)],
  };
  return {
    id: values.id,
    store: values.store,
    elements: {
      element0: {
        id: isProductModule.value ? product : rawMaterial,
        ...(transactionTypeRef.value === "dispatch"
          ? {}
          : { quantity: values.quantity }),
        expirationDate: values.expirationDate.split("T")[0],
        lotCode: values.lotCode,
        metadata: values.metadata,
        customFields: customFieldsFiltered,
      },
    },
  };
}

function resetForms() {
  form.value?.resetForm();
  translateForm.value?.resetForm();
  translates.value = {};
  modal.value.closeModal();
}

let existLotCode = ref(false);

async function onBlurLotCodeInput(
  typeTransaction: "insert" | "dispatch" | "modify"
) {
  if (["insert"].includes(typeTransaction)) {
    const values = get(form, "value.values");
    const storeId = get(values, "inventory.store.id");
    const productId = get(values, "inventory.elements.element0.id.id");
    const lotCode = get(values, "inventory.elements.element0.lotCode");
    if (storeId && productId && lotCode) {
      const payload = {
        store: { id: storeId },
        element: { id: productId, lotCode },
      };
      const result = await (async () => {
        return isProductModule.value
          ? checkProductInventory(payload)
          : checkRawMaterialInventory(payload);
      })();
      const { exists, expirationDate, metadata, customFields } = result;
      existLotCode.value = exists;
      if (exists) {
        const newValues = merge({}, values, {
          inventory: {
            elements: {
              element0: {
                expirationDate: DateHelper.of().format(
                  expirationDate,
                  "yyyy-MM-dd"
                ),
                metadata,
                customFields,
              },
            },
          },
        });
        form.value?.setValues(newValues);
      } else {
        const values = get(form, "value.values");
        const store = get(values, "inventory.store");
        const id = get(values, "inventory.elements.element0.id");
        const lotCode = get(values, "inventory.elements.element0.lotCode");
        setValuesNotRecursive({
          inventory: {
            store,
            elements: { element0: { id, lotCode } },
          },
        });
      }
    }
  }
}

const formValues = computed(() => {
  return form.value?.values;
});

function disableFields(fieldsName?: string[]) {
  get(formTemplateRef, "value.sections.0.fields.0.fields", []).map((field) => {
    if (!fieldsName) {
      field.disabled = true;
    } else if (fieldsName.includes(field.inputName)) {
      field.disabled = true;
    }
  });
}

function enableFields(fieldsName?: string[]) {
  get(formTemplateRef, "value.sections.0.fields.0.fields", []).map((field) => {
    if (!fieldsName) {
      field.disabled = false;
    } else if (fieldsName.includes(field.inputName)) {
      field.disabled = false;
    }
  });
}

function setValuesNotRecursive(values) {
  const oldValues = get(form, "value.values");
  if (JSON.stringify(values) !== JSON.stringify(oldValues)) {
    form?.value?.setValues(values);
  }
}

watch(
  [formValues, existLotCode],
  () => {
    if (["insert"].includes(transactionTypeRef.value)) {
      const values = get(form, "value.values");
      const store = get(values, "inventory.store");
      const id = get(values, "inventory.elements.element0.id");
      const lotCode = get(values, "inventory.elements.element0.lotCode");
      disableFields();
      enableFields(["inventoryStore"]);
      if (get(formValues, "value.inventory.store", false)) {
        enableFields(["productInventory", "rawMaterialInventory"]);
        if (get(formValues, "value.inventory.elements.element0.id", false)) {
          enableFields(["inventoryLotCode"]);
          if (
            get(formValues, "value.inventory.elements.element0.lotCode", false)
          ) {
            enableFields(["inventoryQuantity"]);
            if (
              !existLotCode.value &&
              get(
                formValues,
                "value.inventory.elements.element0.quantity",
                false
              )
            ) {
              enableFields();
            }
            if (
              !existLotCode.value &&
              !get(
                formValues,
                "value.inventory.elements.element0.quantity",
                false
              )
            ) {
              setValuesNotRecursive({
                inventory: {
                  store,
                  elements: { element0: { id, lotCode } },
                },
              });
            }
          } else {
            setValuesNotRecursive({
              inventory: {
                store,
                elements: { element0: { id } },
              },
            });
          }
        } else {
          setValuesNotRecursive({
            inventory: {
              store,
            },
          });
        }
      } else {
        setValuesNotRecursive({
          inventory: {},
        });
      }
    }
  },
  { deep: true }
);

watch(
  [currentInventory, transactionTypeRef],
  async () => {
    formTemplateRef.value = InventoryTransactionFormTemplate(
      customFields.value,
      handleSubmit,
      transactionTypeRef.value,
      isProductModule.value,
      onBlurLotCodeInput
    );
    if (["insert"].includes(transactionTypeRef.value)) {
      enableFields(["inventoryStore"]);
    } else if (["dispatch"].includes(transactionTypeRef.value)) {
      enableFields(["inventoryQuantity"]);
    } else if (["modify"].includes(transactionTypeRef.value)) {
      enableFields();
      disableFields([
        "inventoryStore",
        "productInventory",
        "rawMaterialInventory",
        "inventoryLotCode",
      ]);
    }
    if (currentInventory.value) {
      await nextTick();
      const inventory = {
        ...currentInventory.value,
      };
      form.value?.setValues({
        inventory,
      });
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues) + transactionTypeRef.value,
          "form-name": "inventoryForm"
        }, {
          "section-1-field-0-subField-3": _withCtx(() => [
            _createVNode(SectionName, { name: customFieldLabel })
          ]),
          _: 1
        }, 8, ["form"]))
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(GeneralModal, {
          ref_key: "modal",
          ref: modal,
          modalId: "translateModal",
          modalName: modalTitle.value,
          "data-bs-backdrop": "false",
          class: "p-4"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "translateForm",
              ref: translateForm,
              form: formTranslateRef.value,
              "form-name": "translateForm",
              key: modalTitle.value
            }, null, 8, ["form"]))
          ]),
          _: 1
        }, 8, ["modalName"])
      ])
    ])
  ]))
}
}

})