<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("technicalSheets.entity.rawMaterial") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-end">
      <button
        class="btn ml-1 btn-gear"
        type="button"
        @click="handleAddRawMaterial"
      >
        {{ $t("technicalSheets.form.rawMaterial.add") }}
      </button>
    </div>
    <div class="col-12">
      <BTable :items="rawMaterials" :fields="rawMaterialsFields" show-empty>
        <template #cell(actions)="row">
          <DropdownMenu
            :menuData="getOptions(row.index)"
            :name="`menu-${row.index}-${row.key}`"
          />
        </template>
        <template #empty>
          <h5 class="text-center">
            {{ $t("technicalSheets.tables.rawMaterials.isEmpty") }}
          </h5>
        </template>
      </BTable>
    </div>
  </div>
  <GeneralModal
    ref="modal"
    modalId="translateModal"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="rawMaterialForm"
      :form="rawMaterialFormTemplateRef"
      form-name="translateForm"
      :key="modalTitle"
    ></GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";
import { BTable } from "bootstrap-vue-3";
import { type TableFieldRaw } from "bootstrap-vue-next";
import { merge } from "lodash";
import {
  computed,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
} from "vue";
import { useI18n } from "vue-i18n";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import DropdownMenu from "../globals/UiTools/DropdownMenu.vue";
import { CreateOrUpdateRawMaterialQuantityTemplate } from "./templates/forms/CreateOrUpdateRawMaterialQuantity.template";

const { t } = useI18n();

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string; items: RawMaterial[] };
  quantity: number;
};

const props = defineProps({
  rawMaterials: {
    type: Object as PropType<RawMaterialQuantity[]>,
    required: false,
  },
});
const rawMaterials: Ref<RawMaterialQuantity[]> = toRef(props, "rawMaterials");
let currentRawMaterialIndex = -1;
const modal = ref();
const modalTitle = ref();
const rawMaterialForm = ref();
const rawMaterialFormTemplateRef = ref();

async function handleAddRawMaterial() {
  currentRawMaterialIndex = rawMaterials.value.length;
  await handleAddOrEditRawMaterial(currentRawMaterialIndex);
}

const excludeOptions = computed(() => {
  return rawMaterials.value.map(({ rawMaterial }) => {
    return rawMaterial;
  });
});

async function handleSubmitRawMaterial() {
  const { values } = rawMaterialForm.value;
  rawMaterials.value[currentRawMaterialIndex] = merge(
    {},
    rawMaterials.value[currentRawMaterialIndex] ?? {},
    values
  );
  modal.value.closeModal();
}

async function handleAddOrEditRawMaterial(index: number) {
  const isCreating = rawMaterials.value.length <= index;
  rawMaterialForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("technicalSheets.form.rawMaterial.add")
    : t("technicalSheets.form.rawMaterial.update");
  rawMaterialFormTemplateRef.value = CreateOrUpdateRawMaterialQuantityTemplate(
    handleSubmitRawMaterial,
    isCreating
  );
  rawMaterialFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  if (!isCreating) {
    await nextTick();
    rawMaterialForm.value.setValues(rawMaterials.value[index]);
  }
  modal.value.openModal();
  currentRawMaterialIndex = index;
}

function resetForms() {
  rawMaterials.value = [];
  rawMaterialForm.value?.resetForm();
  modal.value.closeModal();
}

function getOptions(index: number): Menu {
  const options = [
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.edit",
        needsTranslate: true,
      },
      id: 1,
      action: () => handleAddOrEditRawMaterial(index),
    },
    {
      label: {
        value: "technicalSheets.tables.rawMaterials.actionList.delete",
        needsTranslate: true,
      },
      id: 2,
      action: () => rawMaterials.value.splice(index, 1),
    },
  ];
  return {
    options,
  };
}

const rawMaterialsFields: Exclude<TableFieldRaw<any>, string>[] = [
  {
    key: "description",
    sortable: false,
    label: t("technicalSheets.entity.description"),
    formatter: (value, key, item) => {
      return item.rawMaterial.label;
    },
  },
  {
    key: "quantity",
    sortable: false,
    label: t("technicalSheets.entity.quantity"),
    formatter: (value, key, item) => {
      return item.quantity;
    },
  },

  {
    key: "thickness",
    sortable: false,
    label: t("technicalSheets.entity.thickness"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.thickness;
    },
  },
  {
    key: "width",
    sortable: false,
    label: t("technicalSheets.entity.width"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.width;
    },
  },
  {
    key: "length",
    sortable: false,
    label: t("technicalSheets.entity.length"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.length;
    },
  },
  {
    key: "unitVolume",
    sortable: false,
    label: t("technicalSheets.entity.unitVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.volume;
    },
  },
  {
    key: "totalVolume",
    sortable: false,
    label: t("technicalSheets.entity.totalVolume"),
    formatter: (value, key, item) => {
      return item.rawMaterial.item.volume * item.quantity;
    },
  },
  {
    key: "actions",
    sortable: false,
    label: t("users.table.actions"),
    formatter: (value, key, item) => {
      return "";
    },
  },
];

defineExpose({ resetForms });
</script>

<style lang="scss" scoped>
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
.btn-gear {
  color: white;
  background-color: #7451c2;
  border-color: #7451c2;
}
</style>
