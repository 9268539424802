import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { CatalogClassInterface } from "@/store/catalogs/interfaces/CatalogClass.interface";
import {
  getCatalogClasses,
  getCatalogs,
} from "@/store/catalogs/services/Catalogs.service";
import { ref, Ref } from "vue";

export function useCatalogsServices() {
  const catalogs: Ref<CatalogClassInterface[]> = ref([]);
  const classesCatalogs: Ref<Partial<CatalogClassInterface>[]> = ref([]);
  const alias = "catalogs";

  async function getAllCatalogs(): Promise<void> {
    await getCatalogs()
      .then((allCatalogs) => {
        catalogs.value = allCatalogs;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  }

  async function getClassesCatalogs(): Promise<void> {
    await getCatalogClasses()
      .then((allClassesCatalogs) => {
        classesCatalogs.value = allClassesCatalogs;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
        return;
      });
  }

  return {
    catalogs,
    classesCatalogs,
    getAllCatalogs,
    getClassesCatalogs,
  };
}
