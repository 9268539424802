import { DivisionInterface } from "../interfaces/Division.interface";

export class DivisionDto implements DivisionInterface {
  id = 0;
  name = "";
  location = "";
  customFields = null;
  updatedAt = null;
  createdAt = null;
}

export class Division extends DivisionDto {
  constructor(divisionDto: DivisionDto) {
    super();
    Object.assign(this, divisionDto);
  }
}
