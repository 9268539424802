import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const LoginUserTemplate = (action, validations): Form => {
  return {
    sections: [
      {
        fields: [
          {
            path: "email",
            inputName: "email",
            label: {
              value: "auth.fields.email",
              needsTranslate: true,
            },
            type: "email",
            methodsYup: () => {
              const { emailValidation } = validations;
              return emailValidation;
            },
          },
          {
            path: "password",
            inputName: "password",
            label: {
              value: "auth.fields.password",
              needsTranslate: true,
            },
            type: "password",
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "#7451c2",
      customClass: "w-100 text-center",
      text: {
        value: "auth.carouselTabs.signUp.submit",
        needsTranslate: true,
      },
      action,
    },
  };
};
