<template>
  <div>
    <div
      class="offcanvas offcanvas-end"
      :style="`width: ${width}em !important; transform: translateX(-${offset}em);`"
      tabindex="-1"
      :id="canvasId"
      :aria-labelledby="`${canvasId}Label`"
      :data-bs-backdrop="true"
      ref="offCanvasRef"
    >
      <div class="offcanvas-header">
        <div class="container-fluid">
          <div class="row justify-content-between">
            <div class="col-auto">
              <h5 class="offcanvas-title" :id="`${canvasId}Label`">
                {{ canvasName }}
              </h5>
            </div>
            <slot name="headerComplement"></slot>
            <div class="col-auto d-flex align-items-center pl-0">
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                @click="closeOffcanvas"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <div class="offcanvas-body">
        <slot name="content"></slot>
        <div
          :class="`${
            isOpen && childIsOpen ? 'offcanvas-backdrop fade show' : ''
          }`"
        ></div>
      </div>
    </div>
    <div class="offcanvas-child">
      <slot name="offcanvasChild" :setRef="setRef"></slot>
    </div>
  </div>
  <div
    :class="`${isOpen && childIsOpen ? 'offcanvas-backdrop fade show' : ''}`"
  ></div>
</template>

<script setup lang="ts">
import { Offcanvas } from "bootstrap";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  onMounted,
  Ref,
  ref,
} from "vue";

const props = defineProps({
  canvasId: { type: String, required: true },
  canvasName: { type: String, required: true },
  childProps: { type: Object, required: false },
  initialWidth: { type: Number, required: false },
});

const emit = defineEmits(["closed"]);

const canvas: Ref<Element> = ref(undefined);
const offCanvasRef = ref();
const isOpen = ref(false);
const childrenRef = ref();

const width = computed(() => props.initialWidth ?? 30);

function setRef(el) {
  childrenRef.value = el;
}

function closeOffcanvas() {
  emit("closed");
  isOpen.value = false;
}

function openOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).show();
    isOpen.value = true;
  }
}

function closeOffCanvas() {
  if (offCanvasRef.value) {
    Offcanvas.getOrCreateInstance(offCanvasRef.value).hide();
    isOpen.value = false;
  }
}

const offset = computed(() => {
  if (childIsOpen.value) {
    return childOffset.value + childWidth.value - width.value / 2;
  }
  return 0;
});

const childWidth = computed(() => {
  return childrenRef.value?.width ?? 0;
});

const childOffset = computed(() => {
  return childrenRef.value?.offset ?? 0;
});

const childIsOpen = computed(() => {
  return childrenRef.value?.isOpen ?? false;
});

onMounted(() => {
  canvas.value = document.getElementById(props.canvasId);
  canvas.value.addEventListener("hidden.bs.offcanvas", (event) => {
    emit("closed");
  });
});

defineExpose({ isOpen, width, openOffCanvas, closeOffCanvas });
</script>

<style lang="scss" scoped>
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.8);
}
</style>
