import { CheckInventoryInterface } from "@/store/inventories/interfaces/CheckInventory.interface";
import { CheckResultInventory } from "@/store/inventories/interfaces/CheckResultInventory.interface";
import { InventoryTransaction } from "@/store/inventories/interfaces/TransactionInventory.interface";
import { ProductsInventory } from "@/store/inventories/models/ProductsInventory.model";
import {
  CheckProductInventory,
  DispatchProductInventory,
  InsertProductInventory,
  ModifyProductInventory,
} from "@/store/inventories/services/ProductsInventory.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useProductsInventoryServices() {
  const { t } = useI18n();

  const checkProductInventory = async (
    payload: CheckInventoryInterface
  ): Promise<CheckResultInventory> => {
    return await CheckProductInventory(payload);
  };

  const dispatchProductInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: ProductsInventory[]) => void
  ): Promise<void> => {
    DispatchProductInventory(payload)
      .then(async (inventories: ProductsInventory[]) => {
        callBack(inventories);
        useToast().successToast(t("inventories.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const insertProductInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: ProductsInventory[]) => void
  ): Promise<void> => {
    InsertProductInventory(payload)
      .then(async (inventories: ProductsInventory[]) => {
        callBack(inventories);
        useToast().successToast(t("inventories.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const modifyProductInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: ProductsInventory[]) => void
  ): Promise<void> => {
    ModifyProductInventory(payload)
      .then(async (inventory: ProductsInventory) => {
        callBack([inventory]);
        useToast().successToast(t("inventories.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    dispatchProductInventory,
    insertProductInventory,
    modifyProductInventory,
    checkProductInventory,
  };
}
