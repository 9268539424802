import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";

export const createPrintButton = (action): ButtonTemplate => ({
  backgroundColor: "#7451c2",
  customClass: "btn ml-1 my-2",
  graphic: {
    icon: {
      icon: "bi bi-file-earmark-pdf fs-4 mt-2 d-flex justify-content-center",
      color: "white",
      backgroundColor: "#7451c2",
    },
  },
  text: { value: "global.buttons.print", needsTranslate: true },
  action,
  metadata: {
    isAppend: false,
    positionInField: "end",
  },
});
