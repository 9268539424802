import { useYupRules } from "@/composables/useYupRules";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const CreateOrUpdateNumberSettingsTemplate = (t, form, action): Form => {
  const { rulesDocumentation } = useYupRules();
  const dinamycFields: Field[] =
    rulesDocumentation.number.reduce((acc, validation) => {
      const ruleDocumentation = rulesDocumentation["number"].find(
        (v) => v.name === validation.name
      );
      if (ruleDocumentation.params.length > 0) {
        acc = [
          ...acc,
          {
            conditions: () => {
              return form?.value?.values?.customField?.metadata?.fieldSettings?.validations?.find(
                (v) => v.value === validation.name
              );
            },
            path: `customField.metadata.fieldSettings.params.${validation.name}`,
            inputName: `customFieldValidationParams.${validation.name}`,
            label: {
              value: "customFields.quantity",
              needsTranslate: true,
              params: {
                name: t(`customFields.yupRules.${ruleDocumentation.name}`),
              },
            },
            type: "text",
            metadata: {
              labelPosition: "top",
              tooltipPosition: "top",
            },
          },
        ];
      }
      return acc;
    }, []) ?? [];
  return {
    sections: [
      {
        fields: [
          {
            path: "customField.metadata.fieldSettings.unitOfMeasurement",
            inputName: "customFieldUnitOfMeasurement",
            label: {
              value: "customFields.unitOfMeasurement",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "top",
            },
          },
          {
            path: "customField.metadata.fieldSettings.validations",
            inputName: "customFieldValidations",
            label: {
              value: "customFields.validations",
              needsTranslate: true,
            },
            type: "multiple-select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              idKey: `value`,
              labelKey: `name`,
              isMultiple: true,
              initialOptions: rulesDocumentation["number"].map(
                (ruleDocumentation) => {
                  return {
                    name: {
                      value: `customFields.yupRules.${ruleDocumentation.name}`,
                      needsTranslate: true,
                    },
                    value: ruleDocumentation.name,
                  };
                }
              ),
            },
          },
          ...dinamycFields,
          {
            path: "customField.metadata.fieldSettings.isCurrency",
            inputName: "customFieldIsCurrency",
            label: {
              value: "customFields.isCurrency",
              needsTranslate: true,
            },
            type: "checkbox",
            metadata: {
              labelPosition: "right",
              tooltipPosition: "top",
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: { value: "customFields.save", needsTranslate: true },
      action,
    },
  };
};
