import { CheckInventoryInterface } from "@/store/inventories/interfaces/CheckInventory.interface";
import { CheckResultInventory } from "@/store/inventories/interfaces/CheckResultInventory.interface";
import { InventoryTransaction } from "@/store/inventories/interfaces/TransactionInventory.interface";
import { RawMaterialsInventory } from "@/store/inventories/models/RawMaterialsInventory.model";
import {
  CheckRawMaterialInventory,
  DispatchRawMaterialInventory,
  InsertRawMaterialInventory,
  ModifyRawMaterialInventory,
} from "@/store/inventories/services/RawMaterialsInventory.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useRawMaterialsInventoryServices() {
  const { t } = useI18n();

  const checkRawMaterialInventory = async (
    payload: CheckInventoryInterface
  ): Promise<CheckResultInventory> => {
    return await CheckRawMaterialInventory(payload);
  };

  const dispatchRawMaterialInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: RawMaterialsInventory[]) => void
  ): Promise<void> => {
    await DispatchRawMaterialInventory(payload)
      .then(async (inventories: RawMaterialsInventory[]) => {
        callBack(inventories);
        useToast().successToast(t("inventories.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const insertRawMaterialInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: RawMaterialsInventory[]) => void
  ): Promise<void> => {
    await InsertRawMaterialInventory(payload)
      .then(async (inventories: RawMaterialsInventory[]) => {
        callBack(inventories);
        useToast().successToast(t("inventories.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const modifyRawMaterialInventory = async (
    payload: InventoryTransaction,
    callBack: (inventories: RawMaterialsInventory[]) => void
  ): Promise<void> => {
    await ModifyRawMaterialInventory(payload)
      .then(async (inventory: RawMaterialsInventory) => {
        callBack([inventory]);
        useToast().successToast(t("inventories.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    dispatchRawMaterialInventory,
    insertRawMaterialInventory,
    modifyRawMaterialInventory,
    checkRawMaterialInventory,
  };
}
