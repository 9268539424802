import axiosInstance from "@/middlewares/Routing.middleware";
import { WorkplacesFlow } from "../models/WorkplacesFlow.model";

export async function GetWorkplacesFlow(
  productId: number
): Promise<WorkplacesFlow> {
  return (await axiosInstance.get(`workplaces-flows/${productId}`)).data;
}

export async function UpdateWorkplacesFlow(
  productId: number,
  payload: WorkplacesFlow
): Promise<WorkplacesFlow> {
  return (await axiosInstance.put(`workplaces-flows/${productId}`, payload))
    .data;
}
