import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

export const CreateOrUpdateDateSettingsTemplate = (
  action,
  validations
): Form => {
  return {
    sections: [
      {
        fields: [],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: { value: "customFields.save", needsTranslate: true },
      action,
    },
  };
};
