import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "dropdown-menu" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["onClick"]

import {
  CustomActionMenu,
  RouterActionMenu,
} from "@/shared/dashboard/sidebar/types/ActionsSidebarElements.type";
import { SidebarElementSubmenu } from "@/shared/dashboard/sidebar/types/SidebarElements.type";
import { Dropdown } from "bootstrap";
import {
  onMounted,
  PropType,
  ref,
  toRef,
  watch,
} from "vue";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'SubmenuOption',
  props: {
  option: {
    type: Object as PropType<SidebarElementSubmenu>,
    required: true,
  },
  parentIsClosed: {
    type: Boolean,
    required: true,
  },
},
  emits: ["navigating", "openDropdown"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;
const router = useRouter();
const dropdown = ref();
const parentIsClosedRef = toRef(props, "parentIsClosed");
const isOpenDropdown = ref(false);
const submenus = ref([]);

const emit = __emit;

const closeAllDropdowns = (index: number) => {
  submenus.value.forEach((submenu, index2) => {
    if (submenu.isOpenDropdown && index2 !== index) {
      submenu.closeDropdown();
    }
  });
};

function OpenOrCloseDropdown() {
  if (isOpenDropdown.value) {
    closeDropdown();
  } else {
    openDropdown();
  }
}

function openDropdown() {
  Dropdown.getOrCreateInstance(dropdown.value)?.show();
  isOpenDropdown.value = true;
  emit("openDropdown");
}

function closeDropdown(emitClose = false) {
  Dropdown.getOrCreateInstance(dropdown.value)?.hide();
  if (emitClose) {
    emit("navigating");
  }
  isOpenDropdown.value = false;
}

function navigateTo(action: RouterActionMenu | CustomActionMenu): void {
  if ("route" in action) {
    router.push({ name: action.route });
  }
  closeDropdown(true);
}

onMounted(() => {
  if (props.option.submenu) {
    Dropdown.getOrCreateInstance(dropdown.value, {
      display: "dynamic",
      popperConfig: function (defaultBsPopperConfig) {
        defaultBsPopperConfig.placement = "right";
        defaultBsPopperConfig.modifiers = [
          {
            name: "preventOverflow",
            options: {
              boundary: "clippingParents",
            },
          },
        ];
        return defaultBsPopperConfig;
      },
    });
  }
});

watch(parentIsClosedRef, () => {
  if (!parentIsClosedRef.value) {
    closeDropdown();
  }
});
const optionStyles = ["d-flex", "justify-content-start", "text-nowrap"];

__expose({ isOpenDropdown, closeDropdown });

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SubmenuOption = _resolveComponent("SubmenuOption", true)!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!props.option.submenu)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: { name: (props.option.action as RouterActionMenu).route },
          class: _normalizeClass([...optionStyles]),
          onClick: _cache[0] || (_cache[0] = () => emit('navigating'))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t(props.option.i18nName)), 1)
          ]),
          _: 1
        }, 8, ["to", "class"]))
      : (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: "#",
          role: "button",
          onClick: OpenOrCloseDropdown,
          class: _normalizeClass([...optionStyles])
        }, _toDisplayString(_ctx.$t(props.option.i18nName)), 3)),
    _createElementVNode("div", {
      class: "nav-item dropdown",
      ref_key: "dropdown",
      ref: dropdown
    }, [
      _createElementVNode("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.option.submenu, (element, index) => {
          return (_openBlock(), _createElementBlock("li", {
            key: element.i18nName + index
          }, [
            (element.submenu)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                  _createVNode(_component_SubmenuOption, {
                    option: element,
                    "parent-is-closed": isOpenDropdown.value,
                    ref_for: true,
                    ref_key: "submenus",
                    ref: submenus,
                    onNavigating: _cache[1] || (_cache[1] = () => closeDropdown(true)),
                    onOpenDropdown: () => closeAllDropdowns(index)
                  }, null, 8, ["option", "parent-is-closed", "onOpenDropdown"])
                ]))
              : (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: ($event: any) => (navigateTo(element.action)),
                  class: _normalizeClass([...optionStyles]),
                  href: "#"
                }, _toDisplayString(_ctx.$t(element.i18nName)), 11, _hoisted_3))
          ]))
        }), 128))
      ])
    ], 512)
  ], 64))
}
}

})