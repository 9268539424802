import validator from "validator";
import { MethodYup } from "../types/MethodYup.type";

import { t } from "../../../locales/services/i18n.services";

export const stringYup: MethodYup = { name: "string", params: [] };

export const objectYup: MethodYup = { name: "object", params: [] };

export const numberYup: MethodYup = { name: "number", params: [] };

export const dateYup: MethodYup = { name: "date", params: [] };

export const arrayYup: MethodYup = { name: "array", params: [] };

export const isEmailYup: MethodYup = {
  name: "test",
  params: [
    "is-email",
    t("global.inputs.rules.email"),
    (value) => {
      if (value) {
        return validator.isEmail(value);
      }
      return false;
    },
  ],
};

export const onlyNumbersYup: MethodYup = {
  name: "test",
  params: [
    "is-number",
    t("global.inputs.rules.onlyNumber"),
    (value) => {
      if (value) {
        return /^\d+$/.test(value);
      }
      return false;
    },
  ],
};

export const isPositiveNumberYup: MethodYup = {
  name: "test",
  params: [
    "is-positive-number",
    t("global.inputs.rules.positiveNumber"),
    (value) => {
      if (value) {
        return Number(value) > 0;
      }
      return false;
    },
  ],
};

export const isGreaterYup = (num: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-chars",
      t("global.inputs.rules.isGreater", { num }),
      (value: number) => {
        if (value) {
          return value >= Number(num);
        }
        return false;
      },
    ],
  };
};

export const isLessYup = (num: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-chars",
      t("global.inputs.rules.isLess", { num }),
      (value: number) => {
        if (value) {
          return value <= Number(num);
        }
        return false;
      },
    ],
  };
};

export const minCharsYup = (quantity: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-chars",
      t("global.inputs.rules.minQuantity", { quantity }),
      (value) => {
        if (value) {
          return value.length >= Number(quantity);
        }
        return false;
      },
    ],
  };
};

export const requiredYup: MethodYup = {
  name: "test",
  params: [
    "is-required",
    t("global.inputs.rules.required"),
    (value: string | number | boolean | Date | object | Array<object>) => {
      let returnValue;
      if (value === undefined || value === null) {
        returnValue = false;
      } else if (Array.isArray(value)) {
        returnValue = value.length > 0;
      } else {
        if (typeof value === "object") {
          if (value instanceof Date) {
            returnValue = !isNaN(value.getTime()) && value !== null;
          } else if (Object.keys(value).length > 0) {
            returnValue = true;
          }
        } else if (typeof value === "string" && value !== "") {
          returnValue = true;
        } else if (typeof value === "number" && !isNaN(value)) {
          returnValue = true;
        }
      }
      return returnValue;
    },
  ],
};

export const isEmpty: MethodYup = {
  name: "test",
  params: [
    "is-empty",
    t("global.inputs.rules.isOptional"),
    (value) => {
      return value;
    },
  ],
};

export const minSpecialCharsYup = (quantity: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-special-chars",
      t("global.inputs.rules.especialCharactersLimited", { quantity }),
      (value) => {
        if (value) {
          const count = (value || "").match(/[^a-zA-Z0-9\s]/g)?.length || 0;
          return count >= Number(quantity);
        }
        return false;
      },
    ],
  };
};

export const minNumbersYup = (quantity: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-special-chars",
      t("global.inputs.rules.numberCharactersLimited", { quantity }),
      (value) => {
        if (value) {
          const count = (value || "").match(/\d/g)?.length || 0;
          return count >= Number(quantity);
        }
        return false;
      },
    ],
  };
};

export const minUpperCaseYup = (quantity: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-special-chars",
      t("global.inputs.rules.upperCaseLimited", { quantity }),
      (value) => {
        if (value) {
          const count = (value || "").match(/[A-Z]/g)?.length || 0;
          return count >= Number(quantity);
        }
        return false;
      },
    ],
  };
};

export const minLowerCaseYup = (quantity: number): MethodYup => {
  return {
    name: "test",
    params: [
      "min-special-chars",
      t("global.inputs.rules.lowerCaseLimited", { quantity }),
      (value) => {
        if (value) {
          const count = (value || "").match(/[a-z]/g)?.length || 0;
          return count >= Number(quantity);
        }
        return false;
      },
    ],
  };
};

export const passwordValidation = [
  stringYup,
  requiredYup,
  minUpperCaseYup(2),
  minLowerCaseYup(2),
  minNumbersYup(2),
  minSpecialCharsYup(1),
  minCharsYup(8),
];

export const isCoordinatesYup: MethodYup = {
  name: "test",
  params: [
    "is-google-maps-iframe",
    t("global.inputs.rules.isCoordinates"),
    (value) => {
      if (!value) return false;

      // RegEx para validar latitud y longitud
      const coordinatesRegex = /^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/;

      // Validar si cumple el formato de coordenadas
      const isValidFormat = coordinatesRegex.test(value.trim());
      if (!isValidFormat) return false;

      // Extraer latitud y longitud
      const [, latitude, , longitude] =
        coordinatesRegex.exec(value.trim()) || [];
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);

      // Validar rangos
      return lat >= -90 && lat <= 90 && lng >= -180 && lng <= 180;
    },
  ],
};
