import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isGreaterYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

export const CreateOrUpdateStepTemplate = (
  action,
  isCreating: boolean
): Form => {
  const { lang } = useLanguageServices();
  return {
    sections: [
      {
        fields: [
          {
            path: "workplace",
            inputName: "workplacesFlowWorkplace",
            label: {
              value: "workplacesFlows.entity.workplace",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              dataSource: "/workplaces/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "workTime",
            inputName: "workplacesFlowWorkTime",
            label: {
              value: "workplacesFlows.entity.workTime",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isGreaterYup(0)];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "workplacesFlows.form.addStep"
          : "workplacesFlows.form.updateStep",
        needsTranslate: true,
      },
      action,
    },
  };
};
