import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { Customer } from "@/store/customers/models/Customer.model";
import {
  CreateCustomer,
  DeleteCustomer,
  GetAllCustomers,
  UpdateCustomer,
} from "@/store/customers/services/Customer.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useCustomersServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "customers";

  const customers = computed(() => {
    return store.state.customers.customers;
  });

  const getAllCustomers = async (): Promise<void> => {
    if (store.state.measures.measures.length === 0) {
      await GetAllCustomers()
        .then((customers: Partial<Customer>[]) => {
          store.commit("customers/setCustomers", customers);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
        });
    }
  };

  const createCustomer = async (
    payload: Customer,
    callBackCreate: (customer: Customer) => void
  ): Promise<void> => {
    await CreateCustomer(payload)
      .then((customer: Customer) => {
        store.commit("customers/addCustomer", customer);
        callBackCreate(customer);
        useToast().successToast(t("customers.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateCustomer = async (
    payload: Customer,
    callBackUpdate: (customer: Customer) => void
  ): Promise<void> => {
    await UpdateCustomer(payload)
      .then(() => {
        store.commit("customers/updateCustomer", payload);
        callBackUpdate(payload);
        useToast().successToast(t("customers.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteCustomer = async (
    payload: Customer,
    callBackDelete: (customer: Customer) => void
  ): Promise<void> => {
    await DeleteCustomer(payload.id)
      .then(() => {
        callBackDelete(payload);
        store.commit("customers/deleteCustomer", payload);
        useToast().successToast(t("customers.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    customers,
    getAllCustomers,
    createCustomer,
    updateCustomer,
    deleteCustomer,
  };
}
