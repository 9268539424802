import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { Permission } from "@/store/permissions/models/Permission.model";
import { GetPermissions } from "@/store/permissions/services/Permission.service";
import { computed } from "vue";
import { useStore } from "vuex";

export function usePermissionsServices() {
  const store = useStore();
  const alias = "permissions";

  const permissions = computed(() => {
    return store.state.permissions.permissions;
  });

  async function getPermissions() {
    if (permissions.value.length === 0) {
      await GetPermissions()
        .then((permissions: Permission[]) => {
          store.commit("permissions/setPermissions", permissions);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
          return;
        });
    }
  }

  return { permissions, getPermissions };
}
