export enum REQ_RES_CODES {
  ACCESS_EXPIRED = "ACCESS_EXPIRED",
  CREDENTIALS_UNKNOWN = "CREDENTIALS_UNKNOWN",
  INVALID_CODE = "INVALID_CODE",
  INVALID_PASSWORD = "INVALID_PASSWORD",
  ATTEMPTS_LIMIT_EXCEEDED = "ATTEMPTS_LIMIT_EXCEEDED",
  NOT_AUTHORIZED = "NOT_AUTHORIZED",
  PASSWORD_EXPIRED = "PASSWORD_EXPIRED",
  ENTITY_NOT_FOUND = "ENTITY_NOT_FOUND",
  ENTITY_ALREADY_EXIST = "ENTITY_ALREADY_EXIST",
  NEED_CHANGE_PASSWORD = "NEED_CHANGE_PASSWORD",
  SAME_ENTITY = "SAME_ENTITY",
  DETAILS_EMPTY = "DETAILS_EMPTY",
  WRONG_VALUES = "WRONG_VALUES",
}
