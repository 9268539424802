import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mt-3" }
const _hoisted_2 = { class: "col-auto p-0 dividerText d-flex align-items-center" }
const _hoisted_3 = { class: "m-0 fs-5" }

import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { PropType } from "vue";
import ProcessText from "../UiTools/ProcessText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'SectionName',
  props: {
  name: { type: Object as PropType<LabelType>, required: true },
},
  setup(__props) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "col-1" }, [
      _createElementVNode("hr")
    ], -1)),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("b", _hoisted_3, [
        _createVNode(ProcessText, { value: __props.name }, null, 8, ["value"])
      ])
    ]),
    _cache[1] || (_cache[1] = _createElementVNode("div", { class: "col pl-1" }, [
      _createElementVNode("hr")
    ], -1))
  ]))
}
}

})