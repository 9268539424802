<template>
  <div class="container-fluid m-0 min-vh-100 d-flex flex-column">
    <div class="row justify-content-end">
      <div class="col-auto">
        <ChangeLang />
      </div>
    </div>
    <div class="row flex-grow-1">
      <div class="col p-0">
        <div
          id="carouselAuthViews"
          class="carousel slide h-100"
          ref="carrousel"
          data-bs-touch="false"
        >
          <div class="carousel-item h-100 w-100">
            <ForgotPassword @goToSlide="goToSlide" />
          </div>
          <div class="carousel-item active h-100 w-100">
            <LoginBox
              @goToSlide="goToSlide"
              @emitTemporalToken="setTemporalToken"
            />
          </div>
          <div class="carousel-item h-100 w-100">
            <ChangePassword
              @goToSlide="goToSlide"
              :temporal-token="temporalToken"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChangePassword from "@/components/auth/ChangePassword.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import LoginBox from "@/components/auth/LoginBox.vue";
import ChangeLang from "@/components/lang/ChangeLang.vue";
import { useToast } from "@/composables/useToastServices";
import { Carousel } from "bootstrap";
import { onBeforeMount, Ref, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

const store = useStore();
const { t } = useI18n(); // lifecycle hooks
onBeforeMount(() => {
  if (store.state.auth.launchExpiredSessionToast) {
    useToast().infoToast(t("users.errors.ACCESS_EXPIRED"));
    store.commit("auth/setLaunchExpiredSessionToast", false);
  }
});

const temporalToken: Ref<string> = ref(null);

const carousel = useTemplateRef<Element>("carrousel" as never);

function setTemporalToken(event) {
  temporalToken.value = event;
}

function goToSlide(slide: number): void {
  const carouselTemp = new Carousel(carousel.value);
  carouselTemp.to(slide);
}
</script>

<style lang="scss" scoped>
#carouselAuthViews {
  .carousel-item {
    transition: transform 0.5s ease-out;
  }
}
</style>
