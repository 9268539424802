<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="supplierForm"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useSuppliersServices } from "@/composables/useSuppliersServices";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Supplier } from "@/store/suppliers/models/Supplier.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateSupplierTemplate } from "./templates/forms/CreateOrUpdateSupplier.template";

const { customFields } = useCustomFieldsServices();
const { createSupplier, updateSupplier } = useSuppliersServices();

const props = defineProps({
  initialValues: { type: Object as PropType<Supplier>, required: false },
});

const initialValuesRef: Ref<Supplier> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = defineEmits(["handleSubmit"]);

const currentSupplier = computed(() => {
  if (initialValuesRef.value) {
    const supplier = formatValues(initialValuesRef.value);
    return supplier ?? null;
  }
  return null;
});

function callBackCreate(supplier: Supplier) {
  emit("handleSubmit", { supplier, isCreating: true });
}

function callBackEdit(supplier: Supplier) {
  emit("handleSubmit", {
    supplier,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { supplier } = form.value.values;
  if (!initialValuesRef.value) {
    await createSupplier(supplier, callBackCreate);
  } else {
    await updateSupplier(supplier, callBackEdit);
  }
}

function formatValues(values: Supplier) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    code: values.code,
    name: values.name,
    supplierType: values.supplierType,
    businessType: values.businessType,
    state: values.state,
    city: values.city,
    customFields: customFieldsFiltered,
  };
}

watch(
  [customFields, currentSupplier],
  async () => {
    formTemplateRef.value = CreateOrUpdateSupplierTemplate(
      customFields.value,
      handleSubmit,
      !currentSupplier.value
    );
    if (currentSupplier.value) {
      await nextTick();
      form.value?.setValues({ supplier: currentSupplier.value });
    }
  },
  { deep: true, immediate: true }
);

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss"></style>
