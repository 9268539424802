import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = {
  key: 0,
  class: "col-12 d-flex justify-content-center"
}
const _hoisted_5 = { class: "col-12" }
const _hoisted_6 = { class: "col-12" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useValidations } from "@/composables/useValidations";
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import {
  Form,
  FormSection,
} from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Modal } from "bootstrap";
import { cloneDeep, get, merge } from "lodash";
import {
  computed,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { AddNameTranslateTemplate } from "./templates/forms/AddNameTranslate.template";
import { AddOptionTranslateTemplate } from "./templates/forms/AddOptionTranslate.template";
import { CreateOrUpdateCustomFieldTemplate } from "./templates/forms/CreateOrUpdateCustomField.template";
import { CreateOrUpdateDateSettingsTemplate } from "./templates/forms/CreateOrUpdateDateSettings.template";
import { CreateOrUpdateNumberSettingsTemplate } from "./templates/forms/CreateOrUpdateNumberSettings.template";
import {
  ButtonDeleteTemplate,
  ButtonTranslateTemplate,
  CreateFieldTemplate,
  CreateOrUpdateMultipleSelectSettingsTemplate,
} from "./templates/forms/CreateOrUpdateSelectSettings.template";
import { CreateOrUpdateTextSettingsTemplate } from "./templates/forms/CreateOrUpdateTextSettings.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateCustomFields',
  props: {
  initialModuleName: { type: String, required: false },
  initialValues: { type: Object, required: false },
  isCreating: { type: Boolean, required: true },
},
  emits: ["customFieldSubmitted"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const validations = useValidations();
const { createCustomField, updateCustomField } = useCustomFieldsServices(
  props.initialModuleName
);
const { t } = useI18n();

const emit = __emit;

const formMain = ref();
const settingsForm = ref();
const translateForm = ref();
const formMainTemplate = ref(
  CreateOrUpdateCustomFieldTemplate(
    submitMain,
    formMain,
    validations,
    props.initialValues,
    props.isCreating,
    openSettings,
    openTranslate
  )
);
const formSelectSettingsTemplate = ref(
  CreateOrUpdateMultipleSelectSettingsTemplate(submitSettings, validations)
);
const formTextSettingsTemplate = ref(
  CreateOrUpdateTextSettingsTemplate(t, settingsForm, submitSettings)
);
const formNumberSettingsTemplate = ref(
  CreateOrUpdateNumberSettingsTemplate(t, settingsForm, submitSettings)
);
const formDateSettingsTemplate = ref(
  CreateOrUpdateDateSettingsTemplate(submitSettings, validations)
);
const formTranslateTemplate = ref();
const translateModal = ref();
const currentIndex = ref();

// DATA

const settingsFormTrick = computed(() => {
  return settingsForm?.value?.values ?? {};
});

const typeName = computed(() => {
  const type = props.initialValues?.customField?.type;
  if (type === "multiple-select") {
    return "multipleSelect";
  }
  return type;
});

const initialValuesMain = computed(() => {
  if (props.initialValues) {
    return {
      customField: {
        id: props.initialValues?.customField?.id,
        name: props.initialValues?.customField?.name,
        type: {
          value: props.initialValues?.customField?.type,
          name: typeName.value ? t(`customFields.types.${typeName.value}`) : "",
        },
        required: props.initialValues?.customField?.required,
        metadata: {
          options: props.initialValues?.customField?.metadata?.options,
        },
      },
    };
  }
  return null;
});

const initialValuesTranslates = computed(() => {
  const language = props.initialValues?.customField?.metadata?.language;
  if (language?.name || language?.options) {
    return {
      customField: {
        metadata: {
          language: {
            name: props.initialValues?.customField?.metadata?.language?.name,
            options:
              props.initialValues?.customField?.metadata?.language?.options ??
              [],
          },
        },
      },
    };
  }
  return null;
});

const customFieldTemp = computed(() => {
  const customField = merge(
    {},
    formMain.value?.values ?? {},
    translatesValues.value
  );
  return {
    ...customField.customField,
    type: customField.customField?.type?.id ?? "",
  };
});

const type = computed(() => {
  return customFieldTemp.value.type;
});

const translatesValues = ref(initialValuesTranslates.value ?? {});
const mainValues = ref(initialValuesMain.value ?? {});

// OPEN HANDLES

async function openTranslate() {
  formTranslateTemplate.value = AddNameTranslateTemplate(submitTranslate);
  translateForm.value?.setValues(translatesValues.value);
  currentIndex.value = -1;
  Modal.getOrCreateInstance(translateModal.value.$el).show();
}

function openSettings() {
  const modalElement = document.getElementById(
    `settingsModal${formMain?.value?.formId}`
  );
  let modalInstance = Modal.getInstance(modalElement);
  if (!modalInstance) {
    modalInstance = new Modal(modalElement);
  }
  //settingsForm.value?.setValues(<COMPLETAR!!>);
  modalInstance?.show();
}

// SUBMIT HANDLES

async function submitSettings() {
  const modalElement = document.getElementById(
    `settingsModal${formMain?.value.formId}`
  );
  const modalInstance = Modal.getInstance(modalElement);
  modalInstance?.hide();
}

async function submitTranslate() {
  translatesValues.value = merge(
    {},
    translatesValues.value,
    cloneDeep(translateForm.value.values)
  );
  Modal.getInstance(translateModal.value.$el)?.hide();
}

async function submitMain() {
  mainValues.value = merge({}, mainValues.value, formMain.value.values);
  const customField: CustomFieldInterface = customFieldTemp.value;
  if (props.isCreating) {
    await createCustomField({
      customField,
      callBack: handleCustomFieldSubmit,
    });
  } else {
    await updateCustomField({
      customField,
      callBack: handleCustomFieldSubmit,
    });
  }
}

// OTHERS...

const buttonAdd: ButtonTemplate = addButton(() =>
  addOptionField(
    getSection(formMainTemplate, {
      value: "customFields.optionsValues",
      needsTranslate: true,
    })
  )
);

function handleCustomFieldSubmit() {
  if (props.isCreating) {
    resetForm();
  }
  emit("customFieldSubmitted");
}

function resetForm() {
  mainValues.value = {};
  translatesValues.value = {};
  formMain.value.resetAllFields();
}

function getSection(form: Ref<Form>, sectionName: LabelType): FormSection {
  return form.value.sections.find((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function getSectionIndex(form: Ref<Form>, sectionName: LabelType) {
  return form.value.sections.findIndex((e) => {
    return JSON.stringify(e.name) === JSON.stringify(sectionName);
  });
}

function deleteOptionsField(indexOptionField) {
  const inputName = `customFieldOption.${indexOptionField}`;
  const sectionIndex = getSectionIndex(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  if (sectionIndex > -1) {
    const fieldIndex = formMainTemplate.value.sections[
      sectionIndex
    ].fields.findIndex((f) => f.inputName === inputName);
    if (fieldIndex > -1) {
      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      formMainTemplate.value.sections[sectionIndex].fields[
        fieldIndex
      ].conditions = () => false;

      const options = get(
        translatesValues.value,
        "customField.metadata.language.options",
        false
      );
      const name = get(
        translatesValues.value,
        "customField.metadata.language.name",
        false
      );
      if (options) {
        const newOptions = Object.entries(options).map((translate) => {
          const [lang, values] = translate;
          const newValues = Object.entries(values).filter(([key]) => {
            return key !== indexOptionField;
          });
          return [lang, Object.fromEntries(newValues)];
        });
        translatesValues.value = {
          customField: {
            metadata: {
              language: { options: Object.fromEntries(newOptions), name },
            },
          },
        };
      }
    }
  }
}

function addOptionField(
  section: FormSection,
  indexOptionField?: string,
  removable = true,
  propsField?
) {
  let lastIndex;
  if (indexOptionField) {
    lastIndex = indexOptionField.split("_")[1];
  } else {
    const lasField = section.fields.at(-1);
    lastIndex = lasField?.path.split("._")[1] ?? -1;
    lastIndex = Number(lastIndex) + 1;
    indexOptionField = `_${lastIndex}`;
  }

  let optionField: Field = CreateFieldTemplate(validations);
  optionField = {
    ...optionField,
    path: `${optionField.path}${indexOptionField}`,
    inputName: `${optionField.inputName}${indexOptionField}`,
    label: null,
    placeholder: {
      value: `${optionField.placeholder.value}`,
      params: { optionIndex: `${lastIndex}` },
      needsTranslate: true,
    },
    ...(propsField ? propsField : {}),
    buttons: [
      {
        ...ButtonTranslateTemplate,
        action: async () => {
          currentIndex.value = indexOptionField;
          formTranslateTemplate.value = AddOptionTranslateTemplate(
            submitTranslate,
            validations,
            indexOptionField
          );
          translateForm.value?.setValues(translatesValues.value);
          Modal.getOrCreateInstance(translateModal.value.$el).show();
        },
      },
      {
        ...ButtonDeleteTemplate,
        ...(!removable
          ? { customClass: `${ButtonDeleteTemplate.customClass} disabled` }
          : {}),
        action: () => {
          deleteOptionsField(indexOptionField);
        },
      },
    ],
  };
  section.fields.push(optionField);
}

function clearOptionsField() {
  const section = getSection(formMainTemplate, {
    value: "customFields.optionsValues",
    needsTranslate: true,
  });
  section.fields = [];
}

watch(
  settingsFormTrick,
  async () => {
    formTextSettingsTemplate.value = CreateOrUpdateTextSettingsTemplate(
      t,
      settingsForm,
      submitSettings
    );
  },
  { immediate: true, deep: true }
);

onMounted(() => {
  clearOptionsField();

  if (initialValuesMain.value) {
    formMain.value?.setValues(initialValuesMain.value);
    if (
      initialValuesMain.value?.customField?.type?.value &&
      ["select", "multiple-select"].includes(
        initialValuesMain.value?.customField?.type?.value
      )
    ) {
      const options = Object.keys(
        props.initialValues?.customField.metadata?.options
      );
      options.forEach((option, index) => {
        addOptionField(
          getSection(formMainTemplate, {
            value: "customFields.optionsValues",
            needsTranslate: true,
          }),
          option,
          index !== 0
        );
      });
    }
  } else {
    addOptionField(
      getSection(formMainTemplate, {
        value: "customFields.optionsValues",
        needsTranslate: true,
      }),
      null,
      false
    );
  }
});

__expose({
  resetForm,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(GeneralForm, {
        ref_key: "formMain",
        ref: formMain,
        form: formMainTemplate.value,
        formName: "create"
      }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (['select', 'multiple-select'].includes(type.value))
              ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                  _createVNode(ButtonField, { buttonField: _unref(buttonAdd) }, null, 8, ["buttonField"])
                ]))
              : _createCommentVNode("", true)
          ])
        ]),
        _: 1
      }, 8, ["form"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(GeneralModal, {
        modalId: `settingsModal${formMain.value?.formId}`,
        modalName: _ctx.$t('customFields.settings'),
        "data-bs-backdrop": "false",
        class: "p-4"
      }, {
        default: _withCtx(() => [
          (type.value === 'text')
            ? (_openBlock(), _createBlock(GeneralForm, {
                key: 0,
                form: formTextSettingsTemplate.value,
                formName: "settings",
                ref_key: "settingsForm",
                ref: settingsForm
              }, null, 8, ["form"]))
            : _createCommentVNode("", true),
          (type.value === 'number')
            ? (_openBlock(), _createBlock(GeneralForm, {
                key: 1,
                form: formNumberSettingsTemplate.value,
                formName: "settings",
                ref_key: "settingsForm",
                ref: settingsForm
              }, null, 8, ["form"]))
            : _createCommentVNode("", true),
          (type.value === 'date')
            ? (_openBlock(), _createBlock(GeneralForm, {
                key: 2,
                form: formDateSettingsTemplate.value,
                formName: "settings",
                ref_key: "settingsForm",
                ref: settingsForm
              }, null, 8, ["form"]))
            : _createCommentVNode("", true),
          (type.value === 'multiple-select')
            ? (_openBlock(), _createBlock(GeneralForm, {
                key: 3,
                form: formSelectSettingsTemplate.value,
                formName: "settings",
                ref_key: "settingsForm",
                ref: settingsForm
              }, null, 8, ["form"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["modalId", "modalName"])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(GeneralModal, {
        modalId: "translateModal",
        ref_key: "translateModal",
        ref: translateModal,
        modalName: `${_ctx.$t('customFields.translates')}`,
        "data-bs-backdrop": "false",
        class: "p-4"
      }, {
        default: _withCtx(() => [
          _createVNode(GeneralForm, {
            form: formTranslateTemplate.value,
            formName: `translatesForm${currentIndex.value}`,
            ref_key: "translateForm",
            ref: translateForm
          }, null, 8, ["form", "formName"])
        ]),
        _: 1
      }, 8, ["modalName"])
    ])
  ]))
}
}

})