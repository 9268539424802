import Compressor from "compressorjs";

function getQualityCriteria({ size }: File) {
  if (size <= 8 * 10 ** 6) {
    return 1;
  } else if (size <= 14 * 10 ** 6) {
    return 0.8;
  } else if (size <= 24 * 10 ** 6) {
    return 0.6;
  } else if (size <= 33 * 10 ** 6) {
    return 0.4;
  } else if (size <= 35 * 10 ** 6) {
    return 0.2;
  }
  return 1;
}

export async function compressImage(file: File): Promise<File> {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: getQualityCriteria(file),
      success(result: File) {
        if (result.size > 1 * 10 ** 7) {
          reject();
        }
        resolve(result);
      },
      error(err) {
        console.error(err);
        reject();
      },
    });
  });
}
