import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-bold bg-secondary text-white p-1 rounded" }
const _hoisted_2 = { class: "row mt-1" }
const _hoisted_3 = { class: "fw-bold m-0" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { class: "fw-bold m-0" }
const _hoisted_6 = { class: "m-0" }
const _hoisted_7 = { class: "fw-bold m-0" }
const _hoisted_8 = { class: "m-0" }

import { Product } from "@/store/products/models/Product.model";
import { PropType, computed, toRef } from "vue";

type Detail = {
  product: { id: number; label?: string; item: Product; items: Product[] };
  discount: number;
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'OrderDetail',
  props: {
  detail: { type: Object as PropType<Detail>, required: true },
},
  setup(__props) {

const props = __props;
const detailRef = toRef(props, "detail");
const subtotal = computed(() => {
  return (
    (detailRef.value.quantity *
      detailRef.value.product.item.price *
      (100 - detailRef.value.discount)) /
    100
  );
});

return (_ctx: any,_cache: any) => {
  const _component_class = _resolveComponent("class")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(__props.detail.product.label), 1)
    ]),
    _createElementVNode("p", _hoisted_2, [
      _createVNode(_component_class, { class: "col" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(__props.detail.quantity), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("orderDetail.entity.quantity")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_class, { class: "col" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(__props.detail.discount), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("orderDetail.entity.discount")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_class, { class: "col" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_7, _toDisplayString(subtotal.value), 1),
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("orderDetail.entity.subtotal")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})