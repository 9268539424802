import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { Position } from "@/store/positions/models/Position.model";
import {
  CreatePosition,
  DeletePosition,
  GetAllPositions,
  UpdatePosition,
} from "@/store/positions/services/Position.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function usePositionsServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "positions";

  const positions = computed(() => {
    return store.state.positions.positions;
  });

  const getAllPositions = async (): Promise<void> => {
    if (store.state.positions.positions.length === 0) {
      await GetAllPositions()
        .then((positions: Partial<Position>[]) => {
          store.commit("positions/setPositions", positions);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
        });
    }
  };

  const createPosition = async (
    payload: Position,
    callBackCreate: (position: Position) => void
  ): Promise<void> => {
    await CreatePosition(payload)
      .then((position: Position) => {
        store.commit("positions/addPosition", position);
        callBackCreate(position);
        useToast().successToast(t("positions.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updatePosition = async (
    payload: Position,
    callBackUpdate: (position: Position) => void
  ): Promise<void> => {
    await UpdatePosition(payload)
      .then((position: Position) => {
        store.commit("positions/updatePosition", position);
        callBackUpdate(position);
        useToast().successToast(t("positions.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deletePosition = async (
    payload: Position,
    callBackDelete: (position: Position) => void
  ): Promise<void> => {
    await DeletePosition(payload.id)
      .then(() => {
        callBackDelete(payload);
        store.commit("positions/deletePosition", payload);
        useToast().successToast(t("positions.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    positions,
    getAllPositions,
    createPosition,
    updatePosition,
    deletePosition,
  };
}
