import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { Product } from "@/store/products/models/Product.model";
import {
  CreateProduct,
  DeleteProduct,
  GetAllProducts,
  UpdateProduct,
} from "@/store/products/services/Product.service";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useProductsServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "products";

  const products: Ref<Product[]> = computed(() => {
    return store.state.products.products;
  });

  const getAllProducts = async (): Promise<void> => {
    if (store.state.products.products.length === 0) {
      await GetAllProducts()
        .then((products: Partial<Product>[]) => {
          store.commit("products/setProducts", products);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
        });
    }
  };

  const createProduct = async (
    payload: Product,
    callBackCreate: (product: Product) => void
  ): Promise<void> => {
    await CreateProduct(payload)
      .then((product: Product) => {
        store.commit("products/addProduct", product);
        callBackCreate(product);
        useToast().successToast(t("products.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateProduct = async (
    payload: Product,
    callBackUpdate: (product: Product) => void
  ): Promise<void> => {
    await UpdateProduct(payload)
      .then((product: Product) => {
        store.commit("products/updateProduct", payload);
        callBackUpdate(product);
        useToast().successToast(t("products.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteProduct = async (
    payload: Product,
    callBackDelete: (product: Product) => void
  ): Promise<void> => {
    await DeleteProduct(payload.id)
      .then(() => {
        callBackDelete(payload);
        store.commit("products/deleteProduct", payload);
        useToast().successToast(t("products.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    products,
    getAllProducts,
    createProduct,
    updateProduct,
    deleteProduct,
  };
}
