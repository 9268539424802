import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  isCoordinatesYup,
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateDivisionTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean
): Form => {
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "division.name",
            inputName: "divisionName",
            label: {
              value: "divisions.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
          },
          {
            path: "division.location",
            inputName: "divisionLocation",
            label: {
              value: "divisions.entity.location",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, isCoordinatesYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "divisions.form.add" : "divisions.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "division", "division"),
    });
  }

  return form;
};
