import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { WorkplaceFlowStep } from "@/store/workplaces-flow/interfaces/WorkplacesFlow.interface";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  computed,
  nextTick,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateStep from "./CreateOrUpdateStep.vue";
import { CreateOrUpdateWorkplaceFlows } from "./templates/forms/CreateOrUpdateWorkplaceFlows.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateWorkplacesFlow',
  props: {
  productId: { type: Number, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("workplace_flows");

const props = __props;

const { workplaceFlow, getWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices(props.productId);

const workplaceFlowForm = ref();
const workplaceFlowFormTemplateRef = ref();
const steps: Ref<WorkplaceFlowStep[]> = ref([]);

const emit = __emit;

const currentWorkplacesFlow: Ref<WorkplacesFlow> = computed(() => {
  if (workplaceFlow.value) {
    const workplacesFlow = formatValues(workplaceFlow.value);
    return workplacesFlow ?? null;
  }
  return null;
});

function callBackEdit(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", {
    workplacesFlow,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { workplaceFlow } = workplaceFlowForm.value.values;
  /*
  const stepsUpdated = steps.value.map((step, index) => {
    return { ...step, step: index + 1 };
  });
  */
  await updateWorkplacesFlow(
    props.productId,
    { ...workplaceFlow, steps: steps.value },
    callBackEdit
  );
}

function formatValues(values: WorkplacesFlow) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    product: { id: props.productId },
    steps:
      values.steps
        ?.map((step) => {
          const { workplace, ...stepProps } = step;
          const { id } = workplace;
          return {
            workplace: { id, label: translateFromKey(workplace, "name") },
            ...stepProps,
          };
        })
        .sort((a, b) => a.step - b.step) ?? [],
    customFields: customFieldsFiltered,
  };
}

onMounted(async () => {
  await Promise.all([getModuleCustomFields(), getWorkplacesFlow()]);
  steps.value = currentWorkplacesFlow.value?.steps;
  watch(
    [customFields, steps],
    async () => {
      workplaceFlowFormTemplateRef.value = CreateOrUpdateWorkplaceFlows(
        customFields.value,
        handleSubmit
      );
      await nextTick();
      workplaceFlowForm.value?.setValues({
        workplaceFlow: currentWorkplacesFlow.value,
      });
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms: () => workplaceFlowForm.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(GeneralForm, {
        ref_key: "workplaceFlowForm",
        ref: workplaceFlowForm,
        form: workplaceFlowFormTemplateRef.value,
        key: props.productId,
        "form-name": "workplacesFlowForm"
      }, {
        default: _withCtx(() => [
          _createVNode(CreateOrUpdateStep, {
            steps: steps.value,
            modelValue: steps.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((steps).value = $event))
          }, null, 8, ["steps", "modelValue"])
        ]),
        _: 1
      }, 8, ["form"]))
    ])
  ]))
}
}

})