import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";

export const DeleteProductFamilyTemplate = (
  action: () => void,
  currentProductFamily: number
): Form => {
  const { lang } = useLanguageServices();

  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "newId",
            inputName: "newId",
            label: {
              value: "productFamilies.form.selectNewProductFamily",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "left",
            },
            selectOptions: {
              dataSource: "/product-families/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
              excludeOptions: [{ id: currentProductFamily }],
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      text: {
        value: "productFamilies.form.deleteAndReplace",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
