import { DeleteStoreInterface } from "@/store/stores/interfaces/DeleteStore.interface";
import { Store } from "@/store/stores/models/Store.model";
import {
  CreateStore,
  DeleteStore,
  UpdateStore,
} from "@/store/stores/services/Store.service";
import { useI18n } from "vue-i18n";
import { useToast } from "./useToastServices";

export function useStoresServices() {
  const { t } = useI18n();

  const createStore = async (
    payload: Store,
    callBackCreate: (store: Store) => void
  ): Promise<void> => {
    await CreateStore(payload)
      .then(async (storeTemp: Store) => {
        callBackCreate(storeTemp);
        useToast().successToast(t("stores.success.created"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const updateStore = async (
    payload: Store,
    callBackUpdate: (store: Store) => void
  ): Promise<void> => {
    await UpdateStore(payload)
      .then(async (storeTemp: Store) => {
        callBackUpdate(storeTemp);
        useToast().successToast(t("stores.success.updated"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  const deleteStore = async (
    payload: DeleteStoreInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteStore(payload)
      .then(async () => {
        callBackDelete();
        useToast().successToast(t("stores.success.deleted"));
      })
      .catch(({ response }) => {
        useToast().errorToast(t("global.UNKNOWN_ERROR"));
      });
  };

  return {
    createStore,
    updateStore,
    deleteStore,
  };
}
