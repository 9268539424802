import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";
import {
  CreateRawMaterial,
  DeleteRawMaterial,
  UpdateRawMaterial,
} from "@/store/raw-materials/services/RawMaterial.service";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useRawMaterialsServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "rawMaterials";

  const createRawMaterial = async (
    payload: RawMaterial,
    callBackCreate: (rawMaterial: RawMaterial) => void
  ): Promise<void> => {
    await CreateRawMaterial(payload)
      .then((rawMaterial: RawMaterial) => {
        store.commit("rawMaterials/addRawMaterial", rawMaterial);
        callBackCreate(rawMaterial);
        useToast().successToast(t("rawMaterials.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateRawMaterial = async (
    payload: RawMaterial,
    callBackUpdate: (rawMaterial: RawMaterial) => void
  ): Promise<void> => {
    await UpdateRawMaterial(payload)
      .then((rawMaterial: RawMaterial) => {
        store.commit("rawMaterials/updateRawMaterial", rawMaterial);
        callBackUpdate(rawMaterial);
        useToast().successToast(t("rawMaterials.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteRawMaterial = async (
    payload: RawMaterial,
    callBackDelete: (rawMaterial: RawMaterial) => void
  ): Promise<void> => {
    await DeleteRawMaterial(payload.id)
      .then(() => {
        callBackDelete(payload);
        store.commit("rawMaterials/deleteRawMaterial", payload);
        useToast().successToast(t("rawMaterials.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    createRawMaterial,
    updateRawMaterial,
    deleteRawMaterial,
  };
}
