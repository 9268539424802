import { useAuthServices } from "@/composables/useAuthServices";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { REFRESH_SESSION_URL } from "@/store/auth/consts/refresh-session-url.const";
import store from "@/store/index";
import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";
import { get } from "lodash";

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_SMART_TALENT_CORE_URL,
  //withCredentials: true,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  async function (config: InternalAxiosRequestConfig) {
    //config.headers.set("Access-Control-Allow-Origin", "*");
    const {
      auth: {
        value: { token, refreshToken },
      },
    } = useAuthServices();
    if (config.url !== REFRESH_SESSION_URL && token) {
      config.headers.set("x-refresh-token", `${refreshToken}`);
    }
    if (token && !config.headers.getAuthorization()) {
      config.headers.set("authorization", `Bearer ${token}`);
    }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response: AxiosResponse) {
    // Do something with response data
    const { setAuthToken } = useAuthServices();
    const refreshSessionToken = get(
      response,
      "headers.x-refresh-session-token",
      false
    );
    if (refreshSessionToken) {
      setAuthToken({ token: refreshSessionToken });
    }
    return response;
  },
  function (error) {
    // Do something with response error
    if (error.response.data.messageCode === REQ_RES_CODES.ACCESS_EXPIRED) {
      store.dispatch("auth/launchExpiredSession");
    }
    return Promise.reject({ ...error, response: error.response.data });
  }
);

export default axiosInstance;
