import axiosInstance from "@/middlewares/Routing.middleware";
import { DeleteMeasureInterface } from "@/store/measures/interfaces/DeleteMeasure.interface";
import { Supplier } from "../models/Supplier.model";

export async function GetAllSuppliers(): Promise<Partial<Supplier>[]> {
  return (await axiosInstance.get("/suppliers/all")).data;
}
export async function CreateSupplier(payload: Supplier): Promise<Supplier> {
  return (await axiosInstance.post(`suppliers`, payload)).data;
}

export async function UpdateSupplier(payload: Supplier): Promise<Supplier> {
  const { id, ...supplierProps } = payload;
  return (await axiosInstance.put(`suppliers/${id}`, supplierProps)).data;
}

export async function DeleteSupplier(
  payload: DeleteMeasureInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`suppliers?newId=${newId}&oldId=${oldId}`);
}
