<template>
  <div>
    <p>
      <span class="fw-bold bg-secondary text-white p-1 rounded">
        {{ step.workplace.label }}
      </span>
    </p>
    <p class="row mt-1">
      <class class="col">
        <p class="fw-bold m-0">{{ props.step.step }}</p>
        <p class="m-0">{{ $t("workplacesFlows.entity.step") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ step.workTime }}</p>
        <p class="m-0">{{ $t("workplacesFlows.entity.workTime") }}</p>
      </class>
    </p>
  </div>
</template>

<script lang="ts" setup>
import { WorkplaceFlowStep } from "@/store/workplaces-flow/interfaces/WorkplacesFlow.interface";
import { PropType, defineProps } from "vue";

const props = defineProps({
  step: { type: Object as PropType<WorkplaceFlowStep>, required: true },
});
</script>

<style lang="scss"></style>
