import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "fw-bold bg-secondary text-white p-1 rounded" }
const _hoisted_2 = { class: "row mt-1" }
const _hoisted_3 = { class: "fw-bold m-0" }
const _hoisted_4 = { class: "m-0" }
const _hoisted_5 = { class: "fw-bold m-0" }
const _hoisted_6 = { class: "m-0" }

import { WorkplaceFlowStep } from "@/store/workplaces-flow/interfaces/WorkplacesFlow.interface";
import { PropType } from "vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'WorkplaceFlowStep',
  props: {
  step: { type: Object as PropType<WorkplaceFlowStep>, required: true },
},
  setup(__props) {

const props = __props;

return (_ctx: any,_cache: any) => {
  const _component_class = _resolveComponent("class")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", null, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(__props.step.workplace.label), 1)
    ]),
    _createElementVNode("p", _hoisted_2, [
      _createVNode(_component_class, { class: "col" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_3, _toDisplayString(props.step.step), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t("workplacesFlows.entity.step")), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_class, { class: "col" }, {
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_5, _toDisplayString(__props.step.workTime), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.$t("workplacesFlows.entity.workTime")), 1)
        ]),
        _: 1
      })
    ])
  ]))
}
}

})