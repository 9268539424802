import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { MeasureInterface } from "../interfaces/Measures.interface";

export class MeasureDto implements MeasureInterface {
  id = 0;
  name = "";
  description = "";
  createdAt = null;
  updatedAt = null;
  metadata = null;
  customFields: CustomFieldValues;
}

export class Measure extends MeasureDto {
  constructor(MeasureDto: MeasureDto) {
    super();
    Object.assign(this, MeasureDto);
  }
}
