import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";

const { langValues } = useLanguageServices();

export const AddOptionTranslateTemplate = (
  action,
  validations,
  indexOptionTranslate
): Form => {
  return {
    sections: [
      {
        fields: langValues.value.map((language) => {
          return {
            path: `customField.metadata.language.options.${language.key}.${indexOptionTranslate}`,
            inputName: `customFieldOptionTranslate.${indexOptionTranslate}.${language.key}`,
            label: {
              value: `${language.name}`,
              needsTranslate: false,
            },
            type: "text",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              const { yupRules } = validations;
              const { stringYup, requiredYup } = yupRules;
              const validation = [stringYup, requiredYup];
              return validation;
            },
          };
        }),
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "customFields.save",
        needsTranslate: true,
      },
      action,
    },
  };
};
