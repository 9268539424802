import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = ["disabled"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm",
  style: {"color":"white"},
  role: "status",
  "aria-hidden": "true"
}
const _hoisted_3 = { key: 1 }

import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { PropType, Ref, ref } from "vue";
import ProcessText from "../UiTools/ProcessText.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'ButtonField',
  props: {
  buttonField: {
    type: Object as PropType<ButtonTemplate>,
    required: true,
  },
},
  setup(__props) {

const props = __props;

const isLoading: Ref<boolean> = ref(false);

async function action() {
  isLoading.value =
    true && (props.buttonField as ButtonTemplate).metadata?.requireSpinner;
  if ((props.buttonField as ButtonTemplate).action) {
    await (props.buttonField as ButtonTemplate).action();
  }
  isLoading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(`input-group-text ${(__props.buttonField as ButtonTemplate).customClass}`),
    type: "button",
    style: _normalizeStyle({
      backgroundColor: (__props.buttonField as ButtonTemplate).backgroundColor,
    }),
    onClick: _withModifiers(action, ["prevent"]),
    disabled: (props.buttonField as ButtonTemplate)?.isDisabled ?? false
  }, [
    (isLoading.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(ProcessText, {
            style: {"color":"white"},
            value: (__props.buttonField as ButtonTemplate).text
          }, null, 8, ["value"]),
          ((__props.buttonField as ButtonTemplate).graphic?.icon)
            ? (_openBlock(), _createElementBlock("i", {
                key: 0,
                class: _normalizeClass((__props.buttonField as ButtonTemplate).graphic.icon.icon),
                style: _normalizeStyle({
        color: (__props.buttonField as ButtonTemplate).graphic.icon.color,
        background: (__props.buttonField as ButtonTemplate).graphic.icon.backgroundColor,
      })
              }, null, 6))
            : _createCommentVNode("", true)
        ]))
  ], 14, _hoisted_1))
}
}

})