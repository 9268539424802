import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid m-0 min-vh-100 d-flex flex-column" }
const _hoisted_2 = { class: "row justify-content-end" }
const _hoisted_3 = { class: "col-auto" }
const _hoisted_4 = { class: "row flex-grow-1" }
const _hoisted_5 = { class: "col p-0" }
const _hoisted_6 = {
  id: "carouselAuthViews",
  class: "carousel slide h-100",
  ref: "carrousel",
  "data-bs-touch": "false"
}
const _hoisted_7 = { class: "carousel-item h-100 w-100" }
const _hoisted_8 = { class: "carousel-item active h-100 w-100" }
const _hoisted_9 = { class: "carousel-item h-100 w-100" }

import ChangePassword from "@/components/auth/ChangePassword.vue";
import ForgotPassword from "@/components/auth/ForgotPassword.vue";
import LoginBox from "@/components/auth/LoginBox.vue";
import ChangeLang from "@/components/lang/ChangeLang.vue";
import { useToast } from "@/composables/useToastServices";
import { Carousel } from "bootstrap";
import { onBeforeMount, Ref, ref, useTemplateRef } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuthView',
  setup(__props) {

const store = useStore();
const { t } = useI18n(); // lifecycle hooks
onBeforeMount(() => {
  if (store.state.auth.launchExpiredSessionToast) {
    useToast().infoToast(t("users.errors.ACCESS_EXPIRED"));
    store.commit("auth/setLaunchExpiredSessionToast", false);
  }
});

const temporalToken: Ref<string> = ref(null);

const carousel = useTemplateRef<Element>("carrousel" as never);

function setTemporalToken(event) {
  temporalToken.value = event;
}

function goToSlide(slide: number): void {
  const carouselTemp = new Carousel(carousel.value);
  carouselTemp.to(slide);
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(ChangeLang)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(ForgotPassword, { onGoToSlide: goToSlide })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createVNode(LoginBox, {
              onGoToSlide: goToSlide,
              onEmitTemporalToken: setTemporalToken
            })
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(ChangePassword, {
              onGoToSlide: goToSlide,
              "temporal-token": temporalToken.value
            }, null, 8, ["temporal-token"])
          ])
        ], 512)
      ])
    ])
  ]))
}
}

})