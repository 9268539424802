<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <GeneralForm
          ref="form"
          :form="formTemplateRef"
          :key="JSON.stringify(props.initialValues)"
          form-name="divisionForm"
        >
          <div class="my-3">
            <MapMaster
              :latitude="coordinates.latitude"
              :longitude="coordinates.longitude"
            />
          </div>
        </GeneralForm>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Division } from "@/store/divisions/models/Division.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import MapMaster from "../globals/inputs/MapMaster.vue";
import { CreateOrUpdateDivisionTemplate } from "./templates/forms/CreateOrUpdateDivision.template";

const { customFields } = useCustomFieldsServices();
const { createDivision, updateDivision } = useDivisionsServices();

const props = defineProps({
  initialValues: { type: Object as PropType<Division>, required: false },
});

const initialValuesRef: Ref<Division> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = defineEmits(["handleSubmit"]);

const coordinates = computed(() => {
  const [latitude, longitude] = form?.value?.values?.division?.location?.split(
    ","
  ) ?? [19.432608, -99.133209];
  return {
    latitude: (!isNaN(latitude) && Number(latitude)) || 19.432608,
    longitude: (!isNaN(longitude) && Number(longitude)) || -99.133209,
  };
});

const currentDivision = computed(() => {
  if (initialValuesRef.value) {
    const division = formatValues(initialValuesRef.value);
    return division ?? null;
  }
  return null;
});

function callBackCreate(division: Division) {
  emit("handleSubmit", { division, isCreating: true });
}

function callBackEdit(division: Division) {
  emit("handleSubmit", {
    division,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { division } = form.value.values;
  if (!initialValuesRef.value) {
    await createDivision(division, callBackCreate);
  } else {
    await updateDivision(division, callBackEdit);
  }
}

function formatValues(values: Division) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    location: values.location,
    customFields: customFieldsFiltered,
  };
}

watch(
  currentDivision,
  async () => {
    formTemplateRef.value = CreateOrUpdateDivisionTemplate(
      customFields.value,
      handleSubmit,
      !currentDivision.value
    );
    if (currentDivision.value) {
      await nextTick();
      form.value?.setValues({ division: currentDivision.value });
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

defineExpose({
  resetForms: () => form.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
</style>
