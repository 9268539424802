import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "accordion",
  id: "accordionCustomFields"
}
const _hoisted_2 = { class: "accordion-item" }
const _hoisted_3 = {
  class: "accordion-header createCustomField",
  "data-bs-toggle": "collapse",
  "data-bs-target": "#createCustomFieldsAcordion",
  "aria-expanded": "true",
  "aria-controls": "createCustomFieldsAcordion"
}
const _hoisted_4 = { class: "w-100 d-flex justify-content-center" }
const _hoisted_5 = { class: "accordion-body" }
const _hoisted_6 = ["id"]
const _hoisted_7 = { class: "m-2" }
const _hoisted_8 = { class: "w-100 d-flex align-items-center justify-content-between" }
const _hoisted_9 = ["id"]
const _hoisted_10 = { class: "accordion-body pt-0" }

import CreateOrUpdateCustomFields from "@/components/customFields/CreateOrUpdateCustomFields.vue";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import { onMounted, ref } from "vue";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import ConfirmModal from "../globals/modals/ConfirmModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'CustomFields',
  props: {
  initialModuleName: { type: String, required: false },
},
  emits: ["customFieldSubmitted"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const { getModuleCustomFields, customFields, deleteCustomField } =
  useCustomFieldsServices(props.initialModuleName);

const emit = __emit;

const createForm = ref();
const editCollapses = ref();
const modals = ref([]);
const isDeleting = ref(false);
const editCounter = ref(0);
const createAcordion = ref();
const messageDeletion = (customField): LabelType => {
  return {
    value: "customFields.questionDelete",
    params: {
      name: customField.name,
    },
    needsTranslate: true,
  };
};

function resetForms() {
  createForm.value.resetForm();
}

function openDeleteCustomFieldModal(modalIndex) {
  modals.value[modalIndex].openConfirmationModal();
}

async function handleDeleteCustomField(customFieldId) {
  isDeleting.value = true;
  await deleteCustomField(customFieldId);
  isDeleting.value = false;
}

function resetView() {
  resetForms();
}

function handleCustomFieldSubmit() {
  resetView();
}

onMounted(async () => {
  await getModuleCustomFields();
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.$t("customFields.create")), 1)
      ]),
      _createElementVNode("div", {
        id: "createCustomFieldsAcordion",
        class: "accordion-collapse collapse",
        "data-bs-parent": "#accordionCustomFields",
        ref_key: "createAcordion",
        ref: createAcordion
      }, [
        _createElementVNode("div", _hoisted_5, [
          _createVNode(CreateOrUpdateCustomFields, {
            initialModuleName: __props.initialModuleName,
            ref_key: "createForm",
            ref: createForm,
            isCreating: true,
            onCustomFieldSubmitted: handleCustomFieldSubmit
          }, null, 8, ["initialModuleName"])
        ])
      ], 512)
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(customFields), (customField, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "accordion-item pl-2",
        key: customField.id,
        ref_for: true,
        ref: "acordions"
      }, [
        _createElementVNode("div", {
          class: "accordion-header",
          id: `showCustomFieldsAcordion${customField.id}`
        }, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", null, [
                _createElementVNode("span", null, _toDisplayString(customField.name), 1)
              ]),
              _createElementVNode("div", null, [
                _createVNode(ButtonField, {
                  "button-field": 
                  _unref(editButton)(() => {
                    editCounter.value++;
                    return;
                  })
                ,
                  "data-bs-toggle": "collapse",
                  "data-bs-target": `#showCustomFields${customField.id}`,
                  "aria-expanded": "true",
                  "aria-controls": `showCustomFields${customField.id}`
                }, null, 8, ["button-field", "data-bs-target", "aria-controls"]),
                _createVNode(ButtonField, {
                  "button-field": 
                  _unref(deleteButton)(() => openDeleteCustomFieldModal(index))
                
                }, null, 8, ["button-field"])
              ])
            ])
          ])
        ], 8, _hoisted_6),
        _createElementVNode("div", {
          id: `showCustomFields${customField.id}`,
          class: "accordion-collapse collapse",
          ref_for: true,
          ref_key: "editCollapses",
          ref: editCollapses,
          "data-bs-parent": "#accordionCustomFields"
        }, [
          _createElementVNode("div", _hoisted_10, [
            (_openBlock(), _createBlock(CreateOrUpdateCustomFields, {
              initialModuleName: __props.initialModuleName,
              "initial-values": { customField },
              isCreating: false,
              key: editCounter.value,
              onCustomFieldSubmitted: handleCustomFieldSubmit
            }, null, 8, ["initialModuleName", "initial-values"]))
          ])
        ], 8, _hoisted_9),
        _createElementVNode("div", null, [
          _createVNode(ConfirmModal, {
            title: _ctx.$t('global.warnings.delete'),
            ref_for: true,
            ref_key: "modals",
            ref: modals,
            message: messageDeletion(customField),
            confirmAction: 
            async () => {
              await handleDeleteCustomField(customField.id);
            }
          
          }, null, 8, ["title", "message", "confirmAction"])
        ])
      ]))
    }), 128))
  ]))
}
}

})