import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "my-3" }

import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useDivisionsServices } from "@/composables/useDivisionsServices";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { Division } from "@/store/divisions/models/Division.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import MapMaster from "../globals/inputs/MapMaster.vue";
import { CreateOrUpdateDivisionTemplate } from "./templates/forms/CreateOrUpdateDivision.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateDivision',
  props: {
  initialValues: { type: Object as PropType<Division>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createDivision, updateDivision } = useDivisionsServices();

const props = __props;

const initialValuesRef: Ref<Division> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();
const isValid = ref(false);
const emit = __emit;

const coordinates = computed(() => {
  const [latitude, longitude] = form?.value?.values?.division?.location?.split(
    ","
  ) ?? [19.432608, -99.133209];
  return {
    latitude: (!isNaN(latitude) && Number(latitude)) || 19.432608,
    longitude: (!isNaN(longitude) && Number(longitude)) || -99.133209,
  };
});

const currentDivision = computed(() => {
  if (initialValuesRef.value) {
    const division = formatValues(initialValuesRef.value);
    return division ?? null;
  }
  return null;
});

function callBackCreate(division: Division) {
  emit("handleSubmit", { division, isCreating: true });
}

function callBackEdit(division: Division) {
  emit("handleSubmit", {
    division,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { division } = form.value.values;
  if (!initialValuesRef.value) {
    await createDivision(division, callBackCreate);
  } else {
    await updateDivision(division, callBackEdit);
  }
}

function formatValues(values: Division) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    location: values.location,
    customFields: customFieldsFiltered,
  };
}

watch(
  currentDivision,
  async () => {
    formTemplateRef.value = CreateOrUpdateDivisionTemplate(
      customFields.value,
      handleSubmit,
      !currentDivision.value
    );
    if (currentDivision.value) {
      await nextTick();
      form.value?.setValues({ division: currentDivision.value });
    }
  },
  { deep: true, immediate: true }
);

onMounted(() => {
  watch(
    form?.value?.values,
    async () => {
      isValid.value = (await form?.value?.validateForm()) ?? false;
    },
    { deep: true }
  );
});

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "divisionForm"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(MapMaster, {
                latitude: coordinates.value.latitude,
                longitude: coordinates.value.longitude
              }, null, 8, ["latitude", "longitude"])
            ])
          ]),
          _: 1
        }, 8, ["form"]))
      ])
    ])
  ]))
}
}

})