import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useUsersServices } from "@/composables/useUsersServices";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import { User } from "@/store/auth/models/User.model";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { cloneDeep } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateUserTemplate } from "./templates/forms/CreateOrUpdateUser.template";

export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateUser',
  props: {
  initialValues: { type: Object as PropType<UserInterface>, required: false },
  openPositionOffcanvas: {
    type: Object as PropType<() => void>,
    required: true,
  },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createUser, updateUser } = useUsersServices();
const { catalogs, getAllCatalogs } = useCatalogsServices();

const props = __props;

const initialValuesRef: Ref<UserInterface> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = __emit;

const currentUser = computed(() => {
  if (initialValuesRef.value) {
    const user = formatValues(initialValuesRef.value);
    return user ?? null;
  }
  return null;
});

function callBackCreate(user: User) {
  emit("handleSubmit", { user, isCreating: true });
}

function callBackEdit(user: User) {
  emit("handleSubmit", {
    user,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { user } = form.value.values;
  const userFormatted = cloneDeep(user);
  userFormatted.status = { id: user.status.id };
  userFormatted.profile = { id: user.profile.id };
  userFormatted.positions = user?.positions?.map((position) => {
    return { id: position.id };
  });
  userFormatted.mainPosition = { id: user?.mainPosition?.id };
  if (!initialValuesRef.value) {
    await createUser(userFormatted, callBackCreate);
  } else {
    await updateUser(userFormatted, callBackEdit);
  }
}

function formatValues(values: User) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    email: values.email,
    profile: values.profile,
    status: values.status,
    customFields: customFieldsFiltered ?? {},
    mainPosition: values.mainPosition,
    positions: values.positions,
  };
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await getAllCatalogs();
  watch(
    [currentUser],
    async () => {
      formTemplateRef.value = CreateOrUpdateUserTemplate(
        customFields.value,
        handleSubmit,
        !currentUser.value,
        catalogs.value,
        props.openPositionOffcanvas
      );
      if (currentUser.value) {
        await nextTick();
        form.value?.setValues({ user: currentUser.value });
      }
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          key: JSON.stringify(props.initialValues),
          form: formTemplateRef.value,
          "form-name": "usersForm"
        }, null, 8, ["form"]))
      ])
    ])
  ]))
}
}

})