<template>
  <div :key="JSON.stringify(position)">
    <GoogleMap
      :api-key="API_GOOGLE_MAPS"
      style="width: 100%; height: 300px"
      :center="position"
      :zoom="15"
    >
      <Marker v-if="markerOptions" :options="markerOptions" />
    </GoogleMap>
  </div>
</template>

<script lang="ts" setup>
import { API_GOOGLE_MAPS } from "@/configs";
import { computed, defineProps, toRef } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";

const props = defineProps({
  latitude: { type: Number, required: true },
  longitude: { type: Number, required: true },
});

const latitudeRef = toRef(props, "latitude");
const longitudeRef = toRef(props, "longitude");

const position = computed(() => ({
  lat: latitudeRef.value,
  lng: longitudeRef.value,
}));

const markerOptions = computed(() => ({
  position: position.value,
  draggable: false,
}));
</script>
