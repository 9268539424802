import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateWorkplaceFlows = (
  customFields: CustomFieldInterface[],
  action
): Form => {
  const form: Form = {
    sections: [
      {
        fields: [],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: "workplacesFlows.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(
        customFields,
        "workplaceFlow",
        "workplaceFlow"
      ),
    });
  }

  return form;
};
