import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { DeleteProductFamilyInterface } from "@/store/product-families/interfaces/DeleteProductFamily.interface";
import { ProductFamily } from "@/store/product-families/models/ProductFamily.model";
import {
  CreateProductFamily,
  DeleteProductFamily,
  GetAllProductFamilies,
  UpdateProductFamily,
} from "@/store/product-families/services/ProductFamily.service";
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useProductFamiliesServices() {
  const store = useStore();
  const { t } = useI18n();
  const alias = "productFamilies";

  const productFamilies = computed(() => {
    return store.state.productFamilies.productFamilies;
  });

  const getAllProductFamilies = async (): Promise<void> => {
    if (store.state.productFamilies.productFamilies.length === 0) {
      await GetAllProductFamilies()
        .then((productFamilies: Partial<ProductFamily>[]) => {
          store.commit("productFamilies/setProductFamilies", productFamilies);
        })
        .catch(({ response }) => {
          showToastError(alias, response.messageCode);
        });
    }
  };

  const createProductFamily = async (
    payload: ProductFamily,
    callBackCreate: (productFamily: ProductFamily) => void
  ): Promise<void> => {
    await CreateProductFamily(payload)
      .then(async (productFamily: ProductFamily) => {
        await getAllProductFamilies();
        store.commit("productFamilies/addProductFamily", {
          id: productFamily.id,
          name: productFamily.name,
          description: productFamily.description,
          metadata: productFamily.metadata,
          customFields: productFamily.customFields,
        });
        callBackCreate(productFamily);
        useToast().successToast(t("productFamilies.success.created"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateProductFamily = async (
    payload: ProductFamily,
    callBackUpdate: (productFamily: ProductFamily) => void
  ): Promise<void> => {
    await UpdateProductFamily(payload)
      .then(async () => {
        await getAllProductFamilies();
        store.commit("productFamilies/updateProductFamily", payload);
        callBackUpdate(payload);
        useToast().successToast(t("productFamilies.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteProductFamily = async (
    payload: DeleteProductFamilyInterface,
    callBackDelete: () => void
  ): Promise<void> => {
    await DeleteProductFamily(payload)
      .then(async () => {
        await getAllProductFamilies();
        callBackDelete();
        store.commit("productFamilies/deleteProductFamily", {
          id: payload.oldId,
        });
        useToast().successToast(t("productFamilies.success.deleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return {
    productFamilies,
    getAllProductFamilies,
    createProductFamily,
    updateProductFamily,
    deleteProductFamily,
  };
}
