import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import {
  CreateCustomField,
  DeleteCustomField,
  UpdateCustomField,
} from "@/store/customFields/services/CustomField.service";
import { GetCustomFields } from "@/store/modules/services/Module.service";
import { computed, Ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useCustomFieldsServices(initialModuleName?: string) {
  const { t } = useI18n();
  const store = useStore();
  const route = useRoute();
  const moduleName: string =
    initialModuleName ?? (route.meta.moduleName as string);
  const alias = "customFields";

  const customFields: Ref<CustomFieldInterface[]> = computed(() => {
    return store?.state?.modules?.[moduleName]?.customFields ?? [];
  });

  const createCustomField = async ({
    customField,
    callBack,
  }: {
    customField: CustomFieldInterface;
    callBack: () => void;
  }): Promise<void> => {
    await CreateCustomField(moduleName, customField)
      .then(({ data }) => {
        store.commit("modules/addCustomField", {
          moduleName,
          customField: { ...data, ...customField },
        });
        callBack();
        useToast().successToast(t("customFields.success.customFieldCreated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateCustomField = async ({
    customField,
    callBack,
  }: {
    customField: CustomFieldInterface;
    callBack: () => void;
  }): Promise<void> => {
    await UpdateCustomField(moduleName, customField)
      .then(() => {
        store.commit("modules/updateCustomField", {
          moduleName,
          customField,
        });
        callBack();
        useToast().successToast(t("customFields.success.customFieldUpdated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const deleteCustomField = async (id: string): Promise<void> => {
    await DeleteCustomField(id)
      .then(() => {
        store.commit("modules/deleteCustomField", { id, moduleName });
        useToast().successToast(t("customFields.success.customFieldDeleted"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const getModuleCustomFields = async (): Promise<void> => {
    const moduleCustomFields = await GetCustomFields(moduleName);
    store.commit("modules/setCustomFields", moduleCustomFields);
  };

  return {
    createCustomField,
    updateCustomField,
    deleteCustomField,
    getModuleCustomFields,
    customFields,
  };
}
