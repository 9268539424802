import axiosInstance from "@/middlewares/Routing.middleware";
import qs from "qs";
import { CheckInventoryInterface } from "../interfaces/CheckInventory.interface";
import { CheckResultInventory } from "../interfaces/CheckResultInventory.interface";
import { InventoryTransaction } from "../interfaces/TransactionInventory.interface";
import { RawMaterialsInventory } from "../models/RawMaterialsInventory.model";

export async function CheckRawMaterialInventory(
  payload: CheckInventoryInterface
): Promise<CheckResultInventory> {
  const options = {
    params: payload,
  };
  if (payload) {
    options["paramsSerializer"] = (params) => {
      return qs.stringify(params, { arrayFormat: "repeat", encode: false });
    };
  }
  const { data } = await axiosInstance.get(
    "raw-materials-inventories/check",
    options
  );
  return data;
}

export async function DispatchRawMaterialInventory(
  payload: InventoryTransaction
): Promise<RawMaterialsInventory[]> {
  const { data } = await axiosInstance.post(
    `raw-materials-inventories/dispatch`,
    payload
  );
  return data;
}

export async function InsertRawMaterialInventory(
  payload: InventoryTransaction
): Promise<RawMaterialsInventory[]> {
  const { data } = await axiosInstance.post(
    `raw-materials-inventories/insert`,
    payload
  );
  return data;
}

export async function ModifyRawMaterialInventory(
  payload: InventoryTransaction
): Promise<RawMaterialsInventory> {
  const { id, ...inventoryProps } = payload;
  const { data } = await axiosInstance.put(
    `raw-materials-inventories/${id}/modify`,
    inventoryProps
  );
  return data;
}
