<template>
  <div v-if="debug" class="text-wrap">
    <p>
      initialFilesRef:
      {{
        initialFilesRef.map(({ isUrl, file }) => {
          return { isUrl, file };
        })
      }}
    </p>
    <p>srcs: {{ srcs.length }}</p>
    <p>files: {{ files.length }}</p>
    <p v-for="(file, index) of files" :key="`size${index}`">
      file {{ index }} size: {{ file.size }}
    </p>
  </div>

  <div class="card py-2 w-100">
    <div class="row">
      <div class="col-12 d-flex flex-start">
        <div style="margin-left: 8px">
          <button
            type="button"
            @click="() => fileInput.click()"
            class="btn btn-primary"
          >
            Cargar
          </button>
          <input
            type="file"
            style="display: none"
            ref="fileInput"
            class="form-control"
            @change="handleFileChange"
            :multiple="fileOptions.isMultiple"
            accept="`${accept}/*`"
          />
        </div>
      </div>
      <div class="col-12">
        <div class="d-flex flex-row justify-content-start flex-wrap">
          <div
            v-for="srcKey of Object.keys(srcs)"
            :key="files[srcKey].name + files[srcKey].type + files[srcKey].size"
          >
            <div
              class="card p-2 m-2 d-flex flex-column justify-content-center align-items-center"
            >
              <img
                v-if="srcs[srcKey] && fileOptions.imageOptions.showPreview"
                :src="srcs[srcKey] as string"
                :width="fileOptions.imageOptions.width"
                :height="fileOptions.imageOptions.height"
              />
              <i
                @click="() => onRemoveFile(srcKey)"
                class="bi bi-x-circle icon-wrapper text-danger"
              ></i>
              {{ files[srcKey].name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useToast } from "@/composables/useToastServices";
import { FileOptions } from "@/shared/globals/forms/interfaces/FileOptions.interface";
import { compressImage } from "@/shared/globals/helpers/Files.helper";
import { t } from "@/shared/locales/services/i18n.services";
import {
  defineEmits,
  defineProps,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
type FileType = {
  url: string;
  file?: File;
  isUrl: boolean;
};

const props = defineProps({
  modelValue: { type: Array, required: true },
  fileOptions: {
    type: Object as PropType<FileOptions>,
    required: false,
  },
  initialFiles: {
    type: Array<FileType>,
    required: false,
    default: () => [],
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const initialFilesRef: Ref<FileType[]> = toRef(props, "initialFiles");
const fileInput = ref();
const srcs: Ref<{ [key in string]: string }> = ref({});
const files: Ref<{
  [key in string]: File;
}> = ref({});

async function handleFileChange(event) {
  const tempFiles: File[] = Array.from(event.target.files);
  await Promise.all(
    tempFiles.map(async (file) => {
      const url = await getUrlFromFile(file);
      try {
        files.value[file.name] = await compressImage(file);
        srcs.value[file.name] = url;
        return { url, file };
      } catch {
        useToast().errorToast(t("global.errors.imageToBig"));
      }
    })
  );
  emit("update:modelValue", formatValue());
  event.target.value = null;
}

function formatValue(): FileType[] {
  return Object.keys(files.value).map((key) => {
    const url = srcs.value[key];
    const file = files.value[key];
    const isUrl = !url.startsWith("data:");
    return { url, file, isUrl };
  });
}

function onRemoveFile(srcKey: string) {
  const { [`${srcKey}`]: removedSrc, ...newSrcs } = srcs.value;
  const { [`${srcKey}`]: removedFile, ...newFiles } = files.value;
  srcs.value = newSrcs;
  files.value = newFiles;
  emit("update:modelValue", formatValue());
}

function filesNotExists(): boolean {
  return initialFilesRef.value.some((item) => {
    return !item?.file;
  });
}

watch(
  initialFilesRef,
  async () => {
    if (filesNotExists()) {
      const newInitialFiles = await Promise.all(
        initialFilesRef.value.map(async ({ url, file, isUrl }) => {
          if (!file) {
            const response = await fetch(url, {
              mode: "no-cors",
            });
            const data = await response.blob();
            const fileName = url.match(/[^./]+\.[a-zA-Z0-9]+$/)?.[0];
            const tempFile = new File([data], fileName, {
              type: data.type,
            });
            return { url, file: tempFile, isUrl: true };
          }
          return { url, file, isUrl };
        })
      );
      emit("update:modelValue", newInitialFiles);
    } else {
      srcs.value = Object.fromEntries(
        initialFilesRef.value.map(({ url, file }) => {
          return [file.name, url];
        })
      );
      files.value = Object.fromEntries(
        initialFilesRef.value.map(({ file }) => {
          return [file.name, file];
        })
      );
    }
  },
  { immediate: true }
);

function isString(value): value is string {
  if (typeof value === "string") {
    return true;
  }
  return false;
}

async function getUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const url = e.target?.result;
      if (isString(url)) {
        resolve(url);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 30px;
  cursor: pointer;
}

.icon-wrapper:hover {
  background-color: rgba(255, 0, 0, 0.2);
  transform: scale(1.1);
}

.icon-wrapper:hover i {
  color: white;
}
</style>
