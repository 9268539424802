import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock } from "vue"

import { API_GOOGLE_MAPS } from "@/configs";
import { computed, toRef } from "vue";
import { GoogleMap, Marker } from "vue3-google-map";


export default /*@__PURE__*/_defineComponent({
  __name: 'MapMaster',
  props: {
  latitude: { type: Number, required: true },
  longitude: { type: Number, required: true },
},
  setup(__props) {

const props = __props;

const latitudeRef = toRef(props, "latitude");
const longitudeRef = toRef(props, "longitude");

const position = computed(() => ({
  lat: latitudeRef.value,
  lng: longitudeRef.value,
}));

const markerOptions = computed(() => ({
  position: position.value,
  draggable: false,
}));

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    key: JSON.stringify(position.value)
  }, [
    _createVNode(_unref(GoogleMap), {
      "api-key": _unref(API_GOOGLE_MAPS),
      style: {"width":"100%","height":"300px"},
      center: position.value,
      zoom: 15
    }, {
      default: _withCtx(() => [
        (markerOptions.value)
          ? (_openBlock(), _createBlock(_unref(Marker), {
              key: 0,
              options: markerOptions.value
            }, null, 8, ["options"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["api-key", "center"])
  ]))
}
}

})