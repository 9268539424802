import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, renderSlot as _renderSlot, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "container text-start" }
const _hoisted_3 = {
  key: 0,
  class: "row"
}
const _hoisted_4 = {
  key: 0,
  class: "row"
}
const _hoisted_5 = {
  class: /*@__PURE__*/_normalizeClass(`col-12 mt-2`)
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { class: "col-12" }
const _hoisted_10 = { class: "row" }
const _hoisted_11 = { class: "col-12" }
const _hoisted_12 = { class: "row" }
const _hoisted_13 = { class: "col-12" }
const _hoisted_14 = { class: "col-12 p-0 mt-2" }

import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form as FormType } from "@/shared/globals/forms/interfaces/Form.interface";
import { YupValidator } from "@/shared/globals/helpers/YupValidator";
import { useForm } from "vee-validate";
import { PropType, ref, toRef, watch } from "vue";
import ButtonMaster from "../buttons/ButtonMaster.vue";
import MultipleInputMaster from "../inputs/MultipleInputMaster.vue";
import SectionName from "./SectionName.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'GeneralForm',
  props: {
  form: { type: Object as PropType<FormType>, required: true },
  formName: { type: String, required: true },
  debug: { type: Boolean, required: false, default: false },
},
  setup(__props, { expose: __expose }) {

const props = __props;
const yupValidator = ref(createYupValidator());

const formRef = toRef(props.form);
const formProps = useForm();
const {
  values,
  handleSubmit,
  isSubmitting,
  setErrors,
  resetForm,
  setFieldValue,
} = formProps;

const errorBag = ref();

const onSubmit = handleSubmit(async () => {
  const isValid = await validateForm();
  if (isValid) {
    await props.form.submit.action();
  }
});

async function validateForm(): Promise<boolean> {
  const validations = await yupValidator.value.getValidations(values);
  const isValid = Object.values(validations).every((validation) => {
    const value = validation as { error: string; valid: boolean }[];
    return value.every((v) => v.valid);
  });
  if (!isValid) {
    setErrors(validations);
  }
  return isValid;
}

function createYupValidator() {
  const yupValidator = new YupValidator();
  const schema = props.form
    ? props.form.sections.reduce((acc, section) => {
        if (!section.conditions || section.conditions()) {
          section.fields.reduce((acc, field) => {
            if (!field.conditions || field.conditions()) {
              if (
                !Array.isArray(field) &&
                "path" in field &&
                field.methodsYup
              ) {
                const keys = field.path.split(".");
                keys.reduce((acc, key, index, arr) => {
                  if (index === arr.length - 1) {
                    acc[key] = field.methodsYup();
                  }
                  return acc[key] || (acc[key] = {});
                }, acc);
              } else if ("fields" in field) {
                for (const oneField of field.fields) {
                  const keys = oneField.path.split(".");
                  keys.reduce((acc, key, index, arr) => {
                    if (index === arr.length - 1) {
                      acc[key] = oneField.methodsYup?.();
                    }
                    return acc[key] || (acc[key] = {});
                  }, acc);
                }
              }
            }
            return acc;
          }, acc);
        }

        return acc;
      }, {})
    : {};
  yupValidator.addSchema(schema, []);
  return yupValidator;
}

function resetAllFields() {
  resetForm();
  props.form?.sections?.forEach((section) => {
    section.fields?.forEach((field) => {
      if ("fields" in field) {
        for (const oneField of field.fields) {
          if ("type" in oneField && oneField.type === "select") {
            setFieldValue(oneField.path, "");
          }
        }
      } else if ("type" in field && field.type === "select") {
        setFieldValue(field.path, "");
      }
    });
  });
}

watch(
  [values, formRef],
  async () => {
    yupValidator.value = createYupValidator();
    const error = await yupValidator.value.getValidations(values);
    errorBag.value = error;
  },
  { deep: true, immediate: true }
);

__expose({ ...formProps, validateForm, resetAllFields });

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString(_unref(values)), 1))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("form", {
        onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args))),
        class: "row",
        key: __props.formName
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.form?.sections, (section, sectionIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-12",
            key: `${__props.formName}Section-${sectionIndex}`
          }, [
            (section.conditions ? section.conditions() : true)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                  (section.name)
                    ? (_openBlock(), _createBlock(SectionName, {
                        key: 0,
                        name: section.name
                      }, null, 8, ["name"]))
                    : _createCommentVNode("", true),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section?.fields, (field, fieldIndex) => {
                    return (_openBlock(), _createElementBlock("div", {
                      key: `${__props.formName}Section-${sectionIndex}-Field-${fieldIndex}`,
                      class: _normalizeClass(`col-lg-${field.widthDistribution ?? 12} mt-2`),
                      style: _normalizeStyle(`display: ${
              field.conditions?.() ?? true ? 'block' : 'none'
            }`)
                    }, [
                      (field.conditions?.() ?? true)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                            _createElementVNode("div", _hoisted_5, [
                              ('fields' in field)
                                ? (_openBlock(), _createBlock(MultipleInputMaster, {
                                    key: 0,
                                    form: formRef.value,
                                    errorBag: errorBag.value,
                                    sectionIndex: sectionIndex,
                                    fieldIndex: fieldIndex
                                  }, {
                                    "dynamic-slot": _withCtx(({ sectionIndex, fieldIndex, subfieldIndex }) => [
                                      _renderSlot(_ctx.$slots, `section-${sectionIndex}-field-${fieldIndex}-subField-${subfieldIndex}`)
                                    ]),
                                    _: 2
                                  }, 1032, ["form", "errorBag", "sectionIndex", "fieldIndex"]))
                                : (_openBlock(), _createBlock(InputMaster, {
                                    key: 1,
                                    field: field as Field,
                                    errorBag: errorBag.value,
                                    "disabled-default-option": true
                                  }, null, 8, ["field", "errorBag"]))
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("div", _hoisted_7, [
                          (__props.debug)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(`section-${sectionIndex}-field-${fieldIndex}`), 1))
                            : _createCommentVNode("", true),
                          _renderSlot(_ctx.$slots, `section-${sectionIndex}-field-${fieldIndex}`)
                        ])
                      ])
                    ], 6))
                  }), 128))
                ]))
              : _createCommentVNode("", true)
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("div", _hoisted_12, [
            _createElementVNode("div", _hoisted_13, [
              _createElementVNode("div", _hoisted_14, [
                (__props.form?.submit?.text)
                  ? (_openBlock(), _createBlock(ButtonMaster, {
                      key: 0,
                      text: __props.form?.submit?.text,
                      isLoading: _unref(isSubmitting)
                    }, null, 8, ["text", "isLoading"]))
                  : _createCommentVNode("", true)
              ])
            ])
          ])
        ])
      ], 32))
    ])
  ], 64))
}
}

})