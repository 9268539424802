import { useLanguageServices } from "@/composables/useLanguageServices";
import { Menu } from "@/shared/globals/menu/types/Menu.type";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { customFieldToHeader } from "@/store/customFields/helpers/CustomFieldToHeader.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";
import { Measure } from "@/store/measures/models/Measure.model";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";
import { Supplier } from "@/store/suppliers/models/Supplier.model";

export const RawMaterialsHeader = (
  runAction: (
    actionType: "edit" | "delete",
    rawMaterial: RawMaterial,
    index: number
  ) => void,
  customFields: CustomFieldInterface[],
  suppliers: Supplier[],
  measures: Measure[]
): TableHeader[] => {
  const customFieldsHeaders: TableHeader[] = customFieldToHeader(customFields);
  const { lang } = useLanguageServices();

  return [
    {
      sortable: true,
      value: { value: "rawMaterials.entity.id", needsTranslate: true },
      key: "id",
      mappedKey: "id",
      columnType: "number",
      width: "20px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.code", needsTranslate: true },
      key: "code",
      mappedKey: "code",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.description", needsTranslate: true },
      key: "description",
      mappedKey: [`metadata.language.description.${lang.value}`, "description"],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.materialType",
        needsTranslate: true,
      },
      key: "materialType",
      mappedKey: [
        `metadata.language.materialType.${lang.value}`,
        "materialType",
      ],
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.unitCost",
        needsTranslate: true,
      },
      key: "unitCost",
      mappedKey: "unitCost",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.stock", needsTranslate: true },
      key: "stock",
      mappedKey: "stock",
      columnType: "number",
      width: "100px",
      filterType: "number",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.thickness",
        needsTranslate: true,
      },
      key: "thickness",
      mappedKey: "thickness",
      columnType: "number",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.width",
        needsTranslate: true,
      },
      key: "width",
      mappedKey: "width",
      columnType: "number",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.length",
        needsTranslate: true,
      },
      key: "length",
      mappedKey: "length",
      columnType: "number",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.volume",
        needsTranslate: true,
      },
      key: "volume",
      mappedKey: "volume",
      columnType: "number",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: {
        value: "rawMaterials.entity.warehouseLocation",
        needsTranslate: true,
      },
      key: "warehouseLocation",
      mappedKey: "warehouseLocation",
      columnType: "text",
      width: "100px",
      filterType: "text",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.createdAt", needsTranslate: true },
      key: "createdAt",
      mappedKey: "createdAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.updatedAt", needsTranslate: true },
      key: "updatedAt",
      mappedKey: "updatedAt",
      columnType: "date",
      width: "100px",
      filterType: "date",
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.measure", needsTranslate: true },
      key: "measure",
      mainKey: "measure",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "150px",
      filterType: "select",
      selectOptions: {
        dataSource: "/measures/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    {
      sortable: true,
      value: { value: "rawMaterials.entity.supplier", needsTranslate: true },
      key: "supplier",
      mainKey: "supplier",
      mappedKey: [`metadata.language.name.${lang.value}`, "name"],
      columnType: "tag",
      width: "150px",
      filterType: "select",
      selectOptions: {
        dataSource: "/suppliers/",
        idKey: "id",
        labelKey: [`metadata.language.name.${lang.value}`, "name"],
        isMultiple: false,
        searchPath: "name",
      },
    },
    ...customFieldsHeaders,
    {
      sortable: false,
      value: { value: "rawMaterials.table.actions", needsTranslate: true },
      columnType: "action",
      options: (rawMaterial: RawMaterial, index: number): Menu => {
        const options = [
          {
            label: {
              value: "rawMaterials.table.actionList.edit",
              needsTranslate: true,
            },
            id: 1,
            action: () => runAction("edit", rawMaterial, index),
          },
          {
            label: {
              value: "rawMaterials.table.actionList.delete",
              needsTranslate: true,
            },
            id: 2,
            action: () => runAction("delete", rawMaterial, index),
          },
        ];
        return {
          options,
        };
      },
      width: "95px",
    },
  ];
};
