import { showToastError } from "@/shared/globals/helpers/Toast.helper";
import { TechnicalSheet } from "@/store/technical-sheets/models/TechnicalSheet.model";
import {
  GetTechnicalSheet,
  UpdateTechnicalSheet,
} from "@/store/technical-sheets/services/TechnicalSheet.service";
import { Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import { useToast } from "./useToastServices";

export function useTechnicalSheetsServices(productId: number) {
  const store = useStore();
  const { t } = useI18n();
  const alias = "technicalSheets";

  const technicalSheet: Ref<TechnicalSheet> = ref();

  const getTechnicalSheet = async (): Promise<void> => {
    await GetTechnicalSheet(productId)
      .then((technicalSheetTemp: TechnicalSheet) => {
        technicalSheet.value = technicalSheetTemp;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const updateTechnicalSheet = async (
    productId: number,
    payload: TechnicalSheet,
    callBackUpdate: (technicalSheet: TechnicalSheet) => void
  ): Promise<void> => {
    await UpdateTechnicalSheet(productId, payload)
      .then((technicalSheet: TechnicalSheet) => {
        callBackUpdate(technicalSheet);
        useToast().successToast(t("technicalSheets.success.updated"));
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  return { technicalSheet, getTechnicalSheet, updateTechnicalSheet };
}
