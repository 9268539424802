import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row justify-content-center" }
const _hoisted_2 = { class: "col-12 d-flex align-items-center" }
const _hoisted_3 = { class: "textBlack" }
const _hoisted_4 = { class: "row justify-content-between mt-2 mb-1" }
const _hoisted_5 = { class: "col-auto" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }

import { useAuthServices } from "@/composables/useAuthServices";
import { useValidations } from "@/composables/useValidations";
import { ref } from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import AuthCard from "./AuthCard.vue";
import { ChangePasswordTemplate as changePasswordTemplate } from "./templates/forms/ChangePassword.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'ChangePassword',
  props: { temporalToken: { type: String } },
  emits: ["goToSlide"],
  setup(__props, { emit: __emit }) {

const props = __props;

const { changePassword } = useAuthServices();
const validations = useValidations();

const emit = __emit;

const formRef = ref();

const changePasswordForm = ref(
  changePasswordTemplate(submitChangePassword, validations)
);

function goToLogin(): void {
  formRef.value.resetForm({});
  emit("goToSlide", 1);
}

async function submitChangePassword(): Promise<void> {
  const { values } = formRef.value;
  const { newPassword, oldPassword } = values;
  await changePassword(
    { newPassword, oldPassword },
    props.temporalToken,
    goToLogin
  );
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AuthCard, null, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.$t("auth.carouselTabs.changePassword.primaryTitle")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _cache[0] || (_cache[0] = _createElementVNode("i", { class: "bi bi-arrow-left-circle actionableLabel mr-1" }, null, -1)),
          _createElementVNode("span", {
            class: "actionableLabel",
            onClick: goToLogin
          }, _toDisplayString(_ctx.$t("auth.carouselTabs.forgotPassword.hrefs.signUp")), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createVNode(GeneralForm, {
            ref_key: "formRef",
            ref: formRef,
            form: changePasswordForm.value,
            formName: "forgotPassword"
          }, null, 8, ["form"])
        ])
      ])
    ]),
    _: 1
  }))
}
}

})