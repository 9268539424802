import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col-12" }

import { useCustomersServices } from "@/composables/useCustomersServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { Customer } from "@/store/customers/models/Customer.model";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import {
  computed,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import { CreateOrUpdateCustomerTemplate } from "./templates/forms/CreateOrUpdateCustomer.template";


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateCustomer',
  props: {
  initialValues: { type: Object as PropType<Customer>, required: false },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const { customFields } = useCustomFieldsServices();
const { createCustomer, updateCustomer } = useCustomersServices();

const props = __props;

const initialValuesRef: Ref<Customer> = toRef(props, "initialValues");
const form = ref();
const formTemplateRef = ref();

const emit = __emit;

const currentCustomer = computed(() => {
  if (initialValuesRef.value) {
    const customer = formatValues(initialValuesRef.value);
    return customer ?? null;
  }
  return null;
});

function callBackCreate(customer: Customer) {
  emit("handleSubmit", { customer, isCreating: true });
}

function callBackEdit(customer: Customer) {
  emit("handleSubmit", {
    customer,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { customer } = form.value.values;
  if (!initialValuesRef.value) {
    await createCustomer(customer, callBackCreate);
  } else {
    await updateCustomer(customer, callBackEdit);
  }
}

function formatValues(values: Customer) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    id: values.id,
    name: values.name,
    email: values.email,
    customerType: values.customerType,
    businessType: values.businessType,
    state: values.state,
    city: values.city,
    customFields: customFieldsFiltered,
    metadata: values.metadata,
  };
}

watch(
  currentCustomer,
  async () => {
    formTemplateRef.value = CreateOrUpdateCustomerTemplate(
      customFields.value,
      handleSubmit,
      !currentCustomer.value
    );
    if (currentCustomer.value) {
      await nextTick();
      form.value?.setValues({ customer: currentCustomer.value });
    }
  },
  { deep: true, immediate: true }
);

__expose({
  resetForms: () => form.value?.resetAllFields(),
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(), _createBlock(GeneralForm, {
          ref_key: "form",
          ref: form,
          form: formTemplateRef.value,
          key: JSON.stringify(props.initialValues),
          "form-name": "customerForm"
        }, null, 8, ["form"]))
      ])
    ])
  ]))
}
}

})