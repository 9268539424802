import { translateButton } from "@/components/globals/buttons/templates/TranslateButton.template";
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import { LabelType } from "@/shared/globals/forms/types/LabelType.type";
import {
  minCharsYup,
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { CatalogClassesKeys } from "@/store/catalogs/const/CatalogClasses.const";
import { CatalogClass } from "@/store/catalogs/models/CatalogClass.model";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const CreateOrUpdateWorkplaceTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean,
  catalogs: CatalogClass[],
  handleTranslateButton: (
    title: LabelType,
    path: string,
    type: "text" | "textarea"
  ) => void
): Form => {
  const { lang } = useLanguageServices();
  const catalog =
    catalogs.find((catalog) => {
      return catalog.key === CatalogClassesKeys.WORKPLACE_STATUS_CLASS;
    }).catalogs ?? [];
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "workplace.name",
            inputName: "workplaceName",
            label: {
              value: "workplaces.entity.name",
              needsTranslate: true,
            },
            type: "text",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  { value: "workplaces.entity.name", needsTranslate: true },
                  "workplace.metadata.language.name",
                  "text"
                )
              ),
            ],
          },
          {
            path: "workplace.description",
            inputName: "workplaceDescription",
            label: {
              value: "workplaces.entity.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup, minCharsYup(5)];
            },
            buttons: [
              translateButton(() =>
                handleTranslateButton(
                  {
                    value: "workplaces.entity.description",
                    needsTranslate: true,
                  },
                  "workplace.metadata.language.description",
                  "textarea"
                )
              ),
            ],
          },
          {
            path: "workplace.status",
            inputName: "workplaceStatus",
            label: {
              value: "workplaces.entity.status",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              idKey: "id",
              labelKey: [
                `catalogMetadata.name.language.[${lang.value}]`,
                "name",
              ],
              isMultiple: false,
              initialOptions: catalog,
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "workplace.division",
            inputName: "workplaceDivision",
            label: {
              value: "workplaces.entity.division",
              needsTranslate: true,
            },
            type: "select",
            selectOptions: {
              dataSource: "/divisions/",
              idKey: "id",
              labelKey: [`metadata.language.name.${lang.value}`, "name"],
              isMultiple: false,
              searchPath: "name",
            },
            metadata: {
              tooltipPosition: "left",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating ? "workplaces.form.add" : "workplaces.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(customFields, "workplace", "workplace"),
    });
  }

  return form;
};
