import { createStore } from "vuex";
import { authStore } from "./auth/auth";
import { customersStore } from "./customers/customers";
import { measuresStore } from "./measures/measures";
import { modulesStore } from "./modules/modules";
import { ordersStore } from "./orders/orders";
import { permissionsStore } from "./permissions/permissions";
import { positionsStore } from "./positions/rawMaterials";
import { productFamiliesStore } from "./product-families/productFamilies";
import { productStore } from "./products/products";
import { profilesStore } from "./profiles/profiles";
import { rawMaterialsStore } from "./raw-materials/rawMaterials";
import { suppliersStore } from "./suppliers/supplier";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authStore,
    modules: modulesStore,
    profiles: profilesStore,
    permissions: permissionsStore,
    measures: measuresStore,
    rawMaterials: rawMaterialsStore,
    productFamilies: productFamiliesStore,
    products: productStore,
    suppliers: suppliersStore,
    customers: customersStore,
    positions: positionsStore,
    orders: ordersStore,
    stores: measuresStore,
  },
});
