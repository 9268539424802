import { useLanguageServices } from "@/composables/useLanguageServices";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { t } from "@/shared/locales/services/i18n.services";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";

export const CreateOrUpdateRawMaterialQuantityTemplate = (
  action: () => void,
  isCreating: boolean
): Form => {
  const { lang } = useLanguageServices();
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "rawMaterial",
            inputName: "rawMaterial",
            label: {
              value: "technicalSheets.entity.rawMaterial",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              dataSource: "/raw-materials/",
              idKey: "id",
              labelKey: [
                `metadata.language.description.${lang.value}`,
                "description",
              ],
              labelKeyMetadata: (item: RawMaterial) => {
                return ` (${item.materialType})`;
              },
              isMultiple: false,
              searchPath: "description",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
          {
            path: "quantity",
            inputName: "quantity",
            label: {
              value: "technicalSheets.entity.quantity",
              needsTranslate: true,
            },
            type: "number",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      text: {
        value: isCreating
          ? "technicalSheets.form.rawMaterial.add"
          : "technicalSheets.form.rawMaterial.update",
        needsTranslate: true,
      },
      action,
    },
  };
  return form;
};
