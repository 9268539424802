<template>
  <div class="row">
    <div class="col">
      <GeneralForm
        ref="workplaceFlowForm"
        :form="workplaceFlowFormTemplateRef"
        :key="props.productId"
        form-name="workplacesFlowForm"
      >
        <CreateOrUpdateStep :steps="steps" v-model="steps">
        </CreateOrUpdateStep>
      </GeneralForm>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useWorkplacesFlowsServices } from "@/composables/useWorkplacesFlowServices";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { WorkplaceFlowStep } from "@/store/workplaces-flow/interfaces/WorkplacesFlow.interface";
import { WorkplacesFlow } from "@/store/workplaces-flow/models/WorkplacesFlow.model";
import {
  computed,
  defineEmits,
  defineExpose,
  defineProps,
  nextTick,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateStep from "./CreateOrUpdateStep.vue";
import { CreateOrUpdateWorkplaceFlows } from "./templates/forms/CreateOrUpdateWorkplaceFlows.template";

const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("workplace_flows");

const props = defineProps({
  productId: { type: Number, required: true },
});

const { workplaceFlow, getWorkplacesFlow, updateWorkplacesFlow } =
  useWorkplacesFlowsServices(props.productId);

const workplaceFlowForm = ref();
const workplaceFlowFormTemplateRef = ref();
const steps: Ref<WorkplaceFlowStep[]> = ref([]);

const emit = defineEmits(["handleSubmit"]);

const currentWorkplacesFlow: Ref<WorkplacesFlow> = computed(() => {
  if (workplaceFlow.value) {
    const workplacesFlow = formatValues(workplaceFlow.value);
    return workplacesFlow ?? null;
  }
  return null;
});

function callBackEdit(workplacesFlow: WorkplacesFlow) {
  emit("handleSubmit", {
    workplacesFlow,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { workplaceFlow } = workplaceFlowForm.value.values;
  /*
  const stepsUpdated = steps.value.map((step, index) => {
    return { ...step, step: index + 1 };
  });
  */
  await updateWorkplacesFlow(
    props.productId,
    { ...workplaceFlow, steps: steps.value },
    callBackEdit
  );
}

function formatValues(values: WorkplacesFlow) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  return {
    product: { id: props.productId },
    steps:
      values.steps
        ?.map((step) => {
          const { workplace, ...stepProps } = step;
          const { id } = workplace;
          return {
            workplace: { id, label: translateFromKey(workplace, "name") },
            ...stepProps,
          };
        })
        .sort((a, b) => a.step - b.step) ?? [],
    customFields: customFieldsFiltered,
  };
}

onMounted(async () => {
  await Promise.all([getModuleCustomFields(), getWorkplacesFlow()]);
  steps.value = currentWorkplacesFlow.value?.steps;
  watch(
    [customFields, steps],
    async () => {
      workplaceFlowFormTemplateRef.value = CreateOrUpdateWorkplaceFlows(
        customFields.value,
        handleSubmit
      );
      await nextTick();
      workplaceFlowForm.value?.setValues({
        workplaceFlow: currentWorkplacesFlow.value,
      });
    },
    { deep: true, immediate: true }
  );
});

defineExpose({
  resetForms: () => workplaceFlowForm.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
