<template>
  <div>
    <p>
      <span class="fw-bold bg-secondary text-white p-1 rounded">
        {{ detail.product.label }}
      </span>
    </p>
    <p class="row mt-1">
      <class class="col">
        <p class="fw-bold m-0">{{ detail.quantity }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.quantity") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ detail.discount }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.discount") }}</p>
      </class>
      <class class="col">
        <p class="fw-bold m-0">{{ subtotal }}</p>
        <p class="m-0">{{ $t("orderDetail.entity.subtotal") }}</p>
      </class>
    </p>
  </div>
</template>

<script lang="ts" setup>
import { Product } from "@/store/products/models/Product.model";
import { PropType, computed, defineProps, toRef } from "vue";

type Detail = {
  product: { id: number; label?: string; item: Product; items: Product[] };
  discount: number;
  quantity: number;
};

const props = defineProps({
  detail: { type: Object as PropType<Detail>, required: true },
});
const detailRef = toRef(props, "detail");
const subtotal = computed(() => {
  return (
    (detailRef.value.quantity *
      detailRef.value.product.item.price *
      (100 - detailRef.value.discount)) /
    100
  );
});
</script>

<style lang="scss"></style>
