import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { class: "col-12" }
const _hoisted_5 = { class: "row" }
const _hoisted_6 = { class: "col-6" }
const _hoisted_7 = { class: "row g-2" }
const _hoisted_8 = { class: "col-12 d-flex justify-content-start" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "row g-2" }
const _hoisted_11 = { class: "col-12 d-flex justify-content-end" }

import CustomFields from "@/components/customFields/CustomFields.vue";
import GeneralOffcanvas from "@/components/globals/offcanvas/GeneralOffcanvas.vue";
import GeneralTable from "@/components/globals/tables/GeneralTable.vue";
import InventoryTransactionForm from "@/components/inventories/InventoryTransactionForm.vue";
import { InventoriesHeader } from "@/components/inventories/templates/headers/Inventory.headers";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { ProductsInventory } from "@/store/inventories/models/ProductsInventory.model";
import { computed, onMounted, Ref, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

type FormsTypes = "insert" | "dispatch" | "modify" | "customField";


export default /*@__PURE__*/_defineComponent({
  __name: 'InventoryView',
  setup(__props) {

const { getModuleCustomFields, customFields } = useCustomFieldsServices();
const { t } = useI18n();
const route = useRoute();

const form = ref();
const tableRef = ref();
const offCanvasRef = ref();
const formType: Ref<FormsTypes> = ref();
const lastRowIndex: Ref<number> = ref();
const action: Ref<"modify"> = ref();
const tableFilters = ref([]);
const headerTable = ref();
const tempRow: Ref<ProductsInventory | null> = ref();

const moduleName: string = route.meta.moduleName as string;

const isProductModule = computed(() => {
  return moduleName !== "raw_materials_inventory";
});

const dataSource = computed(() => {
  if (isProductModule.value) {
    return "/products-inventories/";
  }
  return "/raw-materials-inventories/";
});

const title = computed(() => {
  if (isProductModule.value) {
    return "inventories.productsTitle";
  }
  return "inventories.rawMaterialsTitle";
});

const offCanvasTitle = computed(() => {
  if (formType.value === "customField") {
    return "customFields.title";
  } else if (formType.value === "insert") {
    if (isProductModule.value) {
      return "inventories.form.insertProductTitle";
    } else {
      return "inventories.form.insertRawMaterialTitle";
    }
  } else if (formType.value === "dispatch") {
    if (isProductModule.value) {
      return "inventories.form.dispatchProductTitle";
    } else {
      return "inventories.form.dispatchRawMaterialTitle";
    }
  } else if (formType.value === "modify") {
    if (isProductModule.value) {
      return "inventories.form.dispatchProductTitle";
    } else {
      return "inventories.form.dispatchRawMaterialTitle";
    }
  }
  return "";
});

const customFieldsClosed = () => {
  return form.value.resetForms && form.value.resetForms();
};

function handleSubmit(data) {
  const { inventories } = data;
  inventories.map((inventory) => {
    tableRef.value.addOrUpdateRow("id", inventory);
  });
  offCanvasRef.value.closeOffCanvas();
}

function runAction(
  actionType: "modify" | "dispatch",
  row: ProductsInventory,
  index: number
) {
  if (actionType === "modify") {
    openOffcanvasForm("modify");
  } else {
    openOffcanvasForm("dispatch");
  }
  action.value = actionType;
  tempRow.value = { ...row };
  lastRowIndex.value = index;
}

function openOffcanvasForm(newFormType: FormsTypes) {
  tempRow.value = null;
  formType.value = newFormType;
  offCanvasRef.value.openOffCanvas();
}

onMounted(async () => {
  await getModuleCustomFields();
  watch(
    customFields,
    () => {
      headerTable.value = InventoriesHeader(
        runAction,
        customFields.value,
        isProductModule.value
      );
    },
    { immediate: true, deep: true }
  );
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t(title.value)), 1)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn btn-primary mr-2",
                  onClick: _cache[0] || (_cache[0] = () => openOffcanvasForm('insert'))
                }, [
                  _cache[2] || (_cache[2] = _createElementVNode("i", { class: "bi bi-person-plus me-2" }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t("inventories.form.insert")), 1)
                ])
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("div", _hoisted_11, [
                _createElementVNode("button", {
                  type: "button",
                  class: "btn ml-1 btn-gear",
                  onClick: _cache[1] || (_cache[1] = () => openOffcanvasForm('customField'))
                }, _cache[3] || (_cache[3] = [
                  _createElementVNode("i", { class: "bi bi-gear" }, null, -1)
                ]))
              ])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(GeneralOffcanvas, {
      canvasId: "customFieldCanvas",
      ref_key: "offCanvasRef",
      ref: offCanvasRef,
      canvasName: _ctx.$t(offCanvasTitle.value),
      onClosed: customFieldsClosed,
      initialWidth: 30
    }, {
      content: _withCtx(() => [
        (formType.value === 'customField')
          ? (_openBlock(), _createBlock(CustomFields, {
              key: 0,
              ref_key: "form",
              ref: form
            }, null, 512))
          : (['insert', 'dispatch', 'modify'].includes(formType.value))
            ? (_openBlock(), _createBlock(InventoryTransactionForm, {
                key: 1,
                ref_key: "form",
                ref: form,
                "initial-values": tempRow.value,
                "transaction-type": formType.value,
                onHandleSubmit: handleSubmit
              }, null, 8, ["initial-values", "transaction-type"]))
            : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["canvasName"]),
    (headerTable.value)
      ? (_openBlock(), _createBlock(GeneralTable, {
          key: 0,
          dataSource: dataSource.value,
          filters: tableFilters.value,
          headers: headerTable.value,
          name: "usersTable",
          ref_key: "tableRef",
          ref: tableRef
        }, null, 8, ["dataSource", "filters", "headers"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})