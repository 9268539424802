import { SupplierInterface } from "../interfaces/Supplier.interface";

export class SupplierDto implements SupplierInterface {
  id = 0;
  code = "";
  name = "";
  supplierType = "";
  businessType = "";
  state = "";
  city = "";
  active = false;
  createdAt = new Date();
  updatedAt = new Date();
  customFields = null;
  metadata = null;
}

export class Supplier extends SupplierDto {
  constructor(supplierDto: SupplierDto) {
    super();
    Object.assign(this, supplierDto);
  }
}
