import axiosInstance from "@/middlewares/Routing.middleware";
import { jsonToFormData } from "@/shared/globals/helpers/Form.helper";
import { TechnicalSheet } from "../models/TechnicalSheet.model";

export async function GetTechnicalSheet(
  productId: number
): Promise<TechnicalSheet> {
  return (await axiosInstance.get(`technical-sheets/${productId}`)).data;
}

export async function UpdateTechnicalSheet(
  productId: number,
  payload: TechnicalSheet
): Promise<TechnicalSheet> {
  const formData = jsonToFormData(payload);
  return (await axiosInstance.put(`technical-sheets/${productId}`, formData))
    .data;
}
