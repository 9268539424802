import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import InputMaster from "@/components/globals/inputs/InputMaster.vue";
import { FieldType } from "@/shared/globals/forms/types/FieldType.type";
import { generateFilterField } from "@/shared/globals/tables/helpers/tables.helper";
import { get } from "lodash";
import { FieldContext } from "vee-validate";
import {
  PropType,
  Ref,
  computed,
  onMounted,
  ref,
  useTemplateRef,
  watch,
} from "vue";
import { TableHeader } from "../../../shared/globals/tables/interfaces/TableHeader.interface";


export default /*@__PURE__*/_defineComponent({
  __name: 'ColumnFilter',
  props: {
  columnHeader: { type: Object as PropType<TableHeader>, required: true },
},
  emits: ["applyFilters"],
  setup(__props, { emit: __emit }) {

const props = __props;
const value: Ref<{ value: any; type: FieldType }> = ref();
const emit = __emit;

const inputData = computed(() => {
  return {
    ...generateFilterField(props.columnHeader),
    disabledDefaultOption: false,
  };
});

const inputRef = useTemplateRef<FieldContext>(inputData.value.inputName);

const inputValue = computed(() => {
  if (["select", "multiple-select"].includes(props.columnHeader.filterType)) {
    return get(value, "value.id", null);
  }
  return get(value, "value", null);
});

const path = computed(() => {
  if (props.columnHeader.isCustomField) {
    return `customFields.${props.columnHeader.mappedKey}`;
  }
  return props.columnHeader.key;
});

watch(inputValue, () => {
  if (
    ["select", "multiple-select", "date"].includes(
      props.columnHeader.filterType
    )
  ) {
    applyFilter(true);
  } else if (
    ["text"].includes(props.columnHeader.filterType) &&
    inputValue.value === ""
  ) {
    applyFilter(true);
  }
});

function applyFilter(forced?: boolean) {
  if (![undefined, null, ""].includes(inputValue.value) || forced) {
    emit("applyFilters", {
      value: inputValue.value,
      path: path.value,
    });
  }
}

onMounted(() => {
  inputRef.value?.setValue("");
});

return (_ctx: any,_cache: any) => {
  return (__props.columnHeader.filterType)
    ? (_openBlock(), _createBlock(InputMaster, {
        key: 0,
        modelValue: value.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((value).value = $event)),
        onEnter: _cache[1] || (_cache[1] = () => applyFilter(true)),
        field: inputData.value,
        reachableValue: true,
        ref: inputData.value.inputName
      }, null, 8, ["modelValue", "field"]))
    : _createCommentVNode("", true)
}
}

})