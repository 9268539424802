<template>
  <div class="row mt-3">
    <div class="col-1"><hr /></div>
    <div class="col-auto p-0 dividerText d-flex align-items-center">
      <b class="m-0 fs-5">
        {{ t("workplacesFlows.form.title") }}
      </b>
    </div>
    <div class="col pl-1"><hr /></div>
  </div>
  <draggable v-model="stepsRef" group="people" item-key="step">
    <template #item="{ element, index }">
      <div class="card p-3 mb-3">
        <div class="row" :key="element.step">
          <div class="col text-start">
            <WorkplaceFlowStep :step="element"></WorkplaceFlowStep>
          </div>
          <div class="col-auto p-0">
            <ButtonField
              :button-field="
                editButton(() => {
                  handleAddOrEditStep(index);
                })
              "
            ></ButtonField>
          </div>
          <div class="col-auto pl-0">
            <ButtonField
              :button-field="
                deleteButton(() => {
                  stepsRef.splice(index, 1);
                })
              "
            ></ButtonField>
          </div>
        </div>
      </div>
    </template>
  </draggable>
  <div class="col-12 d-flex justify-content-center">
    <ButtonField :buttonField="addWorkplaceFlowButtonTemplate"></ButtonField>
  </div>
  <GeneralModal
    ref="modal"
    modalId="step"
    :modalName="modalTitle"
    data-bs-backdrop="false"
    class="p-4"
  >
    <GeneralForm
      ref="stepForm"
      :form="stepFormTemplateRef"
      form-name="workplacesFlowForm"
    >
    </GeneralForm>
  </GeneralModal>
</template>

<script lang="ts" setup>
import { WorkplaceFlowStep as WorkplaceFlowStepInterface } from "@/store/workplaces-flow/interfaces/WorkplacesFlow.interface";
import { merge } from "lodash";
import {
  defineExpose,
  defineProps,
  nextTick,
  PropType,
  Ref,
  ref,
  toRef,
  computed,
  watch,
  defineEmits,
} from "vue";
import { useI18n } from "vue-i18n";
import ButtonField from "../globals/buttons/ButtonField.vue";
import { addButton } from "../globals/buttons/templates/AddButton.template";
import { deleteButton } from "../globals/buttons/templates/DeleteButton.template";
import { editButton } from "../globals/buttons/templates/EditButton.template";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import GeneralModal from "../globals/modals/GeneralModal.vue";
import { CreateOrUpdateStepTemplate } from "./templates/forms/CreateOrUpdateStep.template";
import WorkplaceFlowStep from "./WorkplaceFlowStep.vue";
import draggable from "vuedraggable";

const { t } = useI18n();

const props = defineProps({
  steps: {
    type: Object as PropType<WorkplaceFlowStepInterface[]>,
    required: true,
  },
});

const stepForm = ref();
const stepFormTemplateRef = ref();
const modal = ref();
const modalTitle = ref();
const steps: Ref<WorkplaceFlowStepInterface[]> = toRef(props, "steps");
const stepsRef: Ref<WorkplaceFlowStepInterface[]> = ref([]);
const addWorkplaceFlowButtonTemplate = ref(
  addButton(async () => {
    await handleAddOrEditStep(stepsRef.value.length);
  })
);
const excludeOptions = computed(() => {
  return stepsRef.value.map(({ workplace }) => {
    return workplace;
  });
});
const emit = defineEmits(["update:modelValue"]);

async function handleAddOrEditStep(index: number) {
  const isCreating = stepsRef.value.length <= index;
  stepForm.value?.resetForm();
  modalTitle.value = isCreating
    ? t("workplacesFlows.form.addStep")
    : t("workplacesFlows.form.updateStep");
  stepFormTemplateRef.value = CreateOrUpdateStepTemplate(
    () => handleSubmitStepForm(index),
    isCreating
  );
  stepFormTemplateRef.value.sections[0].fields[0].selectOptions.excludeOptions =
    excludeOptions.value;
  if (!isCreating) {
    await nextTick();
    stepForm.value.setValues(stepsRef.value[index]);
  }
  modal.value.openModal();
}

async function handleSubmitStepForm(index: number) {
  const { values } = stepForm.value;
  stepsRef.value[index] = merge({}, stepsRef.value[index] ?? {}, values, {
    step: index + 1,
  });
  modal.value.closeModal();
}

watch(
  steps,
  () => {
    stepsRef.value = steps.value;
  },
  { deep: true }
);

watch(
  stepsRef,
  () => {
    const newSteps = stepsRef.value.map((step, index) => {
      return { ...step, step: index + 1 };
    });
    if (JSON.stringify(steps.value) !== JSON.stringify(newSteps)) {
      emit("update:modelValue", newSteps);
    }
  },
  { deep: true }
);
defineExpose({
  resetForms: () => stepForm.value?.resetAllFields(),
});
</script>

<style lang="scss" scoped>
::v-deep iframe {
  width: 100%;
  height: 300px;
}
.modal {
  position: absolute;
  background-color: $BackgroundModal;
}
</style>
