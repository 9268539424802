<template>
  <div v-if="debug">
    <div v-if="true">value: {{ value }}</div>
    <div v-if="false">Options: {{ options }}</div>
    <br />
    <div v-if="false">initialOptions: {{ initialOptionsRef }}</div>
    <div v-if="false">initialValue: {{ initialValueRef }}</div>
  </div>
  <v-select
    :options="options"
    :filterable="false"
    :multiple="isMultiple"
    @close="onClose"
    @search="onSearch"
    :class="`p-0 ${classList} w-100`"
    v-model="value"
    :selectable="isSelectable"
    :disabled="disabled"
    :closeOnSelect="!isMultiple"
    class=""
    ref="selector"
    @open="onOpen"
  >
    <template #list-footer>
      <li v-show="hasNextPage" ref="load" class="loader"></li>
    </template>
    <template #no-options>
      <span ref="noOptions"> {{ $t("global.inputs.noOptions") }} </span>
    </template>
  </v-select>
</template>

<script lang="ts" setup>
import { useTablesServices } from "@/composables/useTablesServices";
import { Field } from "@/shared/globals/forms/interfaces/Field.interface";
import { TableHeader } from "@/shared/globals/tables/interfaces/TableHeader.interface";
import { get } from "lodash";
import {
  computed,
  defineEmits,
  defineProps,
  nextTick,
  onMounted,
  PropType,
  ref,
  Ref,
  toRef,
  toRefs,
  watch,
} from "vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

type OptionType = { id: string; label: string; item?: any; items?: any[] };

const isSelectable = (option: OptionType): boolean => {
  if (!option) {
    return false;
  }
  const index =
    field.value.selectOptions.excludeOptions?.findIndex((opt) => {
      return opt.id === option.id;
    }) ?? -1;
  if (index > -1) {
    return false;
  }
  if (Array.isArray(value.value)) {
    return !value.value.find((opt) => opt.id === option.id);
  }
  return value.value?.id !== option.id;
};
const emits = defineEmits(["update:modelValue"]);
const props = defineProps({
  field: {
    type: Object as PropType<Field>,
    required: true,
  },
  initialValue: {
    type: Object as PropType<any>,
    required: true,
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
  isValidClass: {
    type: Object as PropType<"" | "is-valid" | "is-invalid">,
    required: true,
  },
});

const offset = 6;
const field: Ref<Field> = toRef(props, "field");
const isValidClassRef = toRef(props, "isValidClass");
const initialValueRef: Ref<any> = toRef(props, "initialValue");
const observer = ref();
const load = ref();
const items = ref([]);
const tableProps = ref();
const orderBy = ref("id");
const orderType: Ref<"ASC" | "DESC"> = ref("DESC");
const value: Ref<OptionType | Array<OptionType>> = ref();
const selector = ref();
const isFocused = ref(false);

const classList = computed(() => {
  return `${isFocused.value ? "selector" : ""} ${isValidClassRef.value} ${
    isFocused.value && isValidClassRef.value === "is-invalid"
      ? "is-invalid-focused"
      : ""
  }
  ${isValidClassRef.value} ${
    isFocused.value && isValidClassRef.value === "is-valid"
      ? "is-valid-focused"
      : ""
  }
  `;
});

const dataSourceRef = computed(() => {
  return field.value.selectOptions.dataSource;
});

const idKey = computed(() => {
  return field.value.selectOptions.idKey;
});

const labelKey: Ref<string | string[]> = computed(() => {
  return field.value.selectOptions.labelKey;
});

const isMultiple: Ref<boolean> = computed(() => {
  return field.value.selectOptions.isMultiple;
});

const initialOptionsRef: Ref<any> = computed(() => {
  return field.value?.selectOptions?.initialOptions ?? [];
});

const disabled: Ref<boolean> = computed(() => {
  return field.value.disabled;
});

const headers: Ref<TableHeader[]> = ref([
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "id",
    mappedKey: idKey.value,
    columnType: "number",
    width: "0px",
    filterType: "text",
  },
  {
    sortable: true,
    value: { value: "", needsTranslate: false },
    key: "label",
    mappedKey: labelKey.value,
    columnType: "text",
    width: "0px",
    filterType: "text",
  },
]);

const options: Ref<OptionType[]> = computed(() => {
  return items.value.map((item) => {
    return formatElement(item);
  });
});

async function onClose() {
  observer.value?.disconnect();
  isFocused.value = false;
}

async function onSearch(query: string, loading) {
  loading(true);
  if (dataSourceRef.value) {
    const { currentPage, filters, applyFilters, currentData } = toRefs(
      tableProps.value
    );
    currentPage.value = 1;
    filters.value = {};
    if (query.length > 2) {
      applyFilters.value({
        value: `${query}`,
        path: field.value.selectOptions.searchPath,
      });
      await getElementList();
      items.value = currentData.value;
    } else if (query.length === 0) {
      await getElementList();
      items.value = currentData.value;
    }
  } else {
    if (query.length > 0) {
      items.value = items.value.filter((item) => {
        const itemFormatted = formatElement(item);
        return itemFormatted.label
          .toLowerCase()
          ?.startsWith(query.toLowerCase());
      });
    } else {
      items.value = initialOptionsRef.value;
    }
  }
  loading(false);
}

function hasNextPage() {
  const { currentPage, totalPages } = toRefs(tableProps.value);
  return currentPage.value <= totalPages.value;
}

async function infiniteScroll(scrollProps) {
  const [{ isIntersecting, target }] = scrollProps;
  if (isIntersecting) {
    const ul = target.offsetParent;
    const scrollTop = target.offsetParent.scrollTop;
    await getElementList();
    await nextTick();
    ul.scrollTop = scrollTop;
  }
}

async function getElementList(forceLoad = false) {
  const { currentData, getTableData, currentPage } = toRefs(tableProps.value);
  if (hasNextPage() || forceLoad) {
    await getTableData.value();
    if (currentPage.value === 1) {
      items.value = [];
    }
    items.value = [...items.value, ...currentData.value];
    currentPage.value++;
  }
}

function formatElement(item: any): OptionType {
  if (isNotValidItemOrItems(item)) {
    let itemFormatted;
    if (item) {
      if (Array.isArray(labelKey.value)) {
        for (const key of labelKey.value) {
          itemFormatted = {
            id: get(item, idKey.value, false),
            label: get(item, key, false),
          };
          if (itemFormatted.id && itemFormatted.label) {
            break;
          }
        }
      } else {
        itemFormatted = {
          id: get(item, idKey.value, false),
          label: get(item, labelKey.value, false),
        };
      }
      itemFormatted = { ...itemFormatted, item, items: items.value };
      itemFormatted.label =
        itemFormatted.label +
        (field.value.selectOptions.labelKeyMetadata?.(item) ?? "");
      return itemFormatted ?? { id: "", label: "" };
    }
    return { id: "", label: "" };
  }
  return item;
}

const isNotValidItemOrItems = (item: any | any[]) => {
  if (item) {
    if (Array.isArray(item)) {
      return item.some((item) => {
        return Object.keys(item) && !(item && "label" in item && "id" in item);
      });
    }
    return Object.keys(item) && !(item && "label" in item && "id" in item);
  }
  return false;
};

const onOpen = () => {
  isFocused.value = true;
};

watch(
  initialValueRef,
  () => {
    if (isNotValidItemOrItems(initialValueRef.value)) {
      if (Array.isArray(initialValueRef.value)) {
        value.value = initialValueRef.value.map((item) => {
          return formatElement(item);
        });
      } else {
        value.value = formatElement(initialValueRef.value);
      }
    } else if (
      JSON.stringify(value.value) !== JSON.stringify(initialValueRef.value)
    ) {
      value.value = initialValueRef.value;
    }
  },
  { deep: true }
);

watch(
  [load],
  async () => {
    if (load.value && observer.value) {
      await nextTick();
      observer.value?.observe(load.value);
    }
  },
  { immediate: true, deep: true }
);

watch(value, () => {
  if (value.value === null) {
    emits("update:modelValue", "");
  } else {
    emits("update:modelValue", value.value);
  }
});

onMounted(async () => {
  items.value = initialOptionsRef.value;
  if (dataSourceRef.value) {
    tableProps.value = useTablesServices(
      dataSourceRef.value,
      headers,
      orderBy,
      orderType,
      offset
    );
    await getElementList(true);
    observer.value = new IntersectionObserver(infiniteScroll);
  }
});
</script>

<style lang="scss" scoped>
::v-deep(ul) {
  max-height: 150px !important;
}

.selector {
  border-color: #86b7fe;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;
  border-radius: var(--bs-border-radius);
  background-color: var(--bs-body-bg);
  --vs-controls-color: #dee2e6;
  --vs-border-color: #dee2e6;
  --vs-border-radius: var(--bs-border-radius);
}

.is-valid {
  --vs-controls-color: #198754;
  --vs-border-color: #198754;
  --vs-border-radius: var(--bs-border-radius);
}

.is-invalid {
  --vs-controls-color: #dc3545;
  --vs-border-color: #dc3545;
  --vs-border-radius: var(--bs-border-radius);
}

.is-valid-focused {
  --vs-border-radius: var(--bs-border-radius);
  box-shadow: 0 0 0 0.25rem #c5e1d4;
}

.is-invalid-focused {
  --vs-border-radius: var(--bs-border-radius);
  box-shadow: 0 0 0 0.25rem #f6ccd0;
}
</style>

<style lang="scss"></style>
