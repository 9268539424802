import router from "@/router";
import {
  showToastError,
  showToastInfo,
} from "@/shared/globals/helpers/Toast.helper";
import { BaseUrl } from "@/shared/http/consts/baseUrl.const";
import { REQ_RES_CODES } from "@/shared/http/enums/request-response-codes.enum";
import { ChangePasswordDto } from "@/store/auth/dtos/ChangePassword.dto";
import { ConfirmForgotPasswordDto } from "@/store/auth/dtos/ConfirmForgotPassword.dto";
import { ForgotPasswordDto } from "@/store/auth/dtos/ForgotPassword.dto";
import { GetAccountDto } from "@/store/auth/dtos/GetAccount.dto";
import { ResendValidateEmailDto } from "@/store/auth/dtos/ResendValidateEmail.dto";
import { ValidateEmailDto } from "@/store/auth/dtos/ValidateEmail.dto";
import { AccountInterface } from "@/store/auth/interfaces/Account.interface";
import { UserInterface } from "@/store/auth/interfaces/User.interface";
import {
  ChangePassword,
  ConfirmForgotPassword,
  ForgotPassword,
  GetAccount,
  GetUser,
  Login,
  ResendValidateEmail,
  ValidateEmail,
} from "@/store/auth/services/Login.service";
import { useStorage } from "@vueuse/core";
import { t } from "../shared/locales/services/i18n.services";
import { LoginDto } from "../store/auth/dtos/Login.dto";
import { useToast } from "./useToastServices";

interface Auth {
  account?: AccountInterface;
  user?: UserInterface;
  token?: string;
  refreshToken?: string;
  launchExpiredSessionToast: boolean;
}
export function useAuthServices() {
  const alias = "auth";

  const auth = useStorage<Auth>("user", {
    account: null,
    user: null,
    token: null,
    refreshToken: null,
    launchExpiredSessionToast: false,
  });

  const login = async (
    login: LoginDto,
    cbGoForgotPass: () => void,
    cbGoChangePass: (token: string) => void
  ): Promise<void> => {
    await Login(login)
      .then(async ({ data }) => {
        setAuthToken({
          token: data.accessToken,
          refreshToken: data.refreshToken,
        });
        await getUser();
        router.push({ name: "Main" });
      })
      .catch(({ response }) => {
        if (response.messageCode === REQ_RES_CODES.PASSWORD_EXPIRED) {
          showToastInfo(alias, response.messageCode);
          cbGoForgotPass();
        } else if (
          response.messageCode === REQ_RES_CODES.NEED_CHANGE_PASSWORD
        ) {
          showToastInfo(alias, response.messageCode);
          cbGoChangePass(response.accessToken);
        } else {
          showToastError(alias, response.messageCode);
        }
      });
  };

  const logout = () => {
    auth.value = {
      account: null,
      user: null,
      token: null,
      refreshToken: null,
      launchExpiredSessionToast: false,
    };
    router.push({ name: "Home" });
  };

  const getAccount = (payload: GetAccountDto) => {
    let url = payload.url.replace("www.", "");
    if (url === "localhost") {
      url = BaseUrl;
    }
    GetAccount({ url })
      .then(({ data }) => {
        auth.value.account = data;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const getUser = async () => {
    await GetUser()
      .then(({ data }) => {
        auth.value.user = data;
      })
      .catch(({ response }) => {
        showToastError("users", response.messageCode);
      });
  };

  const validateEmail = async (
    validationData: ValidateEmailDto,
    goToLogin?: () => void
  ): Promise<void> => {
    await ValidateEmail(validationData)
      .then(() => {
        useToast().successToast(t("auth.success.validation"));
        if (goToLogin) {
          goToLogin();
        }
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
        if (response.messageCode === REQ_RES_CODES.NOT_AUTHORIZED) {
          goToLogin?.();
        }
      });
  };

  const resendValidateEmail = async (
    payload: ResendValidateEmailDto
  ): Promise<boolean> => {
    return await ResendValidateEmail(payload)
      .then(() => {
        useToast().successToast(t("auth.success.resendCode"));
        return true;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
        return false;
      });
  };

  const forgotPassword = async (
    forgotPassData: ForgotPasswordDto
  ): Promise<boolean> => {
    const valor = await ForgotPassword(forgotPassData)
      .then(() => {
        useToast().successToast(t("auth.success.resendCode"));
        return true;
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
        return false;
      });
    return valor;
  };

  const confirmForgotPassword = async (
    confirmForgotPassData: ConfirmForgotPasswordDto,
    goToLogin?: () => void
  ): Promise<void> => {
    confirmForgotPassData.confirmationCode =
      confirmForgotPassData.confirmationCode.toString();
    await ConfirmForgotPassword(confirmForgotPassData)
      .then(() => {
        useToast().successToast(t("auth.success.changePassword"));
        if (goToLogin) {
          goToLogin();
        }
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  const changePassword = async (
    changePasswordDto: ChangePasswordDto,
    token: string,
    goToLogin?: () => void
  ): Promise<void> => {
    await ChangePassword(changePasswordDto, token)
      .then(() => {
        useToast().successToast(t("auth.success.changePassword"));
        if (goToLogin) {
          goToLogin();
        }
      })
      .catch(({ response }) => {
        showToastError(alias, response.messageCode);
      });
  };

  function setAuthToken({
    token,
    refreshToken,
  }: {
    token: string;
    refreshToken?: string;
  }): void {
    auth.value.token = token;
    if (refreshToken) {
      auth.value.refreshToken = refreshToken;
    }
  }

  return {
    auth,
    login,
    getAccount,
    getUser,
    validateEmail,
    forgotPassword,
    confirmForgotPassword,
    resendValidateEmail,
    logout,
    changePassword,
    setAuthToken,
  };
}
