<template>
  <InventoryView :key="routeKey"></InventoryView>
</template>

<script lang="ts" setup>
import { computed, Ref } from "vue";
import { useRoute } from "vue-router";
import InventoryView from "./InventoryView.vue";
const route = useRoute();
const routeKey: Ref<string> = computed(
  () => (route.fullPath || route.meta.moduleName) as string
);
</script>
