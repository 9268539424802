<template>
  <button
    :class="`input-group-text ${(buttonField as ButtonTemplate).customClass}`"
    type="button"
    :style="{
      backgroundColor: (buttonField as ButtonTemplate).backgroundColor,
    }"
    @click.prevent="action"
    :disabled="(props.buttonField as ButtonTemplate)?.isDisabled ?? false"
  >
    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      style="color: white"
      role="status"
      aria-hidden="true"
    ></span>
    <div v-else>
      <ProcessText
        style="color: white"
        :value="(buttonField as ButtonTemplate).text"
      >
      </ProcessText>
      <i
        v-if="(buttonField as ButtonTemplate).graphic?.icon"
        :class="(buttonField as ButtonTemplate).graphic.icon.icon"
        :style="{
        color: (buttonField as ButtonTemplate).graphic.icon.color,
        background: (buttonField as ButtonTemplate).graphic.icon.backgroundColor,
      }"
      ></i>
    </div>
  </button>
</template>

<script lang="ts" setup>
import { ButtonTemplate } from "@/shared/globals/forms/interfaces/ButtonField.interface";
import { defineProps, PropType, Ref, ref } from "vue";
import ProcessText from "../UiTools/ProcessText.vue";

const props = defineProps({
  buttonField: {
    type: Object as PropType<ButtonTemplate>,
    required: true,
  },
});

const isLoading: Ref<boolean> = ref(false);

async function action() {
  isLoading.value =
    true && (props.buttonField as ButtonTemplate).metadata?.requireSpinner;
  if ((props.buttonField as ButtonTemplate).action) {
    await (props.buttonField as ButtonTemplate).action();
  }
  isLoading.value = false;
}
</script>

<style lang="scss"></style>
