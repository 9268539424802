import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = {
  key: 0,
  class: "row"
}
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12" }

import { useCatalogsServices } from "@/composables/useCatalogsServices";
import { useCustomFieldsServices } from "@/composables/useCustomFieldsServices";
import { useTechnicalSheetsServices } from "@/composables/useTechnicalSheetsServices";
import { translateFromKey } from "@/shared/globals/translates/translate.helper";
import { t } from "@/shared/locales/services/i18n.services";
import { filterCustomField } from "@/store/customFields/helpers/FilterCustomField.helper";
import { RawMaterial } from "@/store/raw-materials/models/RawMaterial.model";
import { TechnicalSheet } from "@/store/technical-sheets/models/TechnicalSheet.model";
import { CustomFieldValues } from "@/store/users/interfaces/CreateOrUpdateUserInterface.interface";
import { merge } from "lodash";
import {
  computed,
  nextTick,
  onMounted,
  Ref,
  ref,
  watch,
} from "vue";
import GeneralForm from "../globals/forms/GeneralForm.vue";
import CreateOrUpdateRawMaterialQuantity from "./CreateOrUpdateRawMaterialQuantity.vue";
import {
  CreateOrUpdateTechnicalSheetTemplate,
  GetManufactureSpecificationFieldTemplate,
  GetQualityCriteriaFieldTemplate,
} from "./templates/forms/CreateOrUpdateTechnicalSheet.template";

type RawMaterialQuantity = {
  rawMaterial: { id: number; label?: string; items: RawMaterial[] };
  quantity: number;
};

type PreManufacturedProduct = {
  preManufacturedProduct: { id: number; label?: string };
  quantity: number;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'CreateOrUpdateTechnicalSheet',
  props: {
  productId: { type: Number, required: true },
},
  emits: ["handleSubmit"],
  setup(__props, { expose: __expose, emit: __emit }) {

const props = __props;

const { technicalSheet, getTechnicalSheet, updateTechnicalSheet } =
  useTechnicalSheetsServices(props.productId);
const { catalogs, getAllCatalogs } = useCatalogsServices();
const { customFields, getModuleCustomFields } =
  useCustomFieldsServices("technical_sheets");

const form = ref();
const formTemplateRef = ref();
const rawMaterials: Ref<RawMaterialQuantity[]> = ref([]);

const emit = __emit;

const currentTechnicalSheet: Ref<{
  product: {
    id: number;
  };
  descriptiveImages: { url: string; file?: File; isUrl: boolean }[];
  rawMaterials: { rawMaterial: RawMaterial; quantity: number }[];
  preManufacturedProducts: {
    rawMaterial: PreManufacturedProduct;
    quantity: number;
  }[];
  instructions: {
    images: { url: string; file?: File; isUrl: boolean }[];
    description: string;
  };
  materialType: {
    id: string;
    label: string;
  };
  manufactureSpecification: {
    images: { url: string; file?: File; isUrl: boolean }[];
    details: {
      [key: string]: string;
    };
  };
  qualityCriteria: {
    orders: {
      [key: string]: string;
    };
    descriptions: {
      [key: string]: {
        [key: string]: string;
      };
    };
    observations: string;
  };
  customFields: Record<string, any>;
} | null> = computed(() => {
  if (technicalSheet.value) {
    return formatValues(technicalSheet.value) ?? null;
  }
  return null;
});

function callBackEdit(technicalSheet: TechnicalSheet) {
  emit("handleSubmit", {
    technicalSheet,
    isCreating: false,
  });
}

async function handleSubmit() {
  const { values } = form.value;
  const { technicalSheet } = merge({}, values, rawMaterials.value);
  const {
    materialType,
    instructions,
    manufactureSpecification,
    qualityCriteria,
    descriptiveImages,
    ...technicalSheetProps
  } = technicalSheet;
  const technicalSheetFormatted = {
    ...technicalSheetProps,
    materialType: materialType.id,
  };
  technicalSheetFormatted.manufactureSpecification = {
    images:
      manufactureSpecification?.images?.map((image) => {
        if (image.isUrl) {
          return image.url;
        }
        return image.file;
      }) ?? [],
    details: Object.keys(manufactureSpecification?.details ?? {}).map(
      (detailKey) => {
        return manufactureSpecification.details[detailKey];
      }
    ),
  };
  technicalSheetFormatted.descriptiveImages =
    descriptiveImages?.map((image) => {
      if (image.isUrl) {
        return image.url;
      }
      return image.file;
    }) ?? [];
  technicalSheetFormatted.instructions = {
    images:
      instructions?.images?.map((image) => {
        if (image.isUrl) {
          return image.url;
        }
        return image.file;
      }) ?? [],
    description: instructions.description,
  };
  technicalSheetFormatted.qualityCriteria = {
    qualityCriterias: Object.keys(qualityCriteria?.orders ?? {}).map(
      (orderKey, index) => {
        return {
          order: qualityCriteria.orders[orderKey],
          description: qualityCriteria.descriptions[`description${index}`],
        };
      }
    ),
    observations: qualityCriteria.observations,
  };
  technicalSheetFormatted.rawMaterials = rawMaterials.value.map(
    (rawMaterial) => ({
      id: rawMaterial.rawMaterial.id,
      quantity: rawMaterial.quantity,
    })
  );
  await updateTechnicalSheet(
    props.productId,
    technicalSheetFormatted,
    callBackEdit
  );
}

function formatValues(values: TechnicalSheet) {
  let customFieldsFiltered: CustomFieldValues = filterCustomField(
    values.customFields,
    customFields.value
  );
  const manufactureSpecification = {
    images:
      values?.product?.manufactureSpecification?.images?.map((url) => {
        return { url };
      }) ?? [],
    details: merge(
      {},
      ...(values?.product?.manufactureSpecification?.details?.map(
        (detail, index) => {
          return { [`detail${index}`]: detail };
        }
      ) ?? [])
    ),
  };
  const descriptiveImages =
    values?.product?.descriptiveImages.map((url) => {
      return { url };
    }) ?? [];
  const materialType = {
    id: values.product.materialType,
    label: t(`technicalSheets.type.${values.product.materialType}`),
  };
  values.product.instructions;
  const instructions = {
    description: values.product.instructions.description,
    images:
      values?.product?.instructions?.images?.map((url) => {
        return { url };
      }) ?? [],
  };
  const qualityCriteria = {
    orders: merge(
      {},
      ...(values?.product?.qualityCriteria?.qualityCriterias?.map(
        ({ order }, index) => {
          return { [`order${index}`]: order };
        }
      ) ?? [])
    ),
    descriptions: merge(
      {},
      ...(values?.product?.qualityCriteria?.qualityCriterias?.map(
        ({ description }, index) => {
          return { [`description${index}`]: description };
        }
      ) ?? [])
    ),
    observations: values.product.qualityCriteria.observations,
  };
  return {
    product: { id: props.productId },
    descriptiveImages,
    rawMaterials: values.rawMaterials,
    preManufacturedProducts: values.preManufacturedProducts,
    instructions,
    materialType,
    manufactureSpecification,
    qualityCriteria,
    customFields: customFieldsFiltered,
  };
}

function resetForms() {
  form.value?.resetForm();
}

onMounted(async () => {
  await Promise.all([
    getAllCatalogs(),
    getModuleCustomFields(),
    getTechnicalSheet(),
  ]);
  watch(
    [currentTechnicalSheet, catalogs, customFields],
    async () => {
      if (customFields.value && catalogs.value) {
        formTemplateRef.value = CreateOrUpdateTechnicalSheetTemplate(
          customFields.value,
          handleSubmit,
          !currentTechnicalSheet.value
        );
        if (currentTechnicalSheet.value) {
          await nextTick();
          form.value?.setValues({
            technicalSheet: currentTechnicalSheet.value,
          });
          rawMaterials.value =
            currentTechnicalSheet.value?.rawMaterials?.map((details) => {
              const rawMaterial = details.rawMaterial;
              return {
                rawMaterial: {
                  id: rawMaterial.id,
                  label: `${translateFromKey(rawMaterial, "description")} (${
                    rawMaterial.materialType
                  })`,
                  item: rawMaterial,
                  items: [rawMaterial],
                },
                quantity: details.quantity,
              };
            }) ?? [];
        }
      }
    },
    { deep: true, immediate: true }
  );
  watch(
    currentTechnicalSheet,
    () => {
      Object.keys(
        currentTechnicalSheet.value.manufactureSpecification.details
      ).map((detailKey) => {
        if (detailKey !== "detail0") {
          formTemplateRef.value.sections[2].fields.push(
            GetManufactureSpecificationFieldTemplate(detailKey)
          );
        }
      });
      Object.keys(currentTechnicalSheet.value.qualityCriteria.orders).map(
        (orderKey) => {
          if (orderKey !== "order0") {
            const descriptionKey = orderKey.replace("order", "description");
            formTemplateRef.value.sections[4].fields.push(
              GetQualityCriteriaFieldTemplate(orderKey, descriptionKey)
            );
          }
        }
      );
    },
    { deep: true, immediate: true }
  );
});

__expose({
  resetForms,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (formTemplateRef.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(), _createBlock(GeneralForm, {
              ref_key: "form",
              ref: form,
              form: formTemplateRef.value,
              key: JSON.stringify(currentTechnicalSheet.value),
              "form-name": "technicalSheetForm"
            }, {
              "section-0-field-0": _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(CreateOrUpdateRawMaterialQuantity, { rawMaterials: rawMaterials.value }, null, 8, ["rawMaterials"])
                  ])
                ])
              ]),
              _: 1
            }, 8, ["form"]))
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})