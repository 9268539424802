import { Fields } from "@/shared/globals/forms/interfaces/Field.interface";
import { Form } from "@/shared/globals/forms/interfaces/Form.interface";
import {
  requiredYup,
  stringYup,
} from "@/shared/globals/yup/services/YupRules.services";
import { t } from "@/shared/locales/services/i18n.services";
import { customFieldsToField } from "@/store/customFields/helpers/CustomFieldToField.helper";
import { CustomFieldInterface } from "@/store/customFields/interfaces/CustomField.interface";

export const GetManufactureSpecificationFieldTemplate = (
  detailKey: string
): Fields => {
  return {
    fields: [
      {
        path: `technicalSheet.manufactureSpecification.details.${detailKey}`,
        inputName: "technicalSheetManufacturingSpecification",
        placeholder: {
          value: "technicalSheets.entity.manufactureSpecification.details",
          needsTranslate: true,
        },
        type: "text",
        metadata: {
          tooltipPosition: "top",
        },
        methodsYup: () => {
          return [stringYup, requiredYup];
        },
        widthDistribution: 12,
      },
    ],
    fieldOptions: {
      requireButtonDelete: true,
      requireButtonAdd: true,
      deleteDistribution: "end",
    },
  };
};

export const GetQualityCriteriaFieldTemplate = (
  orderKey,
  descriptionKey
): Fields => {
  return {
    fields: [
      {
        path: `technicalSheet.qualityCriteria.orders.${orderKey}`,
        inputName: "QualityCriteriaOrder",
        placeholder: {
          value: "technicalSheets.entity.qualityCriteria.order",
          needsTranslate: true,
        },
        type: "number",
        metadata: {
          tooltipPosition: "top",
        },
        methodsYup: () => {
          return [stringYup, requiredYup];
        },
        widthDistribution: 2,
      },
      {
        path: `technicalSheet.qualityCriteria.descriptions.${descriptionKey}`,
        inputName: "QualityCriteriaDescription",
        placeholder: {
          value: "technicalSheets.entity.qualityCriteria.description",
          needsTranslate: true,
        },
        type: "text",
        metadata: {
          tooltipPosition: "top",
        },
        methodsYup: () => {
          return [stringYup, requiredYup];
        },
        widthDistribution: 10,
      },
    ],
    fieldOptions: {
      requireButtonDelete: true,
      requireButtonAdd: true,
      deleteDistribution: "end",
    },
  };
};

export const CreateOrUpdateTechnicalSheetTemplate = (
  customFields: CustomFieldInterface[],
  action,
  isCreating: boolean
): Form => {
  const materialTypeOptions = [
    { id: "new", label: t("technicalSheets.type.new") },
    { id: "recycled", label: t("technicalSheets.type.recycled") },
    { id: "mixed", label: t("technicalSheets.type.mixed") },
  ];
  const form: Form = {
    sections: [
      {
        fields: [
          {
            path: "technicalSheet.descriptiveImages",
            inputName: "technicalSheetImages",
            label: {
              value: "technicalSheets.entity.images",
              needsTranslate: true,
            },
            type: "file",
            metadata: {
              tooltipPosition: "top",
            },
            fileOptions: {
              isMultiple: true,
              accept: "image/*",
              imageOptions: {
                showPreview: true,
                width: "150",
                height: "150",
              },
            },
          },
          {
            path: "technicalSheet.materialType",
            inputName: "technicalSheetMaterialType",
            label: {
              value: "technicalSheets.entity.materialType",
              needsTranslate: true,
            },
            type: "select",
            metadata: {
              tooltipPosition: "top",
            },
            selectOptions: {
              idKey: "id",
              labelKey: "label",
              isMultiple: false,
              initialOptions: materialTypeOptions,
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
      {
        name: {
          value: "technicalSheets.entity.manufactureSpecification.title",
          needsTranslate: true,
        },
        fields: [
          {
            path: "technicalSheet.manufactureSpecification.images",
            inputName: "technicalSheetManufacturingSpecificationImages",
            label: {
              value: "technicalSheets.entity.manufactureSpecification.images",
              needsTranslate: true,
            },
            type: "file",
            metadata: {
              tooltipPosition: "top",
            },
            fileOptions: {
              isMultiple: true,
              accept: "image/*",
              imageOptions: {
                showPreview: true,
                width: "100%",
              },
            },
          },
        ],
      },
      {
        fields: [GetManufactureSpecificationFieldTemplate("detail0")],
      },
      {
        name: {
          value: "technicalSheets.entity.instructions.title",
          needsTranslate: true,
        },
        fields: [
          {
            path: "technicalSheet.instructions.images",
            inputName: "instructionsImages",
            label: {
              value: "technicalSheets.entity.instructions.images",
              needsTranslate: true,
            },
            type: "file",
            metadata: {
              tooltipPosition: "top",
            },
            fileOptions: {
              isMultiple: true,
              accept: "image/*",
              imageOptions: {
                showPreview: true,
                width: "150",
                height: "150",
              },
            },
          },
          {
            path: "technicalSheet.instructions.description",
            inputName: "instructionsDescription",
            placeholder: {
              value: "technicalSheets.entity.instructions.description",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
      {
        name: {
          value: "technicalSheets.entity.qualityCriteria.title",
          needsTranslate: true,
        },
        fields: [GetQualityCriteriaFieldTemplate("order0", "description0")],
      },
      {
        fields: [
          {
            path: "technicalSheet.qualityCriteria.observations",
            inputName: "QualityCriteriaObservations",
            placeholder: {
              value: "technicalSheets.entity.qualityCriteria.observations",
              needsTranslate: true,
            },
            type: "textarea",
            metadata: {
              tooltipPosition: "top",
            },
            methodsYup: () => {
              return [stringYup, requiredYup];
            },
          },
        ],
      },
    ],
    submit: {
      backgroundColor: "red",
      text: {
        value: isCreating
          ? "technicalSheets.form.add"
          : "technicalSheets.form.update",
        needsTranslate: true,
      },
      action,
    },
  };

  if (customFields.length > 0) {
    form.sections.push({
      name: { value: "customFields.title", needsTranslate: true },
      fields: customFieldsToField(
        customFields,
        "technicalSheet",
        "technicalSheet"
      ),
    });
  }

  return form;
};
