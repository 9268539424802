import axiosInstance from "@/middlewares/Routing.middleware";
import { Store } from "../models/Store.model";
import { DeleteStoreInterface } from "../interfaces/DeleteStore.interface";

export async function GetAllStores(): Promise<Store[]> {
  return (await axiosInstance.get(`stores/all`)).data;
}

export async function CreateStore(payload: Store): Promise<Store> {
  return (await axiosInstance.post(`stores`, payload)).data;
}

export async function UpdateStore(payload: Store): Promise<Store> {
  const { id, ...storeProps } = payload;
  return (await axiosInstance.put(`stores/${id}`, storeProps)).data;
}

export async function DeleteStore(
  payload: DeleteStoreInterface
): Promise<void> {
  const { newId, oldId } = payload;
  await axiosInstance.delete(`stores?newId=${newId}&oldId=${oldId}`);
}
