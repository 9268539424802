<template>
  <div class="dropdown container-fluid p-0 w-100" id="langDropdown">
    <button
      class="btn dropdown-toggle p-0 w-100 d-flex align-items-center"
      type="button"
      data-bs-toggle="dropdown"
      aria-expanded="false"
      @click="openLangs($event)"
    >
      <div class="container-fluid p-0">
        <div class="row">
          <div class="col-auto pr-0 pl-1">
            <img width="20px" :src="currentLangData.image" alt="currentLang" />
          </div>
          <div class="col d-flex align-items-center">
            <span id="langName"> {{ currentLangData.name }}</span>
          </div>
        </div>
      </div>
    </button>
    <ul class="dropdown-menu">
      <li
        class="dropdown-item"
        v-for="lang in langValues"
        :key="`lang${lang.key}`"
        @click="selectLang(lang.key)"
      >
        <div class="container-fluid">
          <div class="row">
            <div class="col-auto pr-0">
              <img width="15px" :src="lang.image" alt="langOption" />
            </div>
            <div class="col">
              <span href="#">{{ lang.name }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { useLanguageServices } from "@/composables/useLanguageServices";
import { Dropdown } from "bootstrap";

const { selectLang, langValues, currentLangData } = useLanguageServices();

function openLangs(event) {
  event.stopPropagation();
  const dropdown = new Dropdown("#langDropdown");
}
</script>

<style lang="scss" scoped>
.changeLangIcon {
  width: max-content;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-menu {
  width: 11em;
}

#langName {
  font-size: 0.8em;
}

.btn.show {
  border: none;
}
</style>
