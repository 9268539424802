import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "text-wrap"
}
const _hoisted_2 = { class: "card py-2 w-100" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 d-flex flex-start" }
const _hoisted_5 = { style: {"margin-left":"8px"} }
const _hoisted_6 = ["multiple"]
const _hoisted_7 = { class: "col-12" }
const _hoisted_8 = { class: "d-flex flex-row justify-content-start flex-wrap" }
const _hoisted_9 = { class: "card p-2 m-2 d-flex flex-column justify-content-center align-items-center" }
const _hoisted_10 = ["src", "width", "height"]
const _hoisted_11 = ["onClick"]

import { useToast } from "@/composables/useToastServices";
import { FileOptions } from "@/shared/globals/forms/interfaces/FileOptions.interface";
import { compressImage } from "@/shared/globals/helpers/Files.helper";
import { t } from "@/shared/locales/services/i18n.services";
import {
  PropType,
  Ref,
  ref,
  toRef,
  watch,
} from "vue";
type FileType = {
  url: string;
  file?: File;
  isUrl: boolean;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'FilesMaster',
  props: {
  modelValue: { type: Array, required: true },
  fileOptions: {
    type: Object as PropType<FileOptions>,
    required: false,
  },
  initialFiles: {
    type: Array<FileType>,
    required: false,
    default: () => [],
  },
  debug: {
    type: Boolean,
    required: false,
    default: false,
  },
},
  emits: ["update:modelValue"],
  setup(__props, { emit: __emit }) {

const props = __props;

const emit = __emit;

const initialFilesRef: Ref<FileType[]> = toRef(props, "initialFiles");
const fileInput = ref();
const srcs: Ref<{ [key in string]: string }> = ref({});
const files: Ref<{
  [key in string]: File;
}> = ref({});

async function handleFileChange(event) {
  const tempFiles: File[] = Array.from(event.target.files);
  await Promise.all(
    tempFiles.map(async (file) => {
      const url = await getUrlFromFile(file);
      try {
        files.value[file.name] = await compressImage(file);
        srcs.value[file.name] = url;
        return { url, file };
      } catch {
        useToast().errorToast(t("global.errors.imageToBig"));
      }
    })
  );
  emit("update:modelValue", formatValue());
  event.target.value = null;
}

function formatValue(): FileType[] {
  return Object.keys(files.value).map((key) => {
    const url = srcs.value[key];
    const file = files.value[key];
    const isUrl = !url.startsWith("data:");
    return { url, file, isUrl };
  });
}

function onRemoveFile(srcKey: string) {
  const { [`${srcKey}`]: removedSrc, ...newSrcs } = srcs.value;
  const { [`${srcKey}`]: removedFile, ...newFiles } = files.value;
  srcs.value = newSrcs;
  files.value = newFiles;
  emit("update:modelValue", formatValue());
}

function filesNotExists(): boolean {
  return initialFilesRef.value.some((item) => {
    return !item?.file;
  });
}

watch(
  initialFilesRef,
  async () => {
    if (filesNotExists()) {
      const newInitialFiles = await Promise.all(
        initialFilesRef.value.map(async ({ url, file, isUrl }) => {
          if (!file) {
            const response = await fetch(url, {
              mode: "no-cors",
            });
            const data = await response.blob();
            const fileName = url.match(/[^./]+\.[a-zA-Z0-9]+$/)?.[0];
            const tempFile = new File([data], fileName, {
              type: data.type,
            });
            return { url, file: tempFile, isUrl: true };
          }
          return { url, file, isUrl };
        })
      );
      emit("update:modelValue", newInitialFiles);
    } else {
      srcs.value = Object.fromEntries(
        initialFilesRef.value.map(({ url, file }) => {
          return [file.name, url];
        })
      );
      files.value = Object.fromEntries(
        initialFilesRef.value.map(({ file }) => {
          return [file.name, file];
        })
      );
    }
  },
  { immediate: true }
);

function isString(value): value is string {
  if (typeof value === "string") {
    return true;
  }
  return false;
}

async function getUrlFromFile(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = async (e) => {
      const url = e.target?.result;
      if (isString(url)) {
        resolve(url);
      } else {
        reject();
      }
    };
    reader.readAsDataURL(file);
  });
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (__props.debug)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("p", null, " initialFilesRef: " + _toDisplayString(initialFilesRef.value.map(({ isUrl, file }) => {
          return { isUrl, file };
        })), 1),
          _createElementVNode("p", null, "srcs: " + _toDisplayString(srcs.value.length), 1),
          _createElementVNode("p", null, "files: " + _toDisplayString(files.value.length), 1),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(files.value, (file, index) => {
            return (_openBlock(), _createElementBlock("p", {
              key: `size${index}`
            }, " file " + _toDisplayString(index) + " size: " + _toDisplayString(file.size), 1))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("button", {
              type: "button",
              onClick: _cache[0] || (_cache[0] = () => fileInput.value.click()),
              class: "btn btn-primary"
            }, " Cargar "),
            _createElementVNode("input", {
              type: "file",
              style: {"display":"none"},
              ref_key: "fileInput",
              ref: fileInput,
              class: "form-control",
              onChange: handleFileChange,
              multiple: __props.fileOptions.isMultiple,
              accept: "`${accept}/*`"
            }, null, 40, _hoisted_6)
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.keys(srcs.value), (srcKey) => {
              return (_openBlock(), _createElementBlock("div", {
                key: files.value[srcKey].name + files.value[srcKey].type + files.value[srcKey].size
              }, [
                _createElementVNode("div", _hoisted_9, [
                  (srcs.value[srcKey] && __props.fileOptions.imageOptions.showPreview)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: srcs.value[srcKey] as string,
                        width: __props.fileOptions.imageOptions.width,
                        height: __props.fileOptions.imageOptions.height
                      }, null, 8, _hoisted_10))
                    : _createCommentVNode("", true),
                  _createElementVNode("i", {
                    onClick: () => onRemoveFile(srcKey),
                    class: "bi bi-x-circle icon-wrapper text-danger"
                  }, null, 8, _hoisted_11),
                  _createTextVNode(" " + _toDisplayString(files.value[srcKey].name), 1)
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 64))
}
}

})